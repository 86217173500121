

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ResetForm extends Vue {
  email: string = ''

  @Prop({ required: true })
  readonly errors!: string[]

  @Emit('submit')
  onSubmit () {
    return {
      email: this.email
    }
  }

  hasError (key: string) {
    return this.errors.indexOf(key) !== -1
  }
}
