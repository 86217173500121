
































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component
export default class NavigationUserLinks extends Vue {
  @Action('toggleEntryVisiblity', { namespace: 'session' })
  toggleEntryVisiblity!: () => void

  @Action('toggleEntryTextarea', { namespace: 'session' })
  toggleEntryTextarea!: () => void

  @State('timeEntryTextarea', { namespace: 'session' })
  timeEntryTextareaVisible!: boolean

  @Prop({ type: String })
  readonly avatar!: string

  @Prop({ required: true })
  readonly popup!: boolean

  get toggleLogText () {
    return this.timeEntryTextareaVisible ? 'common.menu.toggleLogForm' : 'common.menu.toggleLogInput'
  }

  get toggleLogIcon () {
    return this.timeEntryTextareaVisible ? 'clock' : 'file-alt'
  }
}
