




















































































































import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { Tags } from '@/types/era'
import { levenshteinEditDistance } from 'levenshtein-edit-distance'

import OnClickOutside from '@/components/base/OnClickOutside.vue'

@Component({
  components: { OnClickOutside }
})
export default class FormTagsDropdown extends Vue {
  isOpen: boolean = false
  currentWord: string = ''

  @Prop({ type: [String], default: '' })
  readonly value!: string

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @State('tags', { namespace: 'interface' })
  tags!: Tags[]

  @Action('loadTags', { namespace: 'interface' })
  loadTags!: (query?: string) => Promise<Tags[]>

  @Watch('currentWord', { immediate: true, deep: true })
  reloadTags (val: string) {
    if (val !== '') {
      this.loadTags(val)
    }
  }

  @Emit('select')
  onSelectItem (item: Tags) {
    const myField = this.$refs.entryTitle as HTMLInputElement
    const startPos = myField!.selectionStart || 0
    const endPos = myField!.selectionEnd || 0
    const $value = (this.currentWord === '' ? this.value.substring(0, startPos) + `#${item.tag}` + this.value.substring(endPos, this.value.length) : this.value.substring(0, startPos - this.currentWord.length) + `${item.tag}` + this.value.substring(endPos, this.value.length))
    this.$emit('updateTitle', $value)
    setTimeout(function () {
      myField!.focus()
      myField!.selectionEnd = endPos + `#${item.tag}`.length
    }, 500)
  }

  @Emit('input')
  onInput ($event: KeyboardEvent): string {
    if (!this.isOpen) {
      this.toggle()
    }
    const txt = ($event.target as HTMLInputElement).value
    this.setCurrentWord(txt)
    return txt
  }

  clearCurrentWord ($event: KeyboardEvent) {
    if ($event.keyCode === 32) {
      this.currentWord = ''
    }
  }

  setCurrentWord (text: string) {
    const myField = this.$refs.entryTitle as HTMLInputElement
    const startPos = myField!.selectionStart || 0
    const current = text.substring(0, startPos).match(/\w+(?:[.,-_]\w+)*/g)?.pop()
    const hash = text.substring(0, startPos).match(/#[^\s#]*/g)?.pop()
    if (hash === `#${current}`) {
      this.currentWord = `${current}`
    }
  }

  tagsReplace (text: string): string {
    const reHash = /#[^\s#]*/g
    let t = text.replace(/<[^>]*>?/gm, '')
    const tags = this.tags.map((item) => (item.tag))
    t = t.replace(reHash, hash => {
      return tags.includes(hash.replace('#', '').trim()) ? `<span class="current">${hash.trim()}</span>` : `<span class="unknown">${hash.trim()}</span>`
    })
    return t
  }

  onClickOutside () {
    if (!this.isOpen) {
      return
    }

    this.isOpen = false
  }

  toggle () {
    this.isOpen = !this.isOpen
  }

  compareStrings (s1:string, s2:string): number {
    const distance = levenshteinEditDistance(s1, s2)
    const maxLength = Math.max(s1.length, s2.length)
    const similarity = (maxLength - distance) / maxLength
    return similarity
  }

  async created () {
    this.loadTags()
  }

  get description () {
    return this.tagsReplace(this.value)
  }

  get title () {
    return this.value.replace(/<[^>]*>?/gm, '')
  }

  get tagsList () {
    const tags = this.tags.filter((item) => {
      return this.compareStrings(item.tag, this.currentWord) >= 0.4
    })
    return tags.length > 0 ? tags : this.tags
  }
}
