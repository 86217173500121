







































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, State, Action } from 'vuex-class'
import { Project, Client, MessageboxMessage } from '@/types/era'
import ProjectModal from '@/components/modals/ProjectModal.vue'
import ListingTable from '@/components/layout/ListingTable.vue'
import ListingTableHeading from '@/components/layout/ListingTableHeading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    DefaultTemplate,
    ProjectModal,
    ListingTable,
    ListingTableHeading
  }
})
export default class ProjectsView extends Vue {
  @State('clients', { namespace: 'interface' })
  clients!: Client[]

  @State('clientFilter', { namespace: 'projects' })
  clientFilter!: number

  @State('project', { namespace: 'projects' })
  project!: Project

  @Getter('filtered', { namespace: 'projects' })
  projects!: Project[]

  @Action('loadAll', { namespace: 'projects' })
  loadProjects!: () => Promise<Project[]>

  @Action('load', { namespace: 'projects' })
  loadProject!: (id: number) => Promise<Project>

  @Action('clearCurrent', { namespace: 'projects' })
  clearProject!: () => void

  @Action('createCurrent', { namespace: 'projects' })
  newProject!: () => void

  @Action('setClientFilter', { namespace: 'projects' })
  setClientFilter!: (filter?: number) => void

  @Action('setFilter', { namespace: 'projects' })
  setFilter!: (filter: string) => void

  @Action('applyCurrent', { namespace: 'projects' })
  applyCurrent!: (project: Partial<Project>) => void

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  created () {
    this.setFilter('')
    this.setClientFilter()
    this.loadProjects()
  }

  onModalNewClient (client: Client) {
    this.applyCurrent({
      clients: [client]
    })
  }

  onProjectCreate () {
    this.newProject()
  }

  onProjectEdit (project: Project) {
    this.loadProject(project.id as number)
  }

  onModalSave (project: Project) {
    this.addMessage({
      message: this.$t('projects.success') as string
    })

    this.clearProject()
  }

  onModalClose () {
    this.clearProject()
  }

  onFilterInput (str: string) {
    this.setFilter(str)
  }

  onClientFilterInput (value: number) {
    this.setClientFilter(value)
  }

  get clientFilters (): object[] {
    return this.clients.map(({ id, title }) => ({
      label: title,
      value: id
    }))
  }
}
