
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import format from 'date-fns/format'

@Component
export default class DateTime extends Vue {
  @Prop({ required: true })
  readonly timestamp!: string

  @State('timeFormat', { namespace: 'session' })
  timeFormat!: string

  @State('dateFormat', { namespace: 'session' })
  dateFormat!: string

  get formattedDateTime () {
    const date = new Date(this.timestamp)
    return format(date, `${this.timeFormat} ${this.dateFormat}`)
  }
}
