









































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator'
import { ProjectFormData, Project, Client } from '@/types/era'

@Component
export default class ProjectForm extends Vue {
  form: ProjectFormData = {
    id: 0,
    title: '',
    color: '',
    description: '',
    clients: []
  }

  created () {
    this.form = {
      ...this.form,
      id: this.data.id,
      title: this.data.title,
      color: this.data.color || null,
      description: this.data.description,
      clients: this.data.clients.map(c => c.id) as number[]
    }
  }

  @Prop({ required: true })
  readonly errors!: string[]

  @Prop({ required: true })
  readonly data!: Project

  @Prop({ required: true })
  readonly clients!: Client[]

  @Emit('remove')
  onRemove (ev: any) {
    return { ...this.form }
  }

  @Emit('submit')
  onSubmit (ev: any) {
    return { ...this.form }
  }

  @Emit('newClient')
  onNewClient () {
  }

  @Watch('data.clients')
  onDataChange (newClients: Client[]) {
    this.form.clients = newClients.map(client => client.id)
  }

  onClientSelect (clientId: number) {
    if (!this.hasClient(clientId)) {
      this.form.clients.push(clientId)
    }
  }

  onClientRemove (clientId: number, index: number) {
    this.form.clients.splice(index)
  }

  clientTitle (id: number) {
    const found = this.clients.find(c => c.id === id)
    return found ? found.title : 'N/A'
  }

  hasClient (clientId: number) {
    return this.form.clients
      .findIndex((id: number) => id === clientId) !== -1
  }

  hasError (key: string) {
    return this.errors.indexOf(key) !== -1
  }

  get clientOptions () {
    return this.clients
      .filter(client => !this.hasClient(client.id as number))
      .map(client => ({
        key: String(client.id),
        value: client.id,
        label: client.title
      }))
  }
}
