


























































import Datepicker from 'vuejs-datepicker'
import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import format from 'date-fns/format'

@Component({
  components: {
    Datepicker
  }
})
export default class FormDatepicker extends Vue {
  isOpen: boolean = false

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ default: '' })
  readonly value!: string

  @Emit('input')
  onSelect (date: Date): string {
    return format(date, 'LL-dd-yyyy')
  }

  onOpened () {
    this.isOpen = true
  }

  onClosed () {
    this.isOpen = false
  }

  get icon (): string {
    return this.isOpen ? 'ChevronUp' : 'ChevronDown'
  }
}
