



































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class FormFileUpload extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: String, default: '' })
  readonly value!: string

  @Prop({ type: String, default: '' })
  readonly accept!: string

  onClick () {
    const file = this.$refs.file as HTMLInputElement
    if (file) {
      file.click()
    }
  }

  @Emit('change')
  onChange () {
    const file = this.$refs.file as HTMLInputElement

    return file!.files![0]
  }
}
