

















import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  SlashIcon,
  PlusIcon,
  MinusIcon,
  XIcon,
  SettingsIcon,
  FileTextIcon,
  ToggleLeftIcon,
  ToggleRightIcon,
  LogOutIcon,
  MenuIcon,
  MoreVerticalIcon,
  ClockIcon,
  SearchIcon,
  CalendarIcon,
  PlayIcon,
  PauseIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  UserIcon,
  UploadIcon,
  PieChartIcon,
  FilePlusIcon,
  TagIcon,
  UsersIcon,
  SaveIcon,
  DeleteIcon,
  UserPlusIcon
} from 'vue-feather-icons'

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    EditIcon,
    SlashIcon,
    PlusIcon,
    MinusIcon,
    XIcon,
    SettingsIcon,
    FileTextIcon,
    ToggleLeftIcon,
    ToggleRightIcon,
    LogOutIcon,
    MenuIcon,
    MoreVerticalIcon,
    ClockIcon,
    SearchIcon,
    CalendarIcon,
    PlayIcon,
    PauseIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    UserIcon,
    UploadIcon,
    PieChartIcon,
    FilePlusIcon,
    TagIcon,
    UsersIcon,
    SaveIcon,
    DeleteIcon,
    UserPlusIcon
  }
})
export default class Icon extends Vue {
  @Prop({ type: String, required: false, default: '1x' })
  readonly size!: string

  @Prop({ type: String, required: true })
  readonly icon!: string

  @Prop({ type: Boolean, required: false, default: false })
  readonly bold!: boolean

  get componentName () {
    return `${this.icon}Icon`
  }

  get strokeWidth () {
    return this.bold ? 4 : 2
  }
}
