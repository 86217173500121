
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/types/era'
import Dropdown from './Dropdown.vue'
import FormMultiselectDropdownDrawer from './FormMultiselectDropdownDrawer.vue'

@Component({
  components: {
    Dropdown,
    FormMultiselectDropdownDrawer
  }
})
export default class FormMultiselectDropdown extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ required: true, default: () => ([]) })
  readonly options!: SelectOption[]

  @Prop({ type: String, default: '' })
  readonly placeholder!: string
}
