/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import { TimePeriod, PeriodType, FirstDayOfWeek } from '@/types/era'

export interface HoursAndMinutes<T> {
  hours: T
  minutes: T
}

const periodMethods = {
  week: [startOfWeek, endOfWeek],
  day: [startOfDay, endOfDay]
}

export const periodFromDate = (date: Date, periodType: PeriodType = 'week', weekStartsOn: FirstDayOfWeek = 0): TimePeriod => {
  const [a, b] = periodMethods[periodType]
  const start = a(date, { weekStartsOn })
  const end = b(date, { weekStartsOn })

  return { start, end, weekStartsOn }
}

export const hoursAndMinutesFromTime = (time: number): HoursAndMinutes<number> => {
  const h = time / 60
  const hours = Math.floor(h)
  const m = (h - hours) * 60
  const minutes = Math.round(m)
  return { hours, minutes }
}

export const hoursAndMinutesFromTimePadded = (time: number, hoursPad: number = 1, minutesPad = 2): HoursAndMinutes<string> => {
  const { hours, minutes } = hoursAndMinutesFromTime(time)

  return {
    hours: String(hours).padStart(hoursPad, '0'),
    minutes: String(minutes).padStart(minutesPad, '0')
  }
}
