









































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { Project, Client, AutosaveData } from '@/types/era'
import TimeEntryBar from '@/components/layout/TimeEntryBar.vue'
import DraftFloatList from '@/components/layout/DraftFloatList.vue'
import bus from '@/services/bus'

@Component({
  components: {
    TimeEntryBar,
    DraftFloatList
  }
})
export default class DefaultTemplate extends Vue {
  @State('timeEntryVisible', { namespace: 'session' })
  isTimeEntryVisible!: boolean

  @State('timeEntryTextarea', { namespace: 'session' })
  isTimeEntryTextarea!: boolean

  @State('drafts', { namespace: 'interface' })
  drafts!: AutosaveData[]

  @Action('loadProjects', { namespace: 'interface' })
  loadProjects!: () => Promise<Project[]>

  @Action('loadClients', { namespace: 'interface' })
  loadClients!: () => Promise<Client[]>

  @Action('loadDrafts', { namespace: 'interface' })
  loadDrafts!: () => Promise<AutosaveData[]>

  @Prop({ default: '' })
  readonly title!: string

  @Prop({ default: 'wide' })
  readonly size!: string

  @Prop({ default: false })
  readonly padded!: string

  async created () {
    await this.loadProjects()
    await this.loadClients()
    await this.loadDrafts()
  }

  mounted () {
    bus.$on('project-updated', this.onProjectUpdated)
    bus.$on('client-updated', this.onClientUpdated)
    bus.$on('draft-updated', this.onDraftUpdated)
  }

  destroyed () {
    bus.$off('project-updated', this.onProjectUpdated)
    bus.$on('client-updated', this.onClientUpdated)
    bus.$on('draft-updated', this.onDraftUpdated)
  }

  onProjectUpdated () {
    this.loadProjects()
  }

  onClientUpdated () {
    this.loadClients()
  }

  onDraftUpdated () {
    this.loadDrafts()
  }

  @Emit('timeLogged')
  onTimeLogged () {
  }

  get isDraftVisible () {
    return this.isTimeEntryTextarea && this.drafts.length > 0
  }
}
