var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultTemplate',{attrs:{"title":_vm.$t('teams.heading'),"padded":true}},[(_vm.modalTarget)?_c('TeamDayReportModal',{class:[_vm.$style.smHide, _vm.$style.teamModel],attrs:{"target":_vm.modalTarget,"blackout":false},on:{"toggle":_vm.onModalToggle}},_vm._l((_vm.selectionEntries),function(e){return _c('EntryCalendarCard',{key:e.id,attrs:{"entry":e,"clsOverride":'teams'}})}),1):_vm._e(),_c('ListingTableHeading',{class:_vm.$style.listingTableHeading},[_c('div',{class:_vm.$style.periodHeading},[_c('div',{class:_vm.$style.periodTitle},[_vm._v(" "+_vm._s(_vm.periodTitle)+" ")]),_c('PeriodSelection',{class:_vm.$style.periodSelection,attrs:{"period":_vm.period},on:{"select":_vm.onPeriodSelection}})],1),_c('FormDropdown',{attrs:{"placeholder":_vm.$t('teams.allTeams'),"options":_vm.teamOptions,"value":_vm.filterValue},on:{"input":_vm.onTeamFilterSelect}}),_c('FormButton',{attrs:{"icon":"Plus"},on:{"click":_vm.onTeamCreate}},[_vm._v(" "+_vm._s(_vm.$t('teams.createButton'))+" ")])],1),_vm._l((_vm.filteredOverview),function(team,index){return _c('div',{key:team.id,class:_vm.$style.section},[_c('div',{class:_vm.$style.heading},[_vm._v(" "+_vm._s(team.title)+" "),_c('Icon',{class:_vm.$style.headerAction,attrs:{"icon":"Settings","size":"2x"},on:{"click":function($event){return _vm.onEdit(team)}}})],1),_c('Grid',{class:_vm.$style.grid,scopedSlots:_vm._u([{key:"head",fn:function(){
var _obj;
return [_c('div',{class:_vm.$style.gridHead},[_c('div',{class:( _obj = {}, _obj[_vm.$style.headItem] = true, _obj[_vm.$style.headName] = true, _obj ),on:{"click":function($event){return _vm.onColumnSortClick(index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.name'))+" "),_c('ListingTableChevron')],1),_vm._l((_vm.weekDays),function(day,dindex){
var _obj;
return _c('div',{key:dindex,class:( _obj = {}, _obj[_vm.$style.today] = _vm.isToday(day), _obj[_vm.$style.headItem] = true, _obj )},[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(day))+". ")])])}),_c('div',{class:_vm.$style.headItem},[_vm._v(" "+_vm._s(_vm.$t('common.total'))+" ")])],2)]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.sortedUsers(index, team.users)),function(user){
var _obj, _obj$1, _obj$2;
return _c('div',{key:user.id,class:_vm.$style.gridBody},[_c('div',{class:_vm.$style.userColumn},[_c('UserLink',{class:_vm.$style.userLink,attrs:{"avatar":true,"user":user}})],1),_vm._l((_vm.weekDays),function(day){
var _obj, _obj$1;
return _c('div',{key:day.toString()},[_c('div',{class:( _obj = {}, _obj[_vm.$style.box] = true, _obj[_vm.$style.rounded] = true, _obj[_vm.$style.warn] = !_vm.hasTimeSpent(user.entries, day), _obj ),on:{"click":function($event){return _vm.onBoxClick(user.id, user.entries, day, $event)}}},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.lgHide] = true, _obj$1[_vm.$style.timeFormat] = true, _obj$1 )},[_c('div',{class:_vm.$style.formatDay},[_vm._v(_vm._s(_vm.formatDate(day))+".")]),_c('div',{class:_vm.$style.formatTime},[_c('TimeFormatted',{attrs:{"time":_vm.getGroupSum(user.entries, day),"mobileTranslate":true}})],1)]),_c('TimeFormatted',{class:_vm.$style.smHide,attrs:{"time":_vm.getGroupSum(user.entries, day)}})],1)])}),_c('div',[_c('div',{class:( _obj = {}, _obj[_vm.$style.unBox] = true, _obj )},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.lgHide] = true, _obj$1[_vm.$style.timeFormat] = true, _obj$1 )},[_c('div',{class:_vm.$style.formatDay},[_vm._v(_vm._s(_vm.$t('common.total'))+".")]),_c('div',{class:_vm.$style.formatTime},[_c('TimeFormatted',{attrs:{"time":user.timeSpent,"mobileTranslate":true}})],1)]),_c('TimeFormatted',{class:_vm.$style.smHide,attrs:{"time":user.timeSpent}})],1)]),(_vm.showDetails(user.id))?_c('div',{class:( _obj$2 = {}, _obj$2[_vm.$style.lgHide] = true, _obj$2[_vm.$style.detailsColumn] = true, _obj$2 )},_vm._l((_vm.selectionEntries),function(e){return _c('EntryCalendarCard',{key:e.id,attrs:{"entry":e,"clsOverride":'teams'}})}),1):_vm._e()],2)})},proxy:true}],null,true)})],1)}),(_vm.team)?_c('TeamModal',{attrs:{"team":_vm.team,"users":_vm.users},on:{"close":_vm.onModalClose,"save":_vm.onModalSave,"userJoin":_vm.onUserJoin,"userLeave":_vm.onUserLeave}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }