/**
 * @project Era
 * @author Marko Muric <marko.muric@copyleft.rs>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export const parseHashtags = (title: string): string => {
  const reHash = /#[^\s#]*/g
  return title.replace(reHash, hash => `<a class='tag' target="_blank" href="/reports?hashtag=${hash.replace('#', '').trim()}">${hash}</a>`)
}
