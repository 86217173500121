
















































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import isToday from 'date-fns/isToday'
import CalendarCard from '@/components/layout/CalendarCard.vue'
import Icon from '@/components/base/Icon.vue'
import { TimeEntry, CalendarCardEvent } from '@/types/era'
import { parseHashtags } from '@/utils'

@Component({
  components: {
    CalendarCard,
    Icon
  }
})
export default class EntryCalendarCard extends Vue {
  @Prop()
  readonly entry!: TimeEntry

  @Prop({ type: String })
  readonly clsOverride!: string

  @State('currentStopwatch', { namespace: 'stopwatches' })
  readonly currentStopwatch!: TimeEntry | null

  @Action('toggleStopwatch', { namespace: 'stopwatches' })
  readonly toggleStopwatch!: (entryId: number) => Promise<void>

  formatProjects () {
    return this.entry.projects
      .map(e => {
        if (e.clients && e.clients.length > 0) {
          return `${e.clients.map(e => e.title).join(', ')} - ${e.title}`
        }

        return e.title
      })
      .join(' / ')
  }

  stripeColor (entry: TimeEntry): string | null {
    if (this.hasProjects) {
      return this.entry.projects[0].color || null // FIXME
    }

    return null
  }

  get hasProjects () {
    return this.entry.projects.length > 0
  }

  get isActiveStopwatch () {
    return this.currentStopwatch?.id === this.entry.id
  }

  get stopwatchIcon () {
    return this.isActiveStopwatch ? 'pauseCircle' : 'playCircle'
  }

  get canUseStopwatch () {
    return this.entry.stopwatch || isToday(new Date(this.entry.spentAt))
  }

  get className () {
    const clsName = { [this.$style.details]: true }

    if (this.clsOverride && this.clsOverride === 'teams') {
      clsName[this.$style.teams] = true
    }

    if (this.clsOverride && this.clsOverride === 'calendar') {
      clsName[this.$style.calendar] = true
    }

    return clsName
  }

  @Emit('click')
  onClick (event: Event): CalendarCardEvent {
    return { event, entry: this.entry }
  }

  parseTitle (title: string):string {
    return parseHashtags(title)
  }
}
