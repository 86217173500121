


































import { Component, Emit, Vue } from 'vue-property-decorator'

@Component
export default class BubbleBox extends Vue {
  @Emit('click')
  onClick (ev: Event) {
    return ev
  }
}
