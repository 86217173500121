/**
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import format from 'date-fns/format'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {
  getProjectList,
  getClientList,
  getDraftList,
  getEntry,
  createEntry,
  createAutosave,
  updateAutosave,
  updateEntry,
  deleteEntry,
  getTags
} from '@/services/api'
import { Client, Project, TimeEntry, TimeEntryFormData, AutosaveData, Tags } from '@/types/era'

@Module({ namespaced: true })
export default class InterfaceModule extends VuexModule {
  loadingCount: number = 0
  blackoutVisible: boolean = false
  projects: Project[] = []
  clients: Client[] = []
  showLogin: boolean = false
  showRegister: boolean = false
  entry: TimeEntry | null = null
  tags: Tags[] = []
  drafts: AutosaveData[] = []
  importtext: string = ''
  importtextid: number | null = null

  @Mutation
  TOGGLE_BLACKOUT (toggle: boolean) {
    this.blackoutVisible = toggle
  }

  @Mutation
  POP_LOADING () {
    this.loadingCount = this.loadingCount - 1
  }

  @Mutation
  PUSH_LOADING () {
    this.loadingCount = this.loadingCount + 1
  }

  @Mutation
  SET_PROJECTS (projects: Project[]) {
    this.projects = projects
  }

  @Mutation
  SET_CLIENTS (clients: Client[]) {
    this.clients = clients
  }

  @Mutation
  SET_DRAFTS (drafts: AutosaveData[]) {
    this.drafts = drafts
  }

  @Mutation
  SET_SHOW_LOGIN (toggle: boolean) {
    this.showLogin = toggle
  }

  @Mutation
  SET_SHOW_REGISTER (toggle: boolean) {
    this.showRegister = toggle
  }

  @Mutation
  SET_CURRENT_ENTRY (entry?: TimeEntry) {
    this.entry = entry || null
  }

  @Mutation
  SET_TAGS (tags: Tags[]) {
    const all = [...this.tags, ...tags]
    this.tags = [...new Map(all.map((m) => [m.id, m])).values()]
  }

  @Mutation
  SET_IMPORTTEXT (importtext: string) {
    this.importtext = importtext
  }

  @Mutation
  SET_IMPORTTEXTID (importtextid: number | null) {
    this.importtextid = importtextid
  }

  @Action({ commit: 'SET_IMPORTTEXT' })
  setImporttext (importtext: string) {
    return importtext
  }

  @Action({ commit: 'SET_IMPORTTEXTID' })
  setImporttextId (importtextid: number | null) {
    return importtextid
  }

  @Action({ commit: 'TOGGLE_BLACKOUT' })
  toggleBlackout (toggle: boolean) {
    return toggle
  }

  @Action({ commit: 'POP_LOADING' })
  popLoading () {
  }

  @Action({ commit: 'PUSH_LOADING' })
  pushLoading () {
  }

  @Action({ commit: 'SET_SHOW_REGISTER' })
  toggleShowRegister (toggle: boolean = !this.showRegister) {
    return toggle
  }

  @Action({ commit: 'SET_SHOW_LOGIN' })
  toggleShowLogin (toggle: boolean = !this.showLogin) {
    return toggle
  }

  @Action({ commit: 'SET_PROJECTS' })
  loadProjects () {
    return getProjectList()
  }

  @Action({ commit: 'SET_CLIENTS' })
  loadClients () {
    return getClientList()
  }

  @Action({ commit: 'SET_DRAFTS' })
  loadDrafts (page: number = 1, limit: number = 20, status: string = 'draft') {
    const query = {
      status,
      page,
      limit
    }
    return getDraftList(query)
  }

  @Action({ commit: 'SET_CURRENT_ENTRY' })
  loadEntry (id: number) {
    return getEntry(id)
  }

  @Action({})
  async createEntry (entry: TimeEntryFormData) {
    return createEntry(entry)
  }

  @Action({})
  async createAutosave (autosave: AutosaveData) {
    return createAutosave(autosave)
  }

  @Action({})
  async updateAutosave (autosave: AutosaveData) {
    return updateAutosave(autosave)
  }

  @Action({})
  async updateEntry (entry: TimeEntryFormData) {
    return updateEntry(entry)
  }

  @Action({})
  async removeEntry (entry: TimeEntryFormData) {
    return deleteEntry(entry)
  }

  @Action({ commit: 'SET_CURRENT_ENTRY' })
  createCurrent (data: Partial<TimeEntry> = {}): Partial<TimeEntry> {
    return {
      id: 0,
      title: '',
      timeSpent: 0,
      spentAt: format(new Date(), 'yyyy-LL-dd'),
      projects: [],
      ...data
    }
  }

  @Action({ commit: 'SET_CURRENT_ENTRY' })
  clearCurrent () {
    return null
  }

  @Action({ commit: 'SET_TAGS' })
  loadTags (query?: string) {
    return getTags(query)
  }

  public get isLoading (): boolean {
    return this.loadingCount > 0
  }
}
