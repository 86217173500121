




























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WelcomeBox extends Vue {
  @Prop({ required: true })
  readonly image!: string

  @Prop({ required: true })
  readonly title!: string

  @Prop({ required: true })
  readonly alignment!: string
}
