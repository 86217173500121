































import { Component, Prop, Mixins, Emit } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { TimeEntry, TimeEntryFormData } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import EntryForm from '@/components/forms/EntryForm.vue'

@Component({
  components: {
    EntryForm
  }
})
export default class HoursEntryModal extends Mixins(BaseModalForm) {
  @State('entry', { namespace: 'interface' })
  entry!: TimeEntry

  @Action('createEntry', { namespace: 'interface' })
  createEntry!: (entry: TimeEntryFormData) => Promise<TimeEntry>

  @Action('updateEntry', { namespace: 'interface' })
  updateEntry!: (entry: TimeEntryFormData) => Promise<TimeEntry>

  @Action('removeEntry', { namespace: 'interface' })
  removeEntry!: (entry: TimeEntryFormData) => Promise<undefined>

  @Action('toggleStopwatch', { namespace: 'stopwatches' })
  toggleStopwatch!: (entryId: number) => void

  @Action('createStopwatch', { namespace: 'stopwatches' })
  createStopwatch!: (entry: TimeEntryFormData) => void

  @Prop({ required: true })
  readonly data!: TimeEntryFormData

  @Emit('done')
  onModalRemove (entry: TimeEntryFormData) {
    return this.submitWithFn(() => this.removeEntry(entry))
  }

  onModalSubmit (entry: TimeEntryFormData) {
    this.clearErrors()

    const submit = entry.id! > 0
      ? this.updateEntry
      : this.createEntry

    return this.submitWithFn(() => submit(entry))
  }

  async onStopwatchToggle (entry: TimeEntryFormData) {
    this.clearErrors()

    try {
      if (entry.id) {
        await this.toggleStopwatch(entry.id)
      } else {
        await this.createStopwatch(entry)
      }
      this.$emit('done')
      this.$emit('close')
    } catch {
    }
  }
}
