/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import Vue from '@/providers/vue'
import Avatar from '@/components/base/Avatar.vue'
import BubbleBox from '@/components/base/BubbleBox.vue'
import CollapseContainer from '@/components/base/CollapseContainer.vue'
import ColorStripe from '@/components/base/ColorStripe.vue'
import Form from '@/components/base/Form.vue'
import FormAutocomplete from '@/components/base/FormAutocomplete.vue'
import FormButton from '@/components/base/FormButton.vue'
import FormConfirmButton from '@/components/base/FormConfirmButton.vue'
import FormColorDropdown from '@/components/base/FormColorDropdown.vue'
import FormDatepicker from '@/components/base/FormDatepicker.vue'
import FormDatepickerRanged from '@/components/base/FormDatepickerRanged.vue'
import FormDropdown from '@/components/base/FormDropdown.vue'
import FormMultiselectDropdown from '@/components/base/FormMultiselectDropdown.vue'
import FormTagsDropdown from '@/components/base/FormTagsDropdown.vue'
import GroupDropdown from '@/components/base/GroupDropdown.vue'
import FormField from '@/components/base/FormField.vue'
import FormLabel from '@/components/base/FormLabel.vue'
import FormTextarea from '@/components/base/FormTextarea.vue'
import FormTextareaParser from '@/components/base/FormTextareaParser.vue'
import FormTextbox from '@/components/base/FormTextbox.vue'
import FormTimeTextbox from '@/components/base/FormTimeTextbox.vue'
import FormFileUpload from '@/components/base/FormFileUpload.vue'
import FormToggle from '@/components/base/FormToggle.vue'
import Modal from '@/components/base/Modal.vue'
import Table from '@/components/base/Table.vue'
import Grid from '@/components/base/Grid.vue'
import Separator from '@/components/base/Separator.vue'
import LoadingSpinner from '@/components/base/LoadingSpinner.vue'
import DocumentTitle from '@/components/base/DocumentTitle.vue'
import TimeFormatted from '@/components/base/TimeFormatted.vue'
import Icon from '@/components/base/Icon.vue'

Vue.component('Avatar', Avatar)
Vue.component('BubbleBox', BubbleBox)
Vue.component('CollapseContainer', CollapseContainer)
Vue.component('ColorStripe', ColorStripe)
Vue.component('Form', Form)
Vue.component('FormAutocomplete', FormAutocomplete)
Vue.component('FormButton', FormButton)
Vue.component('FormConfirmButton', FormConfirmButton)
Vue.component('FormColorDropdown', FormColorDropdown)
Vue.component('FormDatepicker', FormDatepicker)
Vue.component('FormDatepickerRanged', FormDatepickerRanged)
Vue.component('FormDropdown', FormDropdown)
Vue.component('FormMultiselectDropdown', FormMultiselectDropdown)
Vue.component('FormTagsDropdown', FormTagsDropdown)
Vue.component('GroupDropdown', GroupDropdown)
Vue.component('FormField', FormField)
Vue.component('FormLabel', FormLabel)
Vue.component('FormTextarea', FormTextarea)
Vue.component('FormTextareaParser', FormTextareaParser)
Vue.component('FormTextbox', FormTextbox)
Vue.component('FormTimeTextbox', FormTimeTextbox)
Vue.component('FormFileUpload', FormFileUpload)
Vue.component('FormToggle', FormToggle)
Vue.component('Modal', Modal)
Vue.component('Table', Table)
Vue.component('Grid', Grid)
Vue.component('Separator', Separator)
Vue.component('LoadingSpinner', LoadingSpinner)
Vue.component('DocumentTitle', DocumentTitle)
Vue.component('TimeFormatted', TimeFormatted)
Vue.component('Icon', Icon)
