/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Teams',
  allTeams: 'All teams',
  createButton: 'Create team',
  join: 'Join',
  leave: 'Leave'
}
