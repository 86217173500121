/**
 * @entry Era
 * @author Morten Mehus <morten.mehus@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import Vue from 'vue'
import bus from '@/services/bus'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getStopwatches, createStopwatch, toggleStopwatch, removeStopwatch } from '@/services/api'
import { TimeEntry, TimeEntryFormData } from '@/types/era'
import i18n from '@/providers/i18n'

@Module({ namespaced: true })
export default class StopwatchesModule extends VuexModule {
  stopwatches: { [key: number]: TimeEntry } = {}

  currentStopwatch: TimeEntry | null = null

  @Mutation
  SET_STOPWATCHES (stopwatches: TimeEntry[]) {
    Vue.set(this, 'stopwatches', {})

    let active: TimeEntry | null = null

    stopwatches.forEach(stopwatch => {
      Vue.set(this.stopwatches, stopwatch.id, stopwatch)

      if (stopwatch.startedAt) {
        active = stopwatch
      }
    })

    if (this.currentStopwatch) {
      if (this.currentStopwatch.id !== (active as TimeEntry | null)?.id) {
        bus.$emit('stopwatch-stopped', { ...this.currentStopwatch })
      }
    }
    this.currentStopwatch = active
  }

  @Mutation
  REMOVE_STOPWATCH (entryId: number) {
    const stopwatches = { ...this.stopwatches }
    delete stopwatches[entryId]

    Vue.set(this, 'stopwatches', stopwatches)

    if (this.currentStopwatch?.id === entryId) {
      bus.$emit('stopwatch-stopped', { ...this.currentStopwatch })
      this.currentStopwatch = null
    }
  }

  @Action({ commit: 'SET_STOPWATCHES' })
  loadAll () {
    return getStopwatches()
  }

  @Action({})
  async createStopwatch (entry: TimeEntryFormData) {
    const defaultTitle = i18n.t('common.stopwatch.defaultTitle') as string

    await createStopwatch({
      ...entry,
      title: entry.title || defaultTitle
    })

    await this.context.dispatch('loadAll')
  }

  @Action({})
  async toggleStopwatch (entryId: number) {
    await toggleStopwatch(entryId)

    await this.context.dispatch('loadAll')
  }

  @Action({ commit: 'REMOVE_STOPWATCH' })
  async removeStopwatch (entryId: number) {
    await removeStopwatch(entryId)

    return entryId
  }
}
