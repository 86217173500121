




















































































































































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { hoursAndMinutesFromTime } from '@/utils/date'
import { ProjectSummary, Client } from '@/types/era'
import UserLink from '@/components/layout/UserLink.vue'
import DateTime from '@/components/layout/DateTime.vue'
import { parseHashtags } from '@/utils'

const p = (n: number) => String(n).padStart(1, '0')

@Component({
  components: {
    UserLink,
    DateTime
  }
})
export default class ProjectLog extends Vue {
  @Prop({ required: true })
  readonly project!: ProjectSummary

  formatHours (s: number): string {
    return p(hoursAndMinutesFromTime(s).hours)
  }

  formatMinutes (s: number): string {
    return p(hoursAndMinutesFromTime(s).minutes)
  }

  formatTime (s: number): string {
    const { hours, minutes } = hoursAndMinutesFromTime(s)

    return this.$t('common.hoursAndMinutes', {
      hours: p(hours),
      minutes: p(minutes)
    }) as string
  }

  bulletColumnStyles (index: number) {
    return {
      [this.$style.bulletColumn]: true,
      [this.$style.bulletColumnFirst]: index === 0,
      [this.$style.bulletColumnLast]: index >= this.project.entries!.length - 1
    }
  }

  clientName (client: Client, index: number) {
    if (index < this.project.clients.length - 1) {
      return `${client.title}, `
    }

    return client.title
  }

  get totalEntryCount () {
    if (this.project) {
      return this.project.totalEntries! - this.project.entries!.length
    }

    return 0
  }

  @Emit('more')
  onMoreClick () {
  }

  parseTitle (title: string):string {
    return parseHashtags(title)
  }
}
