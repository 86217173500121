/**
 * @entry Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getEntries } from '@/services/api'
import { TimeEntry } from '@/types/era'

@Module({ namespaced: true })
export default class HoursModule extends VuexModule {
  period: Date = new Date()
  entries: TimeEntry[] = []

  @Mutation
  SET_ENTRIES (entries: TimeEntry[]) {
    this.entries = entries
  }

  @Mutation
  SET_PERIOD (period: Date) {
    this.period = period
  }

  @Action({ commit: 'SET_ENTRIES' })
  loadAll () {
    return getEntries(this.period)
  }

  @Action({ commit: 'SET_PERIOD' })
  setPeriod (period: Date) {
    return period
  }
}
