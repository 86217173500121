








































import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'
import Navigation from '@/components/layout/Navigation.vue'
import MessageBox from '@/components/layout/MessageBox.vue'
import Blackout from '@/components/layout/Blackout.vue'
import bus from '@/services/bus'

@Component({
  components: {
    Navigation,
    MessageBox,
    Blackout
  }
})
export default class App extends Vue {
  showNavigation: boolean = true

  @State('blackoutVisible', { namespace: 'interface' })
  isBlackoutVisible!: boolean

  @Getter('isLoading', { namespace: 'interface' })
  isLoading!: boolean

  @Watch('$route')
  watchRoute (r: any) {
    this.showNavigation = r.meta.showNavigation !== false
  }

  beforeMount () {
    if (this.$route && this.$route.meta) {
      this.showNavigation = this.$route.meta.showNavigation !== false
    }
  }

  mounted () {
    document.addEventListener('click', ev => {
      bus.$emit('document-click', ev)
    }, true)
  }
}
