































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class StopwatchButton extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly active!: boolean

  @Emit('click')
  onClick () {}

  get icon () {
    return this.active ? 'PauseCircle' : 'PlayCircle'
  }
}
