



























































































































































































































































































































import { nanoid } from 'nanoid'
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import format from 'date-fns/format'
import Filters from '@/components/layout/Filters.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'
import ReportExportModal from '@/components/modals/ReportExportModal.vue'
import UserGroupLink from '@/components/layout/UserGroupLink.vue'
import { hoursAndMinutesFromTimePadded, parseHashtags } from '@/utils'
import { Team, Client, User, FilterStateValues, FilterStateItem, TimeEntry, FilterState, MultiselectDropdownOption } from '@/types/era'

@Component({
  components: {
    DefaultTemplate,
    Filters,
    ReportExportModal,
    UserGroupLink
  }
})
export default class ReportsView extends Vue {
  startExport: boolean = false

  @State('dates', { namespace: 'reports' })
  dates!: Date[]

  @State('teams', { namespace: 'reports' })
  teams!: Team[]

  @State('clients', { namespace: 'reports' })
  clients!: Client[]

  @State('report', { namespace: 'reports' })
  report!: User[]

  @State('tree', { namespace: 'reports' })
  reportTree!: any

  @State('projectTree', { namespace: 'reports' })
  projectTree!: any

  @State('groupFilter', { namespace: 'reports' })
  groupFilter!: string

  @State('subGroupFilter', { namespace: 'reports' })
  subGroupFilter!: string

  @Action('loadInitial', { namespace: 'reports' })
  loadInitial!: () => Promise<void>

  @Action('createReport', { namespace: 'reports' })
  createReport!: (options: any) => Promise<void>

  @Action('createReportDownload', { namespace: 'reports' })
  createReportDownload!: (options: any) => Promise<Blob>

  @Action('setDates', { namespace: 'reports' })
  setDates!: (dates: Date[]) => Promise<void>

  @Action('toggleReportTreeUser', { namespace: 'reports' })
  toggleReportTreeUser!: (userId: number) => Promise<void>

  @Action('toggleReportTreeProject', { namespace: 'reports' })
  toggleReportTreeProject!: (options: any) => Promise<void>

  @Action('setGroup', { namespace: 'reports' })
  setGroup!: (options: string) => Promise<void>

  @Action('setSubGroup', { namespace: 'reports' })
  setSubGroup!: (options: string) => Promise<void>

  items: FilterStateValues = {
    projects: [],
    clients: [],
    users: [],
    teams: [],
    tags: []
  }

  group: MultiselectDropdownOption[] = [
    {
      key: nanoid(11),
      value: 'user',
      label: 'Users',
      selected: true,
      items: [
        {
          key: nanoid(11),
          value: 'project',
          label: 'Projects',
          selected: false,
          disabled: false
        },
        {
          key: nanoid(11),
          value: 'client',
          label: 'Client',
          selected: false,
          disabled: false
        }
      ]
    },
    {
      key: nanoid(11),
      value: 'client',
      label: 'Client',
      selected: false,
      items: [
        {
          key: nanoid(11),
          value: 'user',
          label: 'Users',
          selected: false,
          disabled: false
        },
        {
          key: nanoid(11),
          value: 'project',
          label: 'Projects',
          selected: false,
          disabled: false
        }
      ]
    },
    {
      key: nanoid(11),
      value: 'project',
      label: 'Projects',
      selected: false,
      items: [
        {
          key: nanoid(11),
          value: 'user',
          label: 'Users',
          selected: false,
          disabled: false
        },
        {
          key: nanoid(11),
          value: 'client',
          label: 'Client',
          selected: false,
          disabled: false
        }
      ]
    }
  ]

  created () {
    this.loadInitial()
    this.loadReport()
  }

  onRemoveItem (name: string, item: FilterStateItem, index: number) {
    const list = this.items[name] as FilterStateItem[]
    list.splice(index, 1)

    this.loadReport()
  }

  onAddItem (name: string, item: FilterStateItem) {
    const list = this.items[name] as FilterStateItem[]
    list.push(item)

    this.loadReport()
  }

  loadReport (exportAs?: string) {
    const projects = this.items.projects.map(({ id }) => id)
    const clients = this.items.clients.map(({ id }) => id)
    const users = this.items.users.map(({ id }) => id)
    const teams = this.items.teams.map(({ id }) => id)
    const tags = this.items.tags.map(({ id }) => id)
    const options = {
      dates: this.dates,
      projects,
      clients,
      users,
      teams,
      tags
    }

    if (exportAs) {
      return this.createReportDownload({
        options,
        exportAs
      })
    }

    return this.createReport(options)
  }

  totalHoursLogged (entries: TimeEntry[]) {
    const total = entries.reduce((acc, curr) => {
      return acc + curr.timeSpent
    }, 0)

    return this.formattedTime(total)
  }

  totalHoursLogged2 (total: number) {
    return this.formattedTime(total)
  }

  formattedTime (time: number) {
    const { hours, minutes } = hoursAndMinutesFromTimePadded(time)

    return `${hours}h ${minutes}m`
  }

  onExportClick () {
    this.startExport = true
  }

  async onModalSubmit (format: string) {
    this.startExport = false

    const file = await this.loadReport(format)
    const data = URL.createObjectURL(file)
    const a = document.createElement('a')
    a.href = data
    a.target = '_blank'
    a.download = `report.${format}`
    a.click()
  }

  onModalClose () {
    this.startExport = false
  }

  async onDateSelect (dates: Date[]) {
    await this.setDates(dates)

    this.loadReport()
  }

  onMultiselectEntry (item: MultiselectDropdownOption) {
    const list = this.items[item.key] as FilterStateItem[]
    const found = list.findIndex(li => li.id === item.value)

    if (found === -1) {
      list.push({
        id: item.value,
        type: item.key,
        title: item.label
      })
    } else {
      list.splice(found, 1)
    }

    this.loadReport()
  }

  onMultiselectAll (type: string) {
    if (type === 'clients') {
      const selectClients = this.clients.map(client => ({
        id: client.id,
        type: 'clients',
        title: client.title
      }))

      const selectProjects = this.clients
        .flatMap(client => client.projects)
        .map(project => ({
          id: project!.id,
          type: 'clients',
          title: project!.title
        }))

      this.items.clients = selectClients
      this.items.projects = selectProjects
    } else {
      const selectTeams = this.teams.map(team => ({
        id: team.id,
        type: 'teams',
        title: team.title
      }))

      const selectUsers = this.teams
        .flatMap(team => team.users)
        .map(user => ({
          id: user!.id,
          type: 'teams',
          title: user!.username
        }))

      this.items.teams = selectTeams
      this.items.users = selectUsers
    }
  }

  onMultiselectNone (type: string) {
    if (type === 'clients') {
      this.items.clients = []
      this.items.projects = []
    } else {
      this.items.teams = []
      this.items.users = []
    }
  }

  onSelectGroup (item: MultiselectDropdownOption) {
    this.setGroup(item.value)
    const subgroup = item.items!.find(element => element)
    this.setSubGroup(subgroup?.value)
    this.loadReport()
  }

  onSelectSubgroup (item: MultiselectDropdownOption) {
    this.setSubGroup(item.value)
    this.loadReport()
  }

  onUserExpandToggle (id: number) {
    this.toggleReportTreeUser(id)
  }

  onProjectExpandToggle (userId: number, projectId: number) {
    this.toggleReportTreeProject({ userId, projectId })
  }

  get clientOptions () {
    const cids = this.items.clients.map(li => li.id)
    const pids = this.items.projects.map(li => li.id)

    return this.clients.map(client => ({
      key: 'clients',
      value: client.id,
      label: client.title,
      selected: cids.includes(client.id),
      items: client.projects!.map(project => ({
        key: 'projects',
        value: project.id,
        label: project.title,
        selected: pids.includes(project.id)
      }))
    }))
  }

  get teamOptions () {
    const tids = this.items.teams.map(li => li.id)
    const uids = this.items.users.map(li => li.id)

    return this.teams.map(team => ({
      key: 'teams',
      value: team.id,
      label: team.title,
      selected: tids.includes(team.id),
      items: team.users!.map(user => ({
        key: 'users',
        value: user.id,
        label: user.fullName || user.username || user.email,
        selected: uids.includes(user.id)
      }))
    }))
  }

  get groupOptions () {
    return this.group.map((item) => {
      item.selected = item.value === this.groupFilter
      item.items!.map((i) => {
        i.selected = i.value === this.subGroupFilter
        return i
      })
      return item
    })
  }

  get filters (): FilterState {
    return {
      projects: {
        label: this.$t('reports.labels.clients') as string,
        items: [...this.items.projects, ...this.items.clients]
      },
      users: {
        label: this.$t('reports.labels.teams') as string,
        items: [...this.items.teams, ...this.items.users]
      },
      tags: {
        label: this.$t('reports.labels.tags') as string,
        items: this.items.tags
      }
    }
  }

  get totalTimeSpent (): number {
    return this.report.reduce((acc, curr: User) => {
      const time = curr.entries!.reduce((accTime, currEntry: TimeEntry) => {
        return accTime + currEntry.timeSpent
      }, 0)

      return acc + time
    }, 0)
  }

  get datePlaceholder (): string {
    const [start, end] = this.dates
    const strings = [
      start ? format(start, 'LL-dd-yyyy') : undefined,
      end ? format(end, 'LL-dd-yyyy') : undefined
    ].filter(str => str !== undefined)

    return strings.length > 0
      ? strings.join(' - ')
      : this.$t('reports.filters.time') as string
  }

  parseTitle (title: string):string {
    return parseHashtags(title)
  }
}
