











































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { MultiselectDropdownOption } from '@/types/era'

@Component({})
export default class FormMultiselectDropdownDrawer extends Vue {
  @Prop({ required: true })
  readonly items!: MultiselectDropdownOption[]

  @Emit('select')
  onSelect (ev: MouseEvent, item: MultiselectDropdownOption) {
    return item
  }

  @Emit('selectAll')
  onSelectAll (item?: MultiselectDropdownOption) {
    return item
  }

  @Emit('selectNone')
  onSelectNone (item?: MultiselectDropdownOption) {
    return item
  }
}
