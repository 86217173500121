/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { login, register, createUserAvatarUrl } from '@/services/api'
import { UserAuth, Auth, UserLoginFormData, UserRegisterFormData } from '@/types/era'

@Module({ namespaced: true })
export default class AuthModule extends VuexModule {
  profile: UserAuth | null = null
  accessToken: string | null = null // FIXME: Nope!
  expires: string | null = null
  avatarHash: number = Date.now()

  @Mutation
  LOGOUT () {
    this.accessToken = null
    this.profile = null
    this.expires = null
  }

  @Mutation
  LOGIN (authResult: Auth) {
    this.accessToken = authResult.token
    this.profile = authResult.profile
    this.expires = authResult.expires
  }

  @Mutation
  BUMP_AVATAR_HASH (hash: number) {
    this.avatarHash = hash
  }

  @Action({ commit: 'LOGOUT' })
  logout () {
  }

  @Action({ commit: 'LOGIN' })
  login (form: UserLoginFormData) {
    return login(form)
  }

  @Action({ commit: 'LOGIN' })
  register (form: UserRegisterFormData) {
    return register(form)
  }

  @Action({ commit: 'BUMP_AVATAR_HASH' })
  bumpAvatarHash () {
    return Date.now()
  }

  // NOTE: Workaround for TS compiler foo
  //       Object is of type 'unknown'
  //       When using "store.state.auth.accessToken"
  public get token (): string | null {
    return this.accessToken
  }

  public get isExpired (): boolean {
    if (this.expires) {
      const now = new Date()
      const expires = new Date(this.expires)
      const expired = now > expires

      return expired
    }

    return true
  }

  public get isLoggedIn (): boolean {
    return this.hasLoggedIn && !this.isExpired
  }

  public get hasLoggedIn (): boolean {
    return !!this.expires
  }

  public get avatarSource (): string {
    return `${createUserAvatarUrl(this.profile!.id)}#${this.avatarHash}`
  }
}
