/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Profile Settings',
  info: {
    name: 'Full name',
    username: 'Username',
    phone: 'Phone number',
    email: 'Email',
    description: 'Description'
  },
  avatar: {
    heading: 'Upload new profile picture',
    placeholder: 'Chose file'
  },
  notifications: {
    defaultPlaceholder: 'System default',
    heading: 'Notifications',
    language: 'Language',
    firstDayOfWeek: 'First day of the week',
    dateFormat: 'Date format',
    timeFormat: 'Time format',
    notifyAdditions: 'When you have been added to a new team',
    notifyTimeAdditions: 'When someone has logged hours for you',
    notifyNoTime: 'If you haven\'t logged any hours on a day',
    notifySummary: 'Summary of last weekds logged hours'
  },
  password: {
    heading: 'Change password',
    current: 'Current password',
    create: 'New password',
    confirm: 'Password confirmation',
    submit: 'Change password'
  }
}
