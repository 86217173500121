/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getProject, getProjects, createProject, updateProject, deleteProject } from '@/services/api'
import { filterList, randomColor } from '@/utils'
import { Project, ProjectFormData, Client } from '@/types/era'

@Module({ namespaced: true })
export default class ProjectsModule extends VuexModule {
  clientFilter: number = -1
  filter: string = ''
  project: Project | null = null
  projects: Project[] = []

  @Mutation
  SET_PROJECTS (projects: Project[]) {
    this.projects = projects
  }

  @Mutation
  SET_PROJECT (project?: Project) {
    this.project = project || null
  }

  @Mutation
  APPLY_PROJECT (project: Partial<Project>) {
    if (this.project !== null) {
      this.project = {
        ...this.project,
        ...project
      }
    }
  }

  @Mutation
  SET_CLIENT_FILTER (filter: number) {
    this.clientFilter = filter
  }

  @Mutation
  SET_FILTER (filter: string) {
    this.filter = filter
  }

  @Action({ commit: 'SET_PROJECTS' })
  loadAll () {
    return getProjects()
  }

  @Action({ commit: 'SET_PROJECT' })
  load (id: number) {
    return getProject(id)
  }

  @Action({ commit: 'SET_PROJECTS' })
  async create (project: ProjectFormData) {
    await createProject(project)
    return getProjects()
  }

  @Action({ commit: 'SET_PROJECTS' })
  async update (project: ProjectFormData) {
    await updateProject(project)
    return getProjects()
  }

  @Action({ commit: 'SET_PROJECTS' })
  async remove (project: ProjectFormData) {
    await deleteProject(project)
    return getProjects()
  }

  @Action({ commit: 'APPLY_PROJECT' })
  applyCurrent (project: Partial<Project>) {
    return project
  }

  @Action({ commit: 'SET_PROJECT' })
  createCurrent (): ProjectFormData {
    return {
      id: 0,
      title: '',
      description: '',
      clients: [],
      color: randomColor()
    }
  }

  @Action({ commit: 'SET_PROJECT' })
  clearCurrent () {
    return null
  }

  @Action({ commit: 'SET_FILTER' })
  setFilter (filter: string) {
    return filter
  }

  @Action({ commit: 'SET_CLIENT_FILTER' })
  setClientFilter (filter: number = -1) {
    return filter
  }

  public get filtered (): Project[] {
    const list = filterList(this.projects, 'title', this.filter)

    if (this.clientFilter > 0) {
      return list.filter(project => project.clients.some((client: Client) => client.id === this.clientFilter))
    }

    return list
  }
}
