/**
 * @client Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getClient } from '@/services/api'
import { filterList } from '@/utils'
import { Client, Project } from '@/types/era'

@Module({ namespaced: true })
export default class ClientModule extends VuexModule {
  filter: string = ''
  client: Client | null = null

  @Mutation
  SET_CLIENT (client?: Client) {
    this.client = client || null
  }

  @Mutation
  SET_FILTER (filter: string) {
    this.filter = filter
  }

  @Action({ commit: 'SET_CLIENT' })
  load (id: number) {
    return getClient(id)
  }

  @Action({ commit: 'SET_FILTER' })
  setFilter (filter: string) {
    return filter
  }

  public get filteredProjects (): Project[] {
    if (this.client) {
      const list = this.client.projects as Project[]

      return this.filter.length > 0
        ? filterList(list, 'title', this.filter)
        : list
    }

    return []
  }
}
