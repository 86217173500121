











































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormField extends Vue {
  @Prop({ default: 'left' })
  readonly align!: string

  @Prop({ default: true })
  readonly expand!: boolean

  @Prop({ default: 'flex-start' })
  readonly justify!: string

  get styles () {
    return {
      justifyContent: this.justify
    }
  }
}
