/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  hero: 'Era makes time tracking so simple you\'ll actually use it. Laoreet sit amet cursus sit amet dictum felis bibendum ut.',
  calendarBox: {
    title: 'Calendar view',
    text: [
      'Get a simple overview of your week, and how many hours you have logged.',
      'You can easily add more hours, both on past and future days, or just add your whole week in one text block.',
      'Of course, Era formats everything for you and adds it to your calendar.'
    ].join('')
  },
  reportsBox: {
    title: 'Create reports',
    text: [
      'Create your own reports quickly and easily. You can make your reports as simple or advanced as you like, and of course export them to all the most widely used formats.',
      'If you need an overview of how many hours are spent on a project or which tasks took most time, then we are the solution for you.'
    ].join('')
  },
  teamsBox: {
    title: 'Team overview',
    text: [
      'See how employees or colleagues spend their time from a single dashboard, including logged hours, total logged hours and which employees who forgets to log their hours.'
    ].join('')
  },
  blurbs: {
    track: 'Track hours using a timer',
    log: 'Log time manually',
    create: 'Create reports',
    export: 'Export reports in PDF, CSV and HTML',
    tags: 'Track hours using tags',
    teams: 'See how your team spends time'
  }
}
