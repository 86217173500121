/**
 * @client Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { entries, uniqBy } from 'lodash-es'
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import { createReport, getTeams, getClients } from '@/services/api'
import { User, Client, Team, Project, TimeEntry } from '@/types/era'
import { id } from 'date-fns/locale'

interface ReportTreeProjectToggle {
  userId: number
  projectId: number
}

interface ReportTreeProject {
  project: Project
  entries: TimeEntry[]
  _expanded: boolean
}

interface ReportTree {
  user: User
  projects: ReportTreeProject[]
  _expanded: boolean
}

interface ProjectTreeView {
  users: User[]
  project: Project
  _expanded: boolean
}

interface Group {
  id: number
  title: string | undefined
  type: string
  _expanded: boolean
  totalTime?: number | undefined
  entries?: TimeEntry[]
  color?: string | undefined | null
}

interface ReportGroup {
  group: Group
  subgroups: Group[]
  _expanded: boolean
}

@Module({ namespaced: true })
export default class ReportsModule extends VuexModule {
  dates: Date[] = []
  report: User[] = []
  clients: Client[] = []
  teams: Team[] = []
  // tree: ReportTree[] = []
  projectTree: ReportGroup[] = []

  groupFilter: string = 'user'
  subGroupFilter: string = 'project'

  @Mutation
  SET_REPORT (report: User[]) {
    this.report = report

    const totalTime = (entries: TimeEntry[]) => (entries.reduce((x: number, y: TimeEntry) => x + y.timeSpent, 0))

    const getGroup = (
      id: number,
      title: string,
      type: string,
      entries: TimeEntry[],
      color?: string | undefined | null
    ) => {
      const tt = totalTime(entries)
      return {
        id,
        title,
        type,
        entries,
        color,
        totalTime: tt,
        _expanded: true
      }
    }

    const getUniqueProjects = () => {
      return report.reduce((result: any, user: User) => {
        const projects = uniqBy(
          user.entries!.flatMap((e: TimeEntry) => e.projects),
          (project: Project) => project.id
        )
        return uniqBy([...result, ...projects], (project: Project) => project.id)
      }, [])
    }

    const projectSubgroup = (user: User) => {
      const uniqueProjects = uniqBy(
        user.entries!.flatMap((e: TimeEntry) => e.projects),
        (project: Project) => project.id
      )

      return uniqueProjects.map((project: Project) => {
        const entries = user.entries!.filter(entry => {
          return entry.projects
            .some((p: Project) => p.id === project.id)
        })

        const tt = totalTime(entries)
        return {
          id: project.id,
          title: project.title,
          _expanded: true,
          type: 'project',
          totalTime: tt,
          entries: entries,
          color: project.color!
        }
      })
    }

    const userSubgroup = (projectId: Number) => {
      return report.filter((user: User) => {
        const entries = user.entries!.filter(entry => {
          return entry.projects
            .some((p: Project) => p.id === projectId)
        })
        return entries.length > 0
      }).map((user: User) => {
        const entries = user.entries!.filter(entry => {
          return entry.projects
            .some((p: Project) => p.id === projectId)
        })
        return getGroup(user.id, user.fullName || user.username || user.email, 'user', entries)
      })
    }

    const clientSubgroup = (user: User) => {
      const projects = uniqBy(
        user.entries!.flatMap((e: TimeEntry) => e.projects),
        (project: Project) => project.id
      )

      const data = projects.reduce((result: any, project: Project) => {
        const clients = project.clients.flatMap(c => c)
        return uniqBy([...result, ...clients], (client: Client) => client.id)
      }, []).map((client: Client) => {
        const clientProjects = projects.filter((project: Project) =>
          project.clients.some((c: Client) => c.id === client.id)
        )
        const entries = clientProjects.reduce((result: any, project: Project) => {
          return uniqBy([...result, ...project.entries!.filter((x) => x.userId === user.id)], (e: TimeEntry) => e.id)
        }, [])

        return getGroup(client.id, client.title, 'client', entries, client.color!)
      })
      return data
    }

    const userGroup = () => {
      return report.map((user: User) => {
        let subgroups:Group[] = []
        if (this.subGroupFilter === 'project') {
          subgroups = projectSubgroup(user)
        }

        if (this.subGroupFilter === 'client') {
          subgroups = clientSubgroup(user)
        }

        return {
          group: {
            id: user.id,
            title: user.fullName || user.username || user.email,
            type: 'user',
            totalTime: totalTime(user.entries!),
            entries: user.entries! || [],
            _expanded: true
          },
          subgroups: subgroups,
          _expanded: true
        }
      })
    }

    const projectGroup = () => {
      return getUniqueProjects().map((project: Project) => {
        let subgroups:Group[] = []
        let tt = 0
        if (this.subGroupFilter === 'user') {
          subgroups = userSubgroup(project.id)
          tt = subgroups.reduce((x, y) => x + (y.totalTime ? y.totalTime : 0), 0)
        }

        if (this.subGroupFilter === 'client') {
          subgroups = project.clients.map((x: Client) => getGroup(x.id, x.title, 'client', project.entries!, x.color))
        }

        return {
          group: {
            id: project.id,
            title: project.title,
            type: 'project',
            _expanded: true,
            totalTime: tt,
            entries: project.entries,
            color: project.color!
          },
          subgroups: subgroups,
          _expanded: true
        }
      })
    }

    const clientGroup = () => {
      const projects = getUniqueProjects()
      const data = projects.reduce((result: any, project: Project) => {
        const clients = project.clients.flatMap(c => c)
        return uniqBy([...result, ...clients], (client: Client) => client.id)
      }, []).map((client: Client) => {
        const clientProjects = projects.filter((project: Project) =>
          project.clients.some((c: Client) => c.id === client.id)
        )
        const entries = clientProjects.reduce((result: any, project: Project) => {
          return uniqBy([...result, ...project.entries!], (e: TimeEntry) => e.id)
        }, [])

        let subgroups:Group[] = []
        if (this.subGroupFilter === 'user') {
          subgroups = clientProjects.reduce((result: any, project: Project) => {
            return [...result, ...userSubgroup(project.id)]
          }, []).reduce((result: any, user: Group) => {
            const exists = result.find((x: Group) => x.id === user.id)
            if (exists) {
              exists.entries = [...exists.entries!, ...user.entries!]
              exists.totalTime = exists.totalTime + user.totalTime
              return result
            }
            return [...result, user]
          }, [])
        }

        if (this.subGroupFilter === 'project') {
          subgroups = clientProjects.map((x: Project) => getGroup(x.id, x.title, 'project', x.entries!, x.color))
        }

        return {
          group: getGroup(client.id, client.title, 'client', entries, client.color),
          subgroups: subgroups,
          _expanded: true
        }
      })

      return data
    }

    if (this.groupFilter === 'user') {
      this.projectTree = userGroup()
    }

    if (this.groupFilter === 'project') {
      this.projectTree = projectGroup()
    }

    if (this.groupFilter === 'client') {
      this.projectTree = clientGroup()
    }
  }

  @Mutation
  SET_DATES (dates: Date[]) {
    this.dates = dates
  }

  @Mutation
  TOGGLE_REPORT_TREE_USER (userId: number) {
    const foundIndex = this.projectTree.findIndex(({ group }) => group.id === userId)
    if (foundIndex !== -1) {
      this.projectTree[foundIndex]._expanded = !this.projectTree[foundIndex]._expanded
    }
  }

  @Mutation
  TOGGLE_REPORT_TREE_PROJECT ({ userId, projectId }: ReportTreeProjectToggle) {
    const foundIndex = this.projectTree.findIndex(({ group }) => group.id === userId)
    if (foundIndex !== -1) {
      const foundProjectIndex = this.projectTree[foundIndex].subgroups.findIndex(
        ({ id }) => id === projectId
      )

      if (foundProjectIndex !== -1) {
        const p = this.projectTree[foundIndex].subgroups[foundProjectIndex]
        this.projectTree[foundIndex].subgroups[foundProjectIndex]._expanded = !p._expanded
      }
    }
  }

  @Mutation
  SET_GROUP (group: string) {
    this.groupFilter = group
  }

  @Mutation
  SET_SUBGROUP (group: string) {
    this.subGroupFilter = group
  }

  @Action({ commit: 'SET_GROUP' })
  setGroup (group: string = 'user') {
    return group
  }

  @Action({ commit: 'SET_SUBGROUP' })
  setSubGroup (group: string = 'project') {
    return group
  }

  @Action({ commit: 'SET_DATES' })
  setDates (dates: Date[] = []) {
    return dates
  }

  @Action({ commit: 'SET_REPORT' })
  createReport (options: any) {
    return createReport(options)
  }

  @Action({})
  createReportDownload ({
    options,
    exportAs
  }: any) {
    return createReport(options, exportAs)
  }

  @Action({ commit: 'TOGGLE_REPORT_TREE_USER' })
  toggleReportTreeUser (userId: number) {
    return userId
  }

  @Action({ commit: 'TOGGLE_REPORT_TREE_PROJECT' })
  toggleReportTreeProject (options: any) {
    return options
  }

  @MutationAction({ mutate: ['teams', 'clients'] })
  async loadInitial () {
    const teams = await getTeams({
      includeUsers: true
    })

    const clients = await getClients({
      includeProjects: true
    })

    return { teams, clients }
  }
}
