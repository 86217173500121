/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import { FirstDayOfWeek, MessageboxMessage } from '@/types/era'
import i18n from '@/providers/i18n'
import config from '@/config'

const outdated = (now: number, then: number) => {
  return (now - then > 5000)
}

// This is for v1 -> v2 of date-fns
const migrateDates: any = {
  'MM.DD.YY': 'LL.dd.yy',
  'DD.MM.YY': 'dd.LL.yy'
}

@Module({ namespaced: true })
export default class SessionModule extends VuexModule {
  language: string = config.language
  timeFormat: string = config.timeFormat
  dateFormat: string = config.dateFormat
  firstDayOfWeek: FirstDayOfWeek = config.firstDayOfWeek
  messages: MessageboxMessage[] = []
  timeEntryVisible: boolean = true
  timeEntryTextarea: boolean = false

  @Mutation
  SET_TIME_FORMAT (fmt: string) {
    this.timeFormat = fmt
  }

  @Mutation
  SET_DATE_FORMAT (fmt: string) {
    this.dateFormat = fmt
  }

  @Mutation
  SET_FIRST_DAY_OF_WEEK (value: FirstDayOfWeek) {
    this.firstDayOfWeek = value
  }

  @Mutation
  SET_LOCALE (locale: string) {
    this.language = locale

    i18n.locale = 'en'
  }

  @Mutation
  TOGGLE_ENTRY_VISIBILITY (toggle: boolean) {
    this.timeEntryVisible = toggle
  }

  @Mutation
  TOGGLE_TEXTAREA_VISIBILITY (toggle: boolean) {
    this.timeEntryTextarea = toggle
  }

  @Mutation
  ADD_MESSAGE (message: MessageboxMessage) {
    this.messages.push(message)
  }

  @Mutation
  REMOVE_MESSAGE (index: number) {
    this.messages.splice(index, 1)
  }

  @Mutation
  SET_MESSAGES (messages: MessageboxMessage[]) {
    this.messages = messages
  }

  @Action({ commit: 'TOGGLE_ENTRY_VISIBILITY' })
  toggleEntryVisiblity () {
    return !this.timeEntryVisible
  }

  @Action({ commit: 'TOGGLE_TEXTAREA_VISIBILITY' })
  toggleEntryTextarea () {
    return !this.timeEntryTextarea
  }

  @Action({ commit: 'ADD_MESSAGE' })
  addMessage (message: Partial<MessageboxMessage>) {
    return {
      created: (new Date()).getTime(),
      ...message
    }
  }

  @Action({ commit: 'REMOVE_MESSAGE' })
  removeMessage (index: number) {
    return index
  }

  @Action({ commit: 'SET_LOCALE' })
  setLocale (locale?: string) {
    return locale || config.language
  }

  @Action({ commit: 'SET_DATE_FORMAT' })
  setDateFormat (fmt: string) {
    return fmt
  }

  @Action({ commit: 'SET_TIME_FORMAT' })
  setTimeFormat (fmt: number) {
    return fmt
  }

  @Action({ commit: 'SET_FIRST_DAY_OF_WEEK' })
  setFirstDayOfWeek (value: FirstDayOfWeek) {
    return value
  }

  @MutationAction({ mutate: ['firstDayOfWeek', 'language', 'timeFormat', 'dateFormat'] })
  setAllLocales (locales: any) {
    const defaults: any = {
      timeFormat: config.timeFormat,
      dateFormat: config.dateFormat,
      firstDayOfWeek: config.firstDayOfWeek,
      language: config.language
    }

    const values = Object
      .keys(locales)
      .map((key: string) => [key, locales[key] || defaults[key]])

    const result = Object.fromEntries(values)
    result.dateFormat = migrateDates[result.dateFormat] || result.dateFormat

    return result
  }

  @Action
  popMessage () {
    const now = (new Date()).getTime()

    const active = this.messages
      .filter(msg => !outdated(now, msg.created))

    if (active.length !== this.messages.length) {
      this.context.commit('SET_MESSAGES', active)
    }
  }

  // NOTE: Workaround for TS compiler foo
  //       Object is of type 'unknown'
  //       When using "store.state.session.locale"
  public get currentLocale (): string | null {
    return this.language
  }
}
