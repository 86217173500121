/**
 * @project Era
 * @author Marko Muric <marko.muric@copyleft.rs>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { EntryCallbackResult } from './interfaces'
import { getSubtractConfig, getDotTime, getNormalTimeFormat, getOrderDate, getYesterday, parseDate, formatTime } from './functions'
import sub from 'date-fns/sub'
import { findInputDetails } from './findInputDetails'
import { format } from 'date-fns'
import { ProjectChoices } from '@/types/era'
import { parseInput } from '@/utils/parsers'

// date regex
const dateRegex = /(\d{1,2}[./-]\d{1,2}[./-]\d{4}|\d{4}[./-]\d{1,2}[./-]\d{1,2}|\d{1,2}[./-]\s?[a-zA-Z]{3,}[\s./-]\d{4}|\d{1,2}[./-]\d{4}[./-]\s?[a-zA-Z]{3,}|[a-zA-Z]{3,}[\s./-]\d{1,2}[./-]\d{4}|[a-zA-Z]{3,}[\s./-]\d{4}[./-]\d{1,2}|\d{4}[./-]\d{1,2}[\s./-]\s?[a-zA-Z]{3,}|\d{4}[\s./-]\s?[a-zA-Z]{3,}[\s./-]\d{1,2})/
const dwmyRegex = /^-((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))/
const dayOrderMonthRegex = /(\d+)(st|nd|rd|th)[\s./-]([a-zA-Z]{3,})/
const monthDayOrderRegex = /([a-zA-Z]{3,})[\s./-](\d+)(st|nd|rd|th)/
const dayOrderRegex = /(\d+)(st|nd|rd|th)/
const yesterdayRegex = /(yesterday|yd|(y+)d|today|td)/
// time regex
const dotTimeRegex = /(?<![.\d])(\d*)[.,](\d*)(?![.\d])/
const normalTimeRegex = /(\d*):(\d*)/
const hmTimeRegex = /(\d+\s?[h|H]?\s?\d+\s?[m|M]|\d+\s?[h|H]|\d+\s?[m|M])/
// ditto regex
const dittoRegex = /(ditto|--)/
const entryDetails = /(.*)/

const getHMTime = (str:string) => {
  const reg = /((\d+)\s?[h|H])? ?((\d+)\s?[m|M])?/
  const res = str.match(reg)!
  return getNormalTimeFormat(res[2], res[4])
}

const returnResult = (
  dateFormat:string,
  date: Date|undefined,
  hours: number|undefined,
  minutes: number|undefined,
  projectName: string|undefined,
  entryTitle: string|undefined,
  currentString: string|undefined
):EntryCallbackResult => {
  return {
    date,
    hours,
    minutes,
    currentString,
    projectName,
    entryTitle,
    dateFormat: date && format(date, `${dateFormat}`),
    fullDate: date && format(date, 'LL-dd-yyyy'),
    time: formatTime(hours, minutes)
  }
}

const findDate = (text:string, dateFormat:string, index?:number, strList?: string[]):{date: Date} => {
  if (index && strList) {
    return parseInput(strList[index - 1], dateFormat, index - 1, strList)
  }
  return { date: new Date() }
}

const findTime = (text:string, dateFormat:string, index?:number|undefined, strList?: string[]):{hours: number, minutes: number} => {
  if (index && strList) {
    const { hours, minutes } = parseInput(strList[index - 1], dateFormat, index - 1, strList)
    return { hours, minutes }
  }
  return { hours: 0, minutes: 0 }
}

// console.log(new RegExp(hmTimeRegex.source + /(.*)/.source))
// console.log(new RegExp(dwmyRegex.source + /\s/.source + dittoRegex.source))

export const regListInputs = [
  {
    reg: new RegExp(dateRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      date = parseDate(date, obj[1])
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: '-1d2w3m4y ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st May ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'May 1st ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      getOrderDate(date, obj[1])
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'yd ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      getYesterday(obj[1], date)
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'ditto H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getDotTime(obj[2], obj[3])
      const { date: d1 } = findDate(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        d1,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'ditto H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getNormalTimeFormat(obj[2], obj[3])
      const { date: d1 } = findDate(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        d1,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'ditto 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getHMTime(obj[2])
      const { date: d1 } = findDate(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        d1,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = parseDate(date, obj[1])
      const { hours, minutes } = getDotTime(obj[2], obj[3])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '-1d2w3m4y H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getDotTime(obj[13], obj[14])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st May H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getDotTime(obj[4], obj[5])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'May 1st H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getDotTime(obj[4], obj[5])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1])
      const { hours, minutes } = getDotTime(obj[3], obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + dotTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'yd H.mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getYesterday(obj[1], date)
      const { hours, minutes } = getDotTime(obj[3], obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = parseDate(date, obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[2], obj[3])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '-1d2w3m4y H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getNormalTimeFormat(obj[13], obj[14])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st May H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getNormalTimeFormat(obj[4], obj[5])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'May 1st H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[4], obj[5])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[3], obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + normalTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'yd H:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getYesterday(obj[1], date)
      const { hours, minutes } = getNormalTimeFormat(obj[3], obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '01.01.2023 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = parseDate(date, obj[1])
      const { hours, minutes } = getHMTime(obj[2])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '-1d2w3m4y 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getHMTime(obj[13])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st May 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getHMTime(obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'May 1st 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getHMTime(obj[4])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: '1st 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1])
      const { hours, minutes } = getHMTime(obj[3])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + hmTimeRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'yd 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getYesterday(obj[1], date)
      const { hours, minutes } = getHMTime(obj[3])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dateRegex.source + entryDetails.source),
    category: 'date',
    format: 'DD.MM.YY/YYYY',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      date = parseDate(date, obj[1])
      const hours = 0
      const minutes = 0

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + entryDetails.source),
    category: 'date',
    format: '-1d2w3m4y ',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const hours = 0
      const minutes = 0
      date = sub(date, getSubtractConfig(obj))

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + entryDetails.source),
    category: 'date',
    format: '1st May',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1], obj[3])
      const hours = 0
      const minutes = 0

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + entryDetails.source),
    category: 'date',
    format: 'May 1st',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[2], obj[1])
      const hours = 0
      const minutes = 0

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + entryDetails.source),
    category: 'date',
    format: '1st',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getOrderDate(date, obj[1])
      const hours = 0
      const minutes = 0

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + entryDetails.source),
    category: 'date',
    format: 'yesterday/yd',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      getYesterday(obj[1], date)
      const hours = 0
      const minutes = 0

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dotTimeRegex.source + entryDetails.source),
    category: 'time',
    format: '2.5 => 2:30',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getDotTime(obj[1], obj[2])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(normalTimeRegex.source + entryDetails.source),
    category: 'time',
    format: 'HH:mm',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getNormalTimeFormat(obj[1], obj[2])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(hmTimeRegex.source + entryDetails.source),
    category: 'time',
    format: '1h10m',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[2]
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList)
      const { hours, minutes } = getHMTime(obj[1])

      return returnResult(
        dateFormat,
        date,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'ditto ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      const { date: d1 } = findDate(obj[0], dateFormat, index, strList)
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        d1,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  },
  {
    reg: new RegExp(dittoRegex.source + entryDetails.source),
    category: 'datetime',
    format: 'ditto',
    fnc: (obj:RegExpMatchArray, date:Date, dateFormat:string, index?:number, strList?: string[]):EntryCallbackResult => {
      const currentString = obj[obj.length - 1]
      const tmp = currentString.split(' - ')
      // in case we do not have following part sentence after yd --
      // we want to copy project name and entry title from previous line
      // this are the flags that will make this happen.
      const isDittoTitle = tmp[0].trim() === ''
      const isDittoEntry = tmp[1] === undefined || tmp[1].trim() === ''
      const { projectName, entryTitle } = findInputDetails(currentString, dateFormat, index, strList, isDittoTitle, isDittoEntry)
      const { date: d1 } = findDate(obj[0], dateFormat, index, strList)
      const { hours, minutes } = findTime(obj[0], dateFormat, index, strList)

      return returnResult(
        dateFormat,
        d1,
        hours,
        minutes,
        projectName,
        entryTitle,
        currentString
      )
    }
  }
]
