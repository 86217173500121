















































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { MultiselectDropdownOption } from '@/types/era'

@Component({})
export default class RadioDropdownDrawer extends Vue {
    @Prop({ type: String, default: '' })
    readonly groupName!: string

    @Prop({ required: true })
    readonly items!: MultiselectDropdownOption[]

    @Emit('select')
    onSelect (ev: MouseEvent, item: MultiselectDropdownOption) {
      return item
    }
}
