




































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { TimeEntry, CalendarCardEvent } from '@/types/era'
import CalendarCard from '@/components/layout/CalendarCard.vue'
import { parseHashtags } from '@/utils'

@Component({
  components: {
    CalendarCard
  }
})
export default class ProfileTimeCard extends Vue {
  @Prop()
  readonly entry!: TimeEntry

  formatProjects () {
    return this.entry.projects
      .map(e => {
        if (e.clients && e.clients.length > 0) {
          return `${e.clients.map(e => e.title).join(', ')} - ${e.title}`
        }

        return e.title
      })
      .join(' / ')
  }

  stripeColor (entry: TimeEntry): string | null {
    if (this.hasProjects) {
      return this.entry.projects[0].color || null // FIXME
    }

    return null
  }

  get hasProjects () {
    return this.entry.projects.length > 0
  }

  @Emit('click')
  onClick (event: Event): CalendarCardEvent {
    return { event, entry: this.entry }
  }

  parseTitle (title: string):string {
    return parseHashtags(title)
  }
}
