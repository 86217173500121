/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  name: 'Navn',
  title: 'Tittel',
  total: 'Total',
  time: 'Tid',
  date: 'Dato',
  today: 'I dag',
  project: 'Prosjekt',
  description: 'Beskrivelse',
  updatedAt: 'Sist oppdatert',
  filterByName: 'Filtrer med navn...',
  totalTimeSpent: 'Total tid logget',
  hoursAndMinutes: '{hours} timer og {minutes} minutter',
  hoursAndMinutesAbbr: '{hours} h {minutes} m',
  register: 'Register',
  signin: 'Logg inn',

  menu: {
    hours: 'Timer',
    projects: 'Prosjekter',
    teams: 'Lag',
    reports: 'Rapporter',
    clients: 'Klienter',
    profile: 'Profil',
    preferences: 'Instillinger',
    toggleLogForm: 'Oppføring skjema',
    toggleLogInput: 'Oppføring tekstfelt',
    toggleLogBox: 'Veksle loggeboks',
    logout: 'Logg ut',
    administration: 'Administrasjon'
  },

  search: {
    placeholder: 'Søk blandt klienter, prosjekter eller lag...',
    searching: 'Søker...',
    noResults: 'Ingen resultater',
    noResultsFor: 'Ingen resultater for "{search}"',
    noOptions: 'Ingen valg'
  },

  timeInput: {
    placeholder: 'Skriv timelogg (DD-MM-YYYY HH:MM Prosjekt - Tittel)'
  },

  timeEntry: {
    submit: 'Loggfør'
  },

  interface: {
    timeLogged: 'Tid ble logget',
    loggedOut: 'Du ble utlogget...'
  }
}
