

















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Client, ClientFormData } from '@/types/era'

@Component
export default class ClientForm extends Vue {
  form: ClientFormData = {
    id: 0,
    title: '',
    color: '',
    description: ''
  }

  created () {
    this.form = {
      ...this.form,
      id: this.data.id,
      title: this.data.title,
      description: this.data.description,
      color: this.data.color || null
    }
  }

  @Prop({ required: true })
  readonly errors!: string[]

  @Prop({ required: true })
  readonly data!: Client

  @Emit('remove')
  onRemove (ev: any) {
    return { ...this.form }
  }

  @Emit('submit')
  onSubmit (ev: any) {
    return { ...this.form }
  }

  hasError (key: string) {
    return this.errors.indexOf(key) !== -1
  }
}
