


























import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Team, TeamFormData } from '@/types/era'

@Component
export default class TeamForm extends Vue {
  form: TeamFormData = {
    id: 0,
    title: ''
  }

  created () {
    this.form = {
      ...this.form,
      id: this.data.id,
      title: this.data.title
    }
  }

  @Prop({ required: true })
  readonly errors!: string[]

  @Prop({ required: true })
  readonly data!: Team

  @Emit('submit')
  onSubmit () {
    return this.form
  }

  hasError (key: string) {
    return this.errors.indexOf(key) !== -1
  }
}
