











































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import OnClickOutside from '@/components/base/OnClickOutside.vue'
import { SelectOption } from '@/types/era'

type FilterFunction = (search: string, options: SelectOption[]) => SelectOption[];
@Component({
  components: { OnClickOutside }
})
export default class FormDropdown extends Vue {
  isOpen: boolean = false

  search: string = ''

  highlightedIndex: number = -1

  @Prop({ default: false })
  readonly required!: boolean

  @Prop({ required: true })
  readonly placeholder!: string

  @Prop({ required: false, default: () => null })
  readonly value!: string | number | null

  @Prop({ required: true })
  readonly options!: SelectOption[]

  @Prop({
    type: Function,
    default: (
      (search, options) => options.filter(option => option.label.toLowerCase().startsWith(search.toLowerCase()))
    ) as FilterFunction
  })
  readonly filterFunction!: FilterFunction

  @Prop({ default: false })
  readonly error!: boolean

  @Ref('search')
  readonly searchRef!: HTMLInputElement

  @Ref()
  readonly button!: HTMLButtonElement

  @Ref('options')
  readonly optionsRef!: HTMLUListElement

  get filteredOptions () {
    return this.filterFunction(this.search, this.options)
  }

  get selectedLabel () {
    if (!this.value) {
      return null
    }

    return this.options[this.selectedIndex]?.label
  }

  get selectedIndex () {
    if (!this.value) {
      return -1
    }

    return this.options.findIndex(option => option.value === this.value)
  }

  get icon (): string {
    return this.isOpen ? 'ChevronUp' : 'ChevronDown'
  }

  @Watch('search')
  showMatches () {
    this.highlightedIndex = 0
    this.scrollToHighlighted()
  }

  toggle () {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    if (this.isOpen) {
      return
    }

    this.isOpen = true
    this.highlightedIndex = this.selectedIndex
    this.$nextTick(() => {
      this.searchRef?.focus()
      this.scrollToHighlighted()
    })
  }

  onClickOutside () {
    if (!this.isOpen) {
      return
    }

    this.isOpen = false
  }

  close () {
    if (!this.isOpen) {
      return
    }

    this.isOpen = false
    this.button.focus()
  }

  select (option: any) {
    this.$emit('input', option)
    this.search = ''
    this.close()
  }

  selectHighlighted () {
    this.select(this.filteredOptions[this.highlightedIndex]?.value)
  }

  scrollToHighlighted () {
    this.optionsRef.children[this.highlightedIndex + 1]?.scrollIntoView({
      block: 'nearest'
    })
  }

  highlight (index: number) {
    this.highlightedIndex = index

    if (this.highlightedIndex < -1) {
      this.highlightedIndex = this.filteredOptions.length - 1
    }

    if (this.highlightedIndex > this.filteredOptions.length - 1) {
      this.highlightedIndex = -1
    }

    this.scrollToHighlighted()
  }

  highlightPrev () {
    this.highlight(this.highlightedIndex - 1)
  }

  highlightNext () {
    this.highlight(this.highlightedIndex + 1)
  }

  optionClass (index: number) {
    return {
      [this.$style.option]: true,
      [this.$style.isActive]: index === this.highlightedIndex,
      [this.$style.placeholder]: index < 0
    }
  }
}
