/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getProjectSummary } from '@/services/api'
import { ProjectSummary, Project, TimeEntry } from '@/types/era'

@Module({ namespaced: true })
export default class ProjectModule extends VuexModule {
  project: ProjectSummary | null = null

  @Mutation
  SET_PROJECT (project?: Project) {
    this.project = project || null
  }

  @Mutation
  APPEND_PROJECT_ENTRIES (project: Project) {
    this.project!.entries = [
      ...this.project!.entries as TimeEntry[],
      ...project.entries as TimeEntry[]
    ]
  }

  @Action({ commit: 'SET_PROJECT' })
  load (id: number) {
    return getProjectSummary(id)
  }

  @Action({ commit: 'APPEND_PROJECT_ENTRIES' })
  loadMoreEntries (page: number) {
    return getProjectSummary(
      this.project!.id,
      page
    )
  }
}
