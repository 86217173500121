







































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ListingTableHeading extends Vue {
  @Prop({ default: '' })
  readonly title!: string
}
