/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import Vue from '@/providers/vue'

if (process.env.SENTRY_CLIENT_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_CLIENT_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true
      })
    ]
  })
}
