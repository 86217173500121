




























































import { Component, Vue } from 'vue-property-decorator'
import bus from '@/services/bus'

@Component
export default class NavigationHamburger extends Vue {
  visible: boolean = false

  mounted () {
    bus.$on('document-click', (ev: Event) => {
      this.visible = false
    })
  }

  onToggle () {
    this.visible = !this.visible
  }
}
