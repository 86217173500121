




















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class FormButton extends Vue {
  @Prop({ type: String, default: 'blue' })
  readonly color!: string

  @Prop({ type: String, default: 'button' })
  readonly type!: string

  @Prop({ type: String })
  readonly icon!: string

  @Prop({ type: String })
  readonly value!: string

  @Emit('click')
  onClick ($event: Event) {
    return $event
  }

  get style () {
    return {
      [this.$style.withtext]: !!this.$slots.default
    }
  }
}
