/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Projects',
  createButton: 'New Project',
  allProjects: 'All projects',
  success: 'Project was successfuly updated',
  newClient: 'New client'
}
