




















































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import LoginModal from '@/components/modals/LoginModal.vue'
import RegisterModal from '@/components/modals/RegisterModal.vue'

@Component({
  components: {
    LoginModal,
    RegisterModal
  }
})
export default class WelcomeTemplate extends Vue {
  @State('showRegister', { namespace: 'interface' })
  showRegister!: boolean

  @State('showLogin', { namespace: 'interface' })
  showLogin!: boolean

  @Action('toggleShowRegister', { namespace: 'interface' })
  toggleShowRegister!: (toggle: boolean) => void

  @Action('toggleShowLogin', { namespace: 'interface' })
  toggleShowLogin!: (toggle: boolean) => void

  onRegisterClose () {
    this.toggleShowRegister(false)
  }

  onLoginClose () {
    this.toggleShowLogin(false)
  }
}
