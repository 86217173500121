







































































import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import { TimeEntryFormData, TimeEntry, ProjectListItem } from '@/types/era'
import StopwatchButton from '@/components/layout/StopwatchButton.vue'

const defaultFormData: TimeEntryFormData = {
  id: 0,
  title: '',
  timeSpent: 0,
  spentAt: format(new Date(), 'LL-dd-yyyy'),
  projects: []
}

@Component({
  components: {
    StopwatchButton
  }
})
export default class EntryForm extends Vue {
  form: TimeEntryFormData = {
    ...defaultFormData
  }

  @State('projects', { namespace: 'interface' })
  projects!: ProjectListItem[]

  @Prop({ required: true })
  readonly errors!: string[]

  @Prop({ required: true })
  readonly data!: TimeEntry

  @Prop()
  readonly entryDate!: Date

  @Watch('data')
  onDataChange (newv: TimeEntry, oldv: TimeEntry) {
    this.updateFormValues({
      ...oldv,
      ...newv
    })
  }

  @Emit('remove')
  onRemove () {
    return { ...this.form }
  }

  @Emit('submit')
  onSubmit () {
    return { ...this.form }
  }

  @Emit('stopwatch')
  onStopwatch () {
    return { ...this.form }
  }

  onProjectSelect (value: number) {
    this.form.projects = value ? [value] : []
  }

  hasError (key: string) {
    return this.errors.indexOf(key) !== -1
  }

  created () {
    this.updateFormValues(this.data)
  }

  updateFormValues (values: TimeEntry) {
    const data = values || { projects: [] } // FIXME: Not needed anymore ?

    this.form = {
      ...defaultFormData,
      id: data.id,
      title: data.title,
      timeSpent: data.timeSpent,
      spentAt: format(new Date(data.spentAt), 'LL-dd-yyyy'),
      projects: data.projects.map(project => project.id) as number[]
    }
  }

  updateTitle ($title: string) {
    this.form = {
      ...defaultFormData,
      title: $title
    }
  }

  get projectChoices () {
    return this.projects.map((p: ProjectListItem) => ({
      label: p.clients && p.clients.length > 0 ? `${p.clients[0].title} / ${p.title}` : `${p.title}`,
      value: p.id
    }))
  }

  get projectId () {
    return this.form.projects[0]
  }

  get canUseStopwatch () {
    return this.data.stopwatch || isToday(new Date(this.data.spentAt))
  }
}
