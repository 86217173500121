

























































































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { Tags, ProjectChoices } from '@/types/era'
import { parseEntryInput } from '@/utils'
import { levenshteinEditDistance } from 'levenshtein-edit-distance'
import OnClickOutside from '@/components/base/OnClickOutside.vue'

@Component({
  components: { OnClickOutside }
})
export default class FormTextareaParser extends Vue {
  dateSuggest: string = ''
  timeSuggest: string = ''
  projectStripe: string | null = ''
  projectTitle: string | null = ''
  entryTitle: string | null = ''
  isOpen: boolean = false
  showProject: boolean = false
  showTags: boolean = false
  currentWord: string = ''

  @Prop({ type: String, default: '' })
  readonly value!: ''

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @State('tags', { namespace: 'interface' })
  tags!: Tags[]

  @Prop({ required: true })
  readonly options!: ProjectChoices[]

  @Prop()
  readonly maxlength!: number

  @State('dateFormat', { namespace: 'session' })
  dateFormat!: string

  @Action('loadTags', { namespace: 'interface' })
  loadTags!: (query?: string) => Promise<Tags[]>

  @Emit('select')
  onSelectTags (item: Tags) {
    const myField = this.$refs.textareaEntry as HTMLInputElement
    const startPos = myField!.selectionStart || 0
    const endPos = myField!.selectionEnd || 0
    let $value = null

    const current = this.value.substring(0, startPos).match(/\w+(?:[.,-_]\w+)*/g)?.pop()
    if (this.currentWord === '') {
      $value = this.value.substring(0, startPos) + `#${item.tag}` + this.value.substring(endPos, this.value.length)
    } else {
      const wordStart = startPos - current!.length
      const tag = this.value.substring(wordStart - 1, wordStart) === '#' ? `${item.tag}` : `#${item.tag}`
      $value = this.value.substring(0, wordStart) + `${tag}` + this.value.substring(wordStart + this.currentWord.length, this.value.length)
    }

    this.$emit('updateImporttext', $value)
    const wordEnd = this.currentWord === '' ? startPos : startPos - current!.length
    setTimeout(function () {
      myField!.focus()
      myField!.selectionEnd = wordEnd + item.tag.length + 1
    }, 500)
    this.$emit('updateImporttext', $value)
  }

  @Emit('select')
  onSelectProject (item: ProjectChoices) {
    const myField = this.$refs.textareaEntry as HTMLInputElement
    const text = myField.value
    const index = text.substr(0, myField!.selectionStart || 0).split('\n').length - 1
    const arr = text.split('\n')
    const parseStr = arr[index]
    const { originalCurrentString: currentString } = parseEntryInput(parseStr, this.dateFormat, this.options, index, arr)
    const lineHead = parseStr.replace(currentString, '')
    const entryTitle = currentString.split(' - ')[1] ? currentString.split(' - ')[1].trim() : ''

    arr[index] = `${lineHead} ${item.title} - ${entryTitle}`

    let count = 0
    for (let i = 0; i < index; i++) {
      count = count + arr[i].length + 1
    }

    const endPos = entryTitle !== '' ? count + arr[index].split(' - ')[0].length : count + arr[index].length
    const $value = arr.join('\n')
    this.$emit('updateImporttext', $value)
    setTimeout(function () {
      myField!.focus()
      myField!.selectionStart = endPos
      myField!.selectionEnd = endPos
    }, 500)
  }

  // shortcuts for now...
  clearCurrentWord ($event: KeyboardEvent) {
    if ($event.keyCode === 32) {
      this.currentWord = ''
    }

    if ($event.altKey && $event.shiftKey && $event.keyCode === 37) {
      console.log('left')
    }

    if ($event.altKey && $event.shiftKey && $event.keyCode === 39) {
      console.log('right')
    }

    if ($event.altKey && $event.shiftKey && $event.keyCode === 38) {
      console.log('up')
    }

    if ($event.altKey && $event.shiftKey && $event.keyCode === 40) {
      console.log('down')
    }

    if ($event.altKey && $event.shiftKey && $event.keyCode === 13) {
      console.log('select')
    }

    if (($event.ctrlKey || $event.metaKey) && $event.shiftKey && $event.keyCode === 83) {
      this.$emit('saveEntries')
    }

    if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 73) {
      this.replaceCurrentLine()
    }
  }

  getCurrentWord (content: string, cursorPos: number) {
    const n = content.substring(cursorPos).match(/^[a-zA-Z0-9-_]+/)
    const p = content.substring(0, cursorPos).match(/[a-zA-Z0-9-_]+$/)
    if (!p && !n) return ''
    const currentWord = `${p || ''}${n || ''}`.replace(/[^\w\s]/g, '')

    return currentWord
  }

  replaceCurrentLine () {
    const suggestion = this.$refs.suggestion as HTMLInputElement
    const myField = this.$refs.textareaEntry as HTMLInputElement
    const text = myField.value
    const index = text.substr(0, myField!.selectionStart || 0).split('\n').length - 1
    const arr = text.split('\n')

    if (~index) {
      arr[index] = suggestion.innerText
    }

    const $value = arr.join('\n')
    this.$emit('updateImporttext', $value)
  }

  setCurrentLine (text: string) {
    if (!this.isOpen) {
      this.toggle()
    }
    const myField = this.$refs.textareaEntry as HTMLInputElement
    const startPos = myField!.selectionStart || 0
    const index = text.substr(0, myField!.selectionStart || 0).split('\n').length - 1
    const arr = text.split('\n')
    const parseStr = arr[index]

    const {
      currentString,
      fullDate,
      time,
      project,
      projectName,
      entryTitle
    } = parseEntryInput(parseStr, this.dateFormat, this.options, index, arr)

    if (fullDate) {
      this.dateSuggest = fullDate
    }

    if (time) {
      this.timeSuggest = time
    }

    this.projectTitle = project?.title ? project.title : projectName
    this.projectStripe = project?.color ? project?.color : '#FF0000'
    this.entryTitle = entryTitle

    // count current cursor position
    let count = 0
    for (let i = 0; i < index; i++) {
      count = count + arr[i].length + 1
    }
    const cursorPosition = startPos - count
    const stringArr = parseStr.split(' - ')
    if (!(cursorPosition - 1 > stringArr[0].length)) {
      // if cursor is on the left side show project
      this.currentWord = currentString.split(' - ')[0].trim()
      this.showProject = true
      this.showTags = false
    } else {
      // if cursor is on the right side show tags
      const current = this.getCurrentWord(text, startPos)
      this.currentWord = `${current}`
      this.showProject = false
      this.showTags = true
    }
  }

  @Emit('keyup')
  onKeyUp ($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value
    this.clearCurrentWord($event)
    this.setCurrentLine(value)
    return $event
  }

  @Emit('input')
  onInput ($event: KeyboardEvent): string {
    const value = ($event.target as HTMLInputElement).value
    this.setCurrentLine(value)
    return value
  }

  onClick ($event: MouseEvent): string {
    const value = ($event.target as HTMLInputElement).value
    this.setCurrentLine(value)
    return value
  }

  tagsReplace (text: string): string {
    const reHash = /#[^\s#!?.,:;'"/\\]*/g
    let t = text.replace(/<[^>]*>?/gm, '')
    const tags = this.tags.map((item) => (item.tag))
    t = t.replace(reHash, hash => {
      return tags.includes(hash.replace(/[^\w\s]/g, '').replace('#', '').trim()) ? `<span class="current">${hash.trim()}</span>` : `<span class="unknown">${hash.trim()}</span>`
    })
    return t
  }

  projectReplace (text: string): string {
    const arr = text.split('\n').map((item, index) => {
      const { project, projectName, entryTitle } = parseEntryInput(item, this.dateFormat, this.options, index, text.split('\n'))
      if (project) {
        item = item.replace(projectName, ` <span style="border-bottom: 2px solid ${project.color}">${projectName.trim()}</span>`)
      } else if (projectName) {
        item = item.replace(projectName!, ` <span style="border-bottom: 2px solid #FF0000">${projectName!.trim()}</span>`)
      } else {
        item = item.replace('', ' <span></span>')
      }

      if (entryTitle) {
        item = item.replace(entryTitle, this.tagsReplace(entryTitle))
      }

      return item
    })
    return arr.join('\n')
  }

  async created () {
    this.loadTags()
  }

  compareStrings (s1:string, s2:string): number {
    const distance = levenshteinEditDistance(s1, s2)
    const maxLength = Math.max(s1.length, s2.length)
    const similarity = (maxLength - distance) / maxLength
    return similarity
  }

  projectStyle (item: ProjectChoices) {
    return {
      borderBottom: `2px solid ${item.color}`
    }
  }

  toggle () {
    this.isOpen = !this.isOpen
  }

  onClickOutside () {
    if (!this.isOpen) {
      return
    }

    this.isOpen = false
  }

  get tagsList () {
    const tags = this.tags.filter((item) => {
      return this.compareStrings(item.tag, this.currentWord) >= 0.4
    })
    return tags.length > 0 ? tags : this.tags
  }

  get projectList () {
    const projects = this.options.filter((item) => {
      return this.compareStrings(item.title, this.currentWord) >= 0.4
    })

    return projects.length > 0 ? projects : this.options
  }

  get description () {
    return this.projectReplace(this.value)
  }

  get stripeStyle () {
    return {
      borderBottom: `2px solid ${this.projectStripe}`
    }
  }

  get isOpenSuggest () {
    return this.isOpen && this.value !== ''
  }

  get isOpenProject () {
    return this.isOpenSuggest && this.showProject
  }

  get isOpenTags () {
    return this.isOpenSuggest && this.showTags
  }
}
