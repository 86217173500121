












































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { checkCollision } from '@/utils'

@Component
export default class Dropdown extends Vue {
  open: boolean = false

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: true })
  readonly autoblur!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ default: '' })
  readonly value!: any

  @Prop({ default: () => ({}) })
  readonly appendStyle!: object

  @Prop({ default: undefined })
  readonly forcedOpen!: boolean | undefined

  mounted () {
    document.body.addEventListener('click', this.onBodyClicked)
    document.body.addEventListener('touchend', this.onBodyClicked)
  }

  destroyed () {
    document.body.removeEventListener('click', this.onBodyClicked)
    document.body.removeEventListener('touchend', this.onBodyClicked)
  }

  arrowPressed () {
    const wasOpen = this.open === true
    this.open = true
    return !wasOpen
  }

  onBodyClicked (ev: Event) {
    if (this.isOpen) {
      if (!checkCollision(this.$el, ev)) {
        this.onNavigateEsc()
      }
    }
  }

  @Emit('blur')
  onBlur () {
    if (this.autoblur) {
      this.toggle(false)
    }
  }

  toggle (toggle: boolean) {
    this.open = toggle
    this.$emit(this.open ? 'open' : 'close')
  }

  @Emit('tab')
  onNavigateTab () {
  }

  @Emit('enter')
  onNavigateEnter (ev: Event) {
    ev.preventDefault()
    ev.stopPropagation()
    ev.stopImmediatePropagation()

    this.toggle(false)
  }

  @Emit('up')
  onNavigateUp () {
    return this.arrowPressed()
  }

  @Emit('down')
  onNavigateDown () {
    return this.arrowPressed()
  }

  @Emit('esc')
  onNavigateEsc () {
    this.toggle(false)
  }

  @Emit('input')
  onInput () {
  }

  onToggle (ev: Event) {
    if (checkCollision(this.$refs.drawer as Element, ev)) {
      return
    }

    if (typeof this.forcedOpen === 'boolean') {
      this.$emit('open')
    } else {
      this.toggle(!this.open)
    }
  }

  get isOpen (): boolean {
    if (typeof this.forcedOpen === 'boolean') {
      return this.forcedOpen
    }

    return this.open
  }

  get icon (): string {
    return this.isOpen ? 'ChevronUp' : 'ChevronDown'
  }
}
