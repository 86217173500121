/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { groupBy } from 'lodash-es'
import { Team, User, TeamUserProfile, TeamFormData, TimeEntry } from '@/types/era'
import {
  getTeamsOverview,
  getTeams,
  getUserTeams,
  getUserList,
  getTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  joinTeam,
  leaveTeam
} from '@/services/api'

const loadOverview = async (period: Date, list: Team[]) => {
  const teams = await getTeamsOverview(period)

  const mapUser = (user: TeamUserProfile) => ({
    ...user,
    timeSpent: ((user.entries || []) as any).reduce(
      (acc: number, value: TimeEntry) => acc + value.timeSpent,
      0
    ),
    entries: groupBy(user.entries, 'spentAt')
  })

  return list.map((team: Team) => {
    const teamData = teams.find((t: Team) => t.id === team.id)

    const users = team.users!.map((u: TeamUserProfile) => {
      const found = teamData!.users!.find((uu: TeamUserProfile) => uu.id === u.id)

      return mapUser({
        ...u,
        ...found || {}
      })
    })

    return {
      ...teamData,
      users
    }
  })
}

@Module({ namespaced: true })
export default class TeamsModule extends VuexModule {
  period: Date = new Date()
  team: Team | null = null
  teams: Team[] = []
  overview: Team[] = []
  userTeams: Team[] = []
  filter: number[] = []
  users: User[] = []

  @Mutation
  SET_OVERVIEW (teams: Team[]) {
    this.overview = teams
  }

  @Mutation
  SET_PERIOD (period: Date) {
    this.period = period
  }

  @Mutation
  SET_TEAM (team?: Team) {
    this.team = team || null
  }

  @Mutation
  SET_TEAMS (teams: Team[]) {
    this.teams = teams
  }

  @Mutation
  SET_USER_TEAMS (teams: Team[]) {
    this.userTeams = teams
  }

  @Mutation
  SET_FILTER (filter: number[]) {
    this.filter = filter
  }

  @Mutation
  SET_USERS (users: User[]) {
    this.users = users
  }

  @Action({ commit: 'SET_OVERVIEW' })
  async create (team: TeamFormData) {
    await createTeam(team)
    return loadOverview(this.period, this.teams)
  }

  @Action({ commit: 'SET_OVERVIEW' })
  async update (team: TeamFormData) {
    await updateTeam(team)
    return loadOverview(this.period, this.teams)
  }

  @Action({ commit: 'SET_OVERVIEW' })
  async remove (team: TeamFormData) {
    await deleteTeam(team)
    return loadOverview(this.period, this.teams)
  }

  @Action({ commit: 'SET_OVERVIEW' })
  async loadOverview () {
    return loadOverview(this.period, this.teams)
  }

  @Action({ commit: 'SET_TEAMS' })
  async loadAll () {
    return getTeams({
      includeUsers: true
    })
  }

  @Action({ commit: 'SET_TEAM' })
  load (id: number) {
    return getTeam(id)
  }

  @Action({})
  async join ({ teamId, userId }: any) { // FIXME: Type
    await joinTeam(teamId, userId)
  }

  @Action({})
  async leave ({ teamId, userId }: any) { // FIXME: Type
    await leaveTeam(teamId, userId)
  }

  @Action({ commit: 'SET_USER_TEAMS' })
  async loadUserTeams (id: number) {
    return getUserTeams(id)
  }

  @Action({ commit: 'SET_USERS' })
  async loadUsers () {
    return getUserList()
  }

  @Action({ commit: 'SET_TEAM' })
  createCurrent (): TeamFormData {
    return {
      id: 0,
      title: ''
    }
  }

  @Action({ commit: 'SET_TEAM' })
  clearCurrent () {
    return null
  }

  @Action({ commit: 'SET_PERIOD' })
  setPeriod (period: Date) {
    return period
  }

  @Action({ commit: 'SET_FILTER' })
  setFilter (ids: number[]) {
    return ids
  }
}
