












import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { MessageboxMessage } from '@/types/era'
import AuthenticationTemplate from '@/components/templates/AuthenticationTemplate.vue'

@Component({
  components: {
    AuthenticationTemplate
  }
})
export default class LogoutView extends Vue {
  @Action('logout', { namespace: 'auth' })
  logout!: () => Promise<void>

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  @Action('setLocale', { namespace: 'session' })
  setLocale!: (locale?: string) => Promise<void>

  async mounted () {
    await this.logout()
    await this.setLocale()

    this.addMessage({
      type: 'info',
      message: this.$t('common.interface.loggedOut') as string
    })

    this.$nextTick(() => {
      this.$router.push({ name: 'home' })
    })
  }
}
