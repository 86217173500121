/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Client',
  table: {
    heading: 'Projects',
    names: 'Project names'
  }
}
