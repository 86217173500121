















































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormToggle extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly label!: string

  @Prop({ type: Boolean, default: false })
  readonly value!: boolean
}
