





























import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { TimeEntry, TimeEntryFormData } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import EntryForm from '@/components/forms/EntryForm.vue'

@Component({
  components: {
    EntryForm
  }
})
export default class ProfileEntryModal extends Mixins(BaseModalForm) {
  @State('entry', { namespace: 'interface' })
  entry!: TimeEntry

  @Action('create', { namespace: 'profile' })
  createEntry!: (entry: TimeEntryFormData) => Promise<TimeEntry>

  @Action('update', { namespace: 'profile' })
  updateEntry!: (entry: TimeEntryFormData) => Promise<TimeEntry>

  @Action('remove', { namespace: 'profile' })
  removeEntry!: (entry: TimeEntryFormData) => Promise<void>

  @Prop({ required: true })
  readonly data!: TimeEntryFormData

  onModalRemove (entry: TimeEntryFormData) {
    return this.submitWithFn(() => this.removeEntry(entry))
  }

  onModalSubmit (entry: TimeEntryFormData) {
    this.clearErrors()

    const submit = entry.id! > 0
      ? this.updateEntry
      : this.createEntry

    return this.submitWithFn(() => submit(entry))
  }
}
