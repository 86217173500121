

















































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavigationLinks extends Vue {
  @Prop({ default: 'horizontal' })
  readonly orientation!: string

  isActive (name: string): boolean {
    const alternative = name.substr(0, name.length - 1)
    const current = this.$route.name as string

    return [name, alternative].indexOf(current) !== -1
  }
}
