


















































































































































































import { Component, Vue } from 'vue-property-decorator'
import WelcomeTemplate from '@/components/templates/WelcomeTemplate.vue'
import WelcomeBox from '@/components/layout/WelcomeBox.vue'
import timeSvg from '@/assets/images/time.svg'
import calendarSvg from '@/assets/images/calendar.svg'
import reportsSvg from '@/assets/images/reports.svg'
import teamSvg from '@/assets/images/team.svg'
import logoSvg from '@/assets/images/logo.svg'

@Component({
  components: {
    WelcomeTemplate,
    WelcomeBox
  }
})
export default class HomeView extends Vue {
  get timeImage () {
    return timeSvg
  }

  get calendarImage () {
    return calendarSvg
  }

  get reportsImage () {
    return reportsSvg
  }

  get teamsImage () {
    return teamSvg
  }

  get logoImage () {
    return logoSvg
  }
}
