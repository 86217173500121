




































































































































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import format from 'date-fns/format'
import { FirstDayOfWeek, TimeEntry, CalendarCardEvent, CalendarCreateClick } from '@/types/era'
import bus from '@/services/bus'
import Calendar from '@/components/layout/Calendar.vue'
import PeriodSelection from '@/components/layout/PeriodSelection.vue'
import HoursEntryModal from '@/components/modals/HoursEntryModal.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    Calendar,
    PeriodSelection,
    DefaultTemplate,
    HoursEntryModal
  }
})
export default class HoursView extends Vue {
  modalTarget: HTMLElement | null = null

  @State('firstDayOfWeek', { namespace: 'session' })
  firstDayOfWeek!: FirstDayOfWeek

  @State('entry', { namespace: 'interface' })
  entry!: TimeEntry

  @State('period', { namespace: 'hours' })
  period!: Date

  @State('entries', { namespace: 'hours' })
  entries!: TimeEntry[]

  @Action('setPeriod', { namespace: 'hours' })
  setPeriod!: (period: Date) => void

  @Action('loadAll', { namespace: 'stopwatches' })
  loadStopwatchEntries!: () => Promise<TimeEntry[]>

  @Action('loadAll', { namespace: 'hours' })
  loadEntries!: () => Promise<TimeEntry[]>

  @Action('loadEntry', { namespace: 'interface' })
  loadEntry!: (id: number) => Promise<TimeEntry>

  @Action('createCurrent', { namespace: 'interface' })
  createCurrent!: (data: any) => void

  @Action('clearCurrent', { namespace: 'interface' })
  clearCurrent!: () => void

  created () {
    this.updatePeriod()

    bus.$on('stopwatch-stopped', this.refreshEntries)
  }

  beforeDestroy () {
    bus.$off('stopwatch-stopped', this.refreshEntries)
  }

  async updatePeriod (period: Date = new Date()) {
    await this.setPeriod(period)
    await this.loadEntries()
  }

  async refreshEntries () {
    await this.loadEntries()
  }

  async openModal (ev: Event, entryId: number = -1, entryDate?: Date) {
    this.modalTarget = ev.target as HTMLElement

    if (entryId === -1) {
      await this.createCurrent({
        spentAt: entryDate
      })
    } else {
      await this.loadEntry(entryId)
    }
  }

  onCardCreate (click: CalendarCreateClick) {
    this.openModal(click.event, -1, click.date)
  }

  onCardEdit (click: CalendarCardEvent) {
    this.openModal(
      click.event,
      click.entry.id as number
    )
  }

  onModalClose () {
    this.modalTarget = null
    this.clearCurrent()
  }

  onModalSave () {
    this.onModalClose()
  }

  onPeriodSelection (period: Date) {
    this.updatePeriod(period)
  }

  onModalDone () {
    this.loadEntries()

    // FIXME: We can re-populate the stopwatch data instead
    this.loadStopwatchEntries()
  }

  onTimeLogged () {
    this.loadEntries()
  }

  get loggedNo () {
    return this.entries.length
  }

  get plannedNo () {
    return 0
  }

  get periodTitle () {
    const week = format(this.period, 'I')
    const month = format(this.period, 'LLLL')
    const str = this.$t('hours.week') as string

    return `${month} - ${str} ${week}`
  }
}
