/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  hero: 'Era gjør tids sporing så simpelt at du faktisk vil bruke det. Laoreet sit amet cursus sit amet dictum felis bibendum ut.'
}
