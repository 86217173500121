/**
 * @project Era
 * @author Marko Muric <marko.muric@copyleft.rs>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { regList } from './tools/parser/regLists'
import { regListInputs } from './tools/parser/regListInputs'
import { formatTime, parseDate } from './tools/parser/functions'
import { DateTimeResult } from './tools/parser/interfaces'
import { parse } from 'date-fns'

import format from 'date-fns/format'
import { ProjectChoices } from '@/types/era'

const findDate = (text:string, dateFormat:string, index?:number, strList?: string[]):Date => {
  if (index && strList) {
    const { date } = parseDateTime(strList[index - 1], dateFormat, index - 1, strList)
    return parse(date, `${dateFormat}`, new Date())
  }
  return new Date()
}

const findTime = (text:string, dateFormat:string, index?:number, strList?: string[]):number[] => {
  if (index && strList) {
    const { hours, minutes } = parseDateTime(strList[index - 1], dateFormat, index - 1, strList)
    return [hours, minutes]
  }
  return [0, 0]
}

export const parseDateTime = (text:string, dateFormat:string, index?:number, strList?: string[]):DateTimeResult => {
  const arr = text.split(' - ')
  const parseStr = arr[0]
  const defaultDate = new Date()
  const defaultHours = 0
  const defaultMinutes = 0

  const regex = regList.find((item) => (parseStr.match(item.reg)))
  // console.log('date string', regex)
  if (regex) {
    const dateMatch = parseStr.match(regex.reg)
    if (dateMatch) {
      // console.log(dateMatch)
      if (regex.fnc) {
        const fnc = regex.fnc
        const res = fnc(dateMatch, defaultDate)
        const { result } = res
        let { date, hours, minutes } = res
        // console.log(res)
        if (date === undefined) {
          date = findDate(text, dateFormat, index, strList)
        }

        if (hours === undefined || minutes === undefined) {
          const timeRes = findTime(text, dateFormat, index, strList)
          hours = timeRes[0]
          minutes = timeRes[1]
        }

        return {
          result,
          date: format(date, `${dateFormat}`),
          fullDate: format(date, 'LL-dd-yyyy'),
          time: formatTime(hours, minutes),
          hours: hours,
          minutes: minutes
        }
      }
    }
  }

  return {
    result: null,
    date: format(defaultDate, `${dateFormat}`),
    fullDate: format(defaultDate, 'LL-dd-yyyy'),
    time: formatTime(defaultHours, defaultMinutes),
    hours: defaultHours,
    minutes: defaultMinutes
  }
}

export const parseEntryInput = (parseStr:string, dateFormat:string, options:ProjectChoices[], index?:number, strList?: string[]): any => {
  const res = parseInput(parseStr, dateFormat, index, strList)
  let project
  if (res && res.projectName) {
    project = options.find((item) => {
      const projectName:string = res.projectName
      return item.title.toLowerCase().trim() === projectName.replace(/--/g, '').replace(/ditto/g, '').toLowerCase().trim()
    })
  }
  const originalCurrentString = res?.currentString
  const currentString = res?.currentString.trim() || ''
  return { project, ...res, currentString, originalCurrentString }
}

export const parseInput = (parseStr:string, dateFormat:string, index?:number, strList?: string[]): any => {
  const defaultDate = new Date()
  const regex = regListInputs.find((item) => (parseStr.match(item.reg)))
  if (regex) {
    const parsedInput = parseStr.match(regex.reg)
    // console.log(parsedInput)
    if (parsedInput) {
      if (regex.fnc) {
        // console.log(regex)
        // console.log(parsedInput)
        const fnc = regex.fnc
        const res = fnc(parsedInput, defaultDate, dateFormat, index, strList)
        return res
      }
    }
  }
}
