/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'My Hours',
  logged: 'Logged',
  planned: 'Planned',
  week: 'Week',
  noProject: 'no project'
}
