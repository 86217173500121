



























































import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator'
import { filterList } from '@/utils'

@Component
export default class FormAutocomplete extends Vue {
  value: string = ''
  currentIndex: number = -1

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: String, default: 'title' })
  readonly labelKey!: string

  @Prop({ type: Array, default: () => [] })
  readonly data!: any[]

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Watch('value')
  onValueChange (prev: string, next: string) {
    if (prev !== next) {
      this.currentIndex = -1
    }
  }

  @Emit('select')
  onSelectItem (item: any) {
    this.value = ''

    return item
  }

  onInput () {
  }

  onEnter () {
    if (this.results.length === 1) {
      this.currentIndex = 0
    }

    if (this.currentIndex !== -1) {
      this.$emit('select', this.results[this.currentIndex])
    }

    this.value = ''
  }

  onNavigate (dir: number) {
    if (this.hasResults) {
      this.currentIndex = dir > 0
        ? Math.min(this.currentIndex + 1, this.results.length - 1)
        : Math.max(this.currentIndex - 1, 0)
    } else {
      this.currentIndex = -1
    }
  }

  itemTitle (item: any) {
    return item[this.labelKey]
  }

  itemKey (item: any) {
    return `${item.id}-${this.itemTitle(item)}`
  }

  isActive (index: number) {
    return this.currentIndex === index
  }

  get open () {
    return this.value.length > 0
  }

  get results () {
    return filterList(this.data, this.labelKey, this.value)
  }

  get hasResults () {
    return this.results.length > 0
  }
}
