














































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class Message extends Vue {
  @Prop({ type: String, default: '' })
  readonly message!: string

  @Prop({ type: String, default: 'info' })
  readonly type!: string

  @Emit('click')
  onClick ($event: Event) {
    return $event
  }

  get styles () {
    return [this.$style.message, this.$style[this.type]]
  }
}
