





















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { hoursAndMinutesFromTimePadded, hoursAndMinutesFromTime } from '@/utils'

@Component
export default class FormTimeTextbox extends Vue {
  focused: boolean = false
  hadFormattedValue: [boolean, boolean] = [false, false]

  @Prop({ type: Number, default: 0 })
  readonly value!: number

  @Emit('focus')
  onFocus ($event: Event) {
    this.focused = true
    this.hadFormattedValue = [
      this.value >= 60,
      this.value >= 60
    ]
  }

  @Emit('blur')
  onBlur ($event: Event) {
    this.focused = false
  }

  @Emit('keydown')
  onKeyDown ($event: KeyboardEvent) {
    const input = $event.target as HTMLInputElement
    const value = input!.value
    const [, mins] = value.split(':')
    const allow = [9, 8, 13, 37, 39, 36, 35]
    const startsAt = value.indexOf(':')
    const currentlyAt = input.selectionStart || -1
    const allowed = allow.indexOf($event.keyCode) !== -1
    const maxedMinutes = (mins || '').length === 2
    const beforeHours = startsAt === -1 || currentlyAt <= startsAt

    if (maxedMinutes && !allowed && !beforeHours) {
      $event.preventDefault()
    }

    return $event
  }

  get virtualValue () {
    if (this.focused) {
      const [hasHours, hasMinutes] = this.hadFormattedValue
      if (hasHours) {
        const { hours, minutes } = hoursAndMinutesFromTime(this.value)
        return `${hours}:${hasMinutes ? minutes : ''}`
      }

      return this.value > 0 ? String(this.value) : ''
    }

    const { hours, minutes } = hoursAndMinutesFromTimePadded(this.value)
    return `${hours}:${minutes}`
  }

  set virtualValue (str: string) {
    const [hrs, mins] = str.indexOf(':') === -1
      ? ['', str]
      : [...str.split(':')]

    const total = hrs.length > 0 && mins.length > 2
      ? this.value
      : (Number(hrs) * 60) + Number(mins)

    this.$emit('input', total)
    this.hadFormattedValue = [!!hrs, !!mins]
  }
}
