














































import { Component, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { UserLoginFormData, Auth } from '@/types/era'
import ResetForm from '@/components/forms/ResetForm.vue'
import LoginForm from '@/components/forms/LoginForm.vue'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import ladySvg from '@/assets/images/lady.svg'

@Component({
  components: {
    ResetForm,
    LoginForm
  }
})
export default class LoginModal extends Mixins(BaseModalForm) {
  forgot: boolean = false

  @Action('login', { namespace: 'auth' })
  login!: (data: UserLoginFormData) => Promise<Auth>

  @Action('setAllLocales', { namespace: 'session' })
  setAllLocales!: (locales: any) => Promise<void>

  onForgotClick () {
    this.forgot = true
  }

  onResetSubmit () {
    // TODO
  }

  async onLoginSubmit (data: UserLoginFormData) {
    const result = await this.submitWithFn(() => this.login(data))
    if (result) {
      await this.setAllLocales(result.profile)

      this.onClose()
      this.$router.push({ name: 'hours' })
    }
  }

  get ladyImage () {
    return ladySvg
  }
}
