














































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { TimeEntry } from '@/types/era'
import Message from '@/components/layout/Message.vue'
import DateTime from '@/components/layout/DateTime.vue'
import { parseHashtags } from '@/utils'

@Component({
  components: {
    Message,
    DateTime
  }
})
export default class StopwatchMessage extends Vue {
  @Prop({ required: true })
  readonly stopwatch!: TimeEntry

  @Emit('remove')
  onRemove ($event: Event) {
    return $event
  }

  @Emit('click')
  onClick ($event: Event) {
    return $event
  }

  stopwatchTitle (stopwatch: TimeEntry) {
    const prefix = stopwatch.startedAt ? 'active' : 'inactive'
    const str = this.$t(`common.stopwatch.${prefix}`)
    const defaultTitle = this.$t('common.stopwatch.defaultTitle') as string

    return `${str}: ` + (parseHashtags(stopwatch.title) || defaultTitle)
  }

  get icon () {
    return this.active ? 'Pause' : 'Play'
  }

  get active () {
    return !!this.stopwatch.startedAt
  }
}
