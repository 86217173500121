













import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Form extends Vue {
  @Prop({ type: Boolean, default: false })
  autofocus!: Boolean

  mounted () {
    if (this.autofocus) {
      const el = this.$refs.form as HTMLFormElement
      const children = el.elements
      if (children.length > 0) {
        (children[0] as HTMLInputElement).focus()
      }
    }
  }

  @Emit('submit')
  onSubmit (ev: Event) {
    return ev
  }

  @Emit('input')
  handleChange (ev: Event) {
    return ev
  }
}
