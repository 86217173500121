/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  name: 'Name',
  title: 'Title',
  total: 'Total',
  time: 'Time',
  date: 'Date',
  today: 'Today',
  project: 'Project',
  description: 'Description',
  updatedAt: 'Last updated',
  filterByName: 'Filter by name...',
  totalTimeSpent: 'Total time logged',
  hoursAndMinutes: '{hours} hours and {minutes} minutes',
  hoursAndMinutesAbbr: '{hours} h {minutes} m',
  hours: '{hours}',
  minutes: ':{minutes}',
  hoursMinutes: '{hours}:{minutes}',
  register: 'Register',
  signin: 'Sign in',

  stopwatch: {
    created: 'Created',
    active: 'Active',
    inactive: 'Inactive',
    defaultTitle: 'My Stopwatch'
  },

  menu: {
    hours: 'Hours',
    projects: 'Projects',
    teams: 'Teams',
    reports: 'Reports',
    clients: 'Clients',
    profile: 'Profile',
    preferences: 'Preferences',
    toggleLogForm: 'Input form',
    toggleLogInput: 'Input text field',
    toggleLogBox: 'Toggle log box',
    logout: 'Sign out',
    administration: 'Administration'
  },

  search: {
    placeholder: 'Search clients, projects or teams...',
    searching: 'Searching...',
    noResults: 'No results found',
    noResultsFor: 'No results found for "{search}"',
    noOptions: 'No options'
  },

  timeInput: {
    placeholder: 'Enter time logs (DD-MM-YYYY HH:MM Project - Title)'
  },

  timeEntry: {
    submit: 'Log time'
  },

  interface: {
    timeLogged: 'Time logged successfully',
    loggedOut: 'You were logged out...'
  }
}
