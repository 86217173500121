







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { hoursAndMinutesFromTimePadded } from '@/utils'

@Component
export default class TimeFormatted extends Vue {
  @Prop()
  readonly time!: number

  @Prop({ default: false })
  readonly bold!: boolean

  @Prop({ default: true })
  readonly abbreviate!: boolean

  @Prop({ default: false })
  readonly mobileTranslate!: boolean

  get translation (): string {
    if (this.mobileTranslate) {
      if (this.formatHours !== '0' && this.formatMinutes !== '0' && this.formatMinutes !== '00') {
        return 'common.hoursMinutes'
      }

      if (this.formatHours === '0' && this.formatMinutes !== '0' && this.formatMinutes !== '00') {
        return 'common.minutes'
      }

      return 'common.hours'
    }
    return this.abbreviate
      ? 'common.hoursAndMinutesAbbr'
      : 'common.hoursAndMinutes'
  }

  get formatMinutes (): string {
    const hoursPad = 1
    let minutesPad = 2
    if (this.mobileTranslate) {
      minutesPad = 1
    }
    return hoursAndMinutesFromTimePadded(this.time, hoursPad, minutesPad).minutes
  }

  get formatHours (): string {
    return hoursAndMinutesFromTimePadded(this.time).hours
  }
}
