




























































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import NavigationDropdown from '@/components/layout/NavigationDropdown.vue'
import NavigationSearch from '@/components/layout/NavigationSearch.vue'
import NavigationLinks from '@/components/layout/NavigationLinks.vue'
import NavigationHamburger from '@/components/layout/NavigationHamburger.vue'
import NavigationUserLinks from '@/components/layout/NavigationUserLinks.vue'
import defaultLogo from '@/assets/images/logo-icon.svg'

@Component({
  components: {
    NavigationDropdown,
    NavigationSearch,
    NavigationLinks,
    NavigationHamburger,
    NavigationUserLinks
  }
})
export default class Navigation extends Vue {
  @Getter('avatarSource', { namespace: 'auth' })
  avatar!: string

  @Getter('isLoggedIn', { namespace: 'auth' })
  isLoggedIn!: boolean

  @Action('toggleShowLogin', { namespace: 'interface' })
  toggleShowLogin!: (toggle: boolean) => void

  @Action('toggleShowRegister', { namespace: 'interface' })
  toggleShowRegister!: (toggle: boolean) => void

  onDropdownToggle (toggle: string) {
    return toggle
  }

  onLoginClick () {
    this.toggleShowLogin(true)
  }

  onRegisterClick () {
    this.toggleShowRegister(true)
  }

  get logo () {
    return defaultLogo
  }
}
