/**
 * @client Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getClient, getClients, createClient, updateClient, deleteClient } from '@/services/api'
import { filterList, randomColor } from '@/utils'
import { Client, ClientFormData } from '@/types/era'

@Module({ namespaced: true })
export default class ClientsModule extends VuexModule {
  filter: string = ''
  client: Client | null = null
  clients: Client[] = []

  @Mutation
  SET_CLIENTS (clients: Client[]) {
    this.clients = clients
  }

  @Mutation
  SET_CLIENT (client?: Client) {
    this.client = client || null
  }

  @Mutation
  SET_FILTER (filter: string) {
    this.filter = filter
  }

  @Action({ commit: 'SET_CLIENTS' })
  loadAll () {
    return getClients()
  }

  @Action({ commit: 'SET_CLIENT' })
  load (id: number) {
    return getClient(id)
  }

  @Action({})
  async createAndReturn (client: ClientFormData) {
    return createClient(client)
  }

  @Action({ commit: 'SET_CLIENTS' })
  async create (client: ClientFormData) {
    await createClient(client)
    return getClients()
  }

  @Action({ commit: 'SET_CLIENTS' })
  async update (client: ClientFormData) {
    await updateClient(client)
    return getClients()
  }

  @Action({ commit: 'SET_CLIENTS' })
  async remove (client: ClientFormData) {
    await deleteClient(client)
    return getClients()
  }

  @Action({ commit: 'SET_CLIENT' })
  createCurrent (): ClientFormData {
    return {
      id: 0,
      title: '',
      description: '',
      color: randomColor()
    }
  }

  @Action({ commit: 'SET_CLIENT' })
  clearCurrent () {
    return null
  }

  @Action({ commit: 'SET_FILTER' })
  setFilter (filter: string) {
    return filter
  }

  public get filtered (): Client[] {
    return filterList(this.clients, 'title', this.filter)
  }
}
