/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @author Morten Mehus <morten.mehus@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import format from 'date-fns/format'
import { fetch, createApiUrl, ApiAbortError } from './fetch'
import { isNativeData } from '@/utils/fetch'
import { periodFromDate } from '@/utils'
import store from '@/providers/store'
import {
  TimeEntry,
  Team,
  Project,
  Auth,
  ProjectListItem,
  ClientListItem,
  Client,
  User,
  UserProfile,
  SearchResult,
  UserInviteFormData,
  TimeEntryFormData,
  AutosaveQuery,
  AutosaveData,
  TeamFormData,
  UserPasswordFormData,
  UserNotification,
  ClientFormData,
  ProjectFormData,
  PeriodType,
  UserLoginFormData,
  TeamQueryOptions,
  ProjectSummary,
  UserRegisterFormData,
  ClientQueryOptions,
  Tags
} from '@/types/era'

const cancellable: string[] = [
  // TODO: Exclusive list
  // TODO: Wildcard
  // TODO: Based on context ?
  /*
  'search',
  'entries',
  'projects/list',
  'clients/list',
  'teams/list'
  */
]

/**
 * Checks if the given fetch path is cancellable
 */
const isCancellable = (method: string, path: string) =>
  method !== 'GET' ||
    cancellable.length === 0 ||
    cancellable.indexOf(path) !== -1

/**
 * Creates a ranged object
 */
const withTimeSpan = (period: Date, periodType: PeriodType) => {
  const { start, end } = periodFromDate(period, periodType)
  return {
    start: format(start, 'yyyy-LL-dd'),
    end: format(end, 'yyyy-LL-dd')
  }
}

/**
 * Just formats input
 */
const withCustomTimeSpan = (start?: Date, end?: Date) => start && end
  ? {
      start: format(start, 'yyyy-LL-dd'),
      end: format(end, 'yyyy-LL-dd')
    }
  : {}

/**
 * Hooks API requests into vuex store actions used for interface indicators etc.
 */
async function fetchWithStore<T> (
  method: string,
  path: string,
  payload?: any,
  type?: string
): Promise<T> {
  const key = `${method} ${path}`
  const json = !isNativeData(payload)
  const cancelContext = isCancellable(method, path)
    ? key
    : undefined

  try {
    store.dispatch('interface/pushLoading')

    const result = await fetch(method, path, payload, {
      Authorization: `JWT ${store.getters['auth/token']}`
    }, {
      cancelContext,
      json
    }, type)

    return result
  } catch (e) {
    if (e instanceof Error) {
      if (!(e instanceof ApiAbortError)) {
        store.dispatch('session/addMessage', {
          type: 'error',
          message: e.toString()
        })
      }
    }
    throw e
  } finally {
    store.dispatch('interface/popLoading')
  }
}

/**
 * Perform login
 */
export const login = (form: UserLoginFormData) =>
  fetchWithStore<Auth>('post', 'auth/login', form)

/**
 * Perform registration
 */
export const register = (form: UserRegisterFormData) =>
  fetchWithStore<Auth>('post', 'auth/register', form)

/**
 * Gets time entries (for period)
 * FIXME: Should we always use the one below instead?
 */
export const getEntries = (period: Date, periodType: PeriodType = 'week') =>
  fetchWithStore<TimeEntry[]>(
    'get',
    'entries',
    withTimeSpan(period, periodType)
  )

/**
 * Gets time entries (for period) for given user
 */
export const getUserEntries = (userId: number, period: Date, periodType: PeriodType = 'week') =>
  fetchWithStore<TimeEntry[]>(
    'get',
    `users/${userId}/entries`,
    withTimeSpan(period, periodType)
  )

/**
 * Gets given time entry
 */
export const getEntry = (id: number) =>
  fetchWithStore<TimeEntry>('get', `entries/${id}`)

/**
 * Creates a new time entry
 */
export const createEntry = (entry: TimeEntryFormData) =>
  fetchWithStore<TimeEntry>('post', 'entries', entry)

/**
 * Creates a new autosave entry
 */
export const createAutosave = (item: AutosaveData) =>
  fetchWithStore<AutosaveData>('put', 'autosave', item)

/**
 * Updates a autosave entry
 */
export const updateAutosave = (item: AutosaveData) =>
  fetchWithStore<AutosaveData>('put', `autosave/${item.id}`, item)

/**
 * Gets a list of drafts models
 */
export const getDraftList = (query: AutosaveQuery) =>
  fetchWithStore<AutosaveData[]>('post', 'autosave', query)

/**
 * Updates a time entry
 */
export const updateEntry = (entry: TimeEntryFormData) =>
  fetchWithStore<TimeEntry>('put', `entries/${entry.id}`, entry)

/**
 * Deletes a time entry
 */
export const deleteEntry = (entry: TimeEntryFormData) =>
  fetchWithStore<undefined>('delete', `entries/${entry.id}`)

/**
 * Gets a list of projects
 */
export const getProjects = () =>
  fetchWithStore<Project[]>('get', 'projects')

/**
 * Gets a list of short project models
 */
export const getProjectList = () =>
  fetchWithStore<ProjectListItem[]>('get', 'projects/list')

/**
 * Gets given project
 */
export const getProject = (id: number) =>
  fetchWithStore<Project>('get', `projects/${id}`)

/**
 * Gets given project, but with extended information
 */
export const getProjectSummary = (id: number, page: number = 1) =>
  fetchWithStore<ProjectSummary>('get', `projects/${id}/summary`, {
    page
  })

/**
 * Creates a project
 */
export const createProject = (project: ProjectFormData) =>
  fetchWithStore<Project>('post', 'projects', project)

/**
 * Updates a project
 */
export const updateProject = (project: ProjectFormData) =>
  fetchWithStore<Project>('put', `projects/${project.id}`, project)

/**
 * Deletes a project
 */
export const deleteProject = (project: ProjectFormData) =>
  fetchWithStore<undefined>('delete', `projects/${project.id}`)

/**
 * Gets a list of clients
 */
export const getClients = (options?: Partial<ClientQueryOptions>) =>
  fetchWithStore<Client[]>('get', 'clients', options)

/**
 * Gets a list of short client models
 */
export const getClientList = () =>
  fetchWithStore<ClientListItem[]>('get', 'clients/list')

/**
 * Gets given client
 */
export const getClient = (id: number) =>
  fetchWithStore<Client>('get', `clients/${id}`)

/**
 * Creates a new client
 */
export const createClient = (client: ClientFormData) =>
  fetchWithStore<Client>('post', 'clients', client)

/**
 * Updates a client
 */
export const updateClient = (client: ClientFormData) =>
  fetchWithStore<Client>('put', `clients/${client.id}`, client)

/**
 * Deletes a client
 */
export const deleteClient = (client: ClientFormData) =>
  fetchWithStore<undefined>('delete', `clients/${client.id}`)

/**
 * Updates a user profile
 */
export const updateUserProfile = (id: number, data: Partial<User>) =>
  fetchWithStore<User>('put', `users/${id}/profile`, data)

/**
 * Updates a user profile
 */
export const updateUserNotifications = (id: number, data: UserNotification[]) =>
  fetchWithStore<User>('put', `users/${id}/notifications`, data)

/**
 * Updates user password
 */
export const updateUserPassword = (id: number, data: UserPasswordFormData) =>
  fetchWithStore<User>('put', `users/${id}/password`, data)

/**
 * Updates user password
 */
export const updateUserAvatar = (id: number, file: File) => {
  const formData = new FormData()
  formData.append('avatar', file)

  return fetchWithStore<boolean>('put', `users/${id}/avatar`, formData)
}

/**
 * Gets a user avatar
 */
export const createUserAvatarUrl = (id: number): string =>
  createApiUrl(`users/${id}/avatar`)

/**
 * Gets given user
 */
export const getUser = (id: number) =>
  fetchWithStore<User>('get', `users/${id}`)

/**
 * Gets given user with extended information
 */
export const getUserProfile = (id: number, start?: Date, end?: Date) =>
  fetchWithStore<UserProfile>(
    'get',
    `users/${id}/profile`,
    withCustomTimeSpan(start, end)
  )

/**
 * Gets a list of user teams
 */
export const getUserTeams = (id: number) =>
  fetchWithStore<Team[]>('get', `users/${id}/teams`)

/**
 * Gets a list of user teams
 */
export const getUserList = () =>
  fetchWithStore<User[]>('get', 'users')

/**
 * Gets a list of teams
 */
export const getTeams = (options?: Partial<TeamQueryOptions>) =>
  fetchWithStore<Team[]>('get', 'teams', options)

/**
 * Gets a list of teams with overview
 */
export const getTeamsOverview = (period: Date) =>
  fetchWithStore<Team[]>(
    'get',
    'teams/overview',
    withTimeSpan(period, 'week')
  )

/**
 * Gets a team
 */
export const getTeam = (id: number) =>
  fetchWithStore<Team>('get', `teams/${id}`)

/**
 * Creates a new team
 */
export const createTeam = (team: TeamFormData) =>
  fetchWithStore<Team>('post', 'teams', team)

/**
 * Updates a team
 */
export const updateTeam = (team: TeamFormData) =>
  fetchWithStore<Team>('put', `teams/${team.id}`, team)

/**
 * Deletes a team
 */
export const deleteTeam = (team: TeamFormData) =>
  fetchWithStore<undefined>('delete', `teams/${team.id}`)

/**
 * Make user join a team
 */
export const joinTeam = (teamId: number, userId?: number) =>
  fetchWithStore<boolean>('post', `teams/${teamId}/join`, {
    id: userId
  })

/**
 * Make a user leave a team
 */
export const leaveTeam = (teamId: number, userId?: number) =>
  fetchWithStore<boolean>('post', `teams/${teamId}/leave`, {
    id: userId
  })

/**
 * Searches for something
 */
export const searchAll = (query: string) =>
  fetchWithStore<SearchResult[]>('get', 'search', { query })

/**
 * Creates a new report
 */
export const createReport = (options: any, exportAs?: string) => exportAs
  ? fetchWithStore<Blob>('get', `reports/create/${exportAs}`, options, 'blob')
  : fetchWithStore<User[]>('get', 'reports/create', options)

/**
 * Get all time entries which are enabled as a stopwatch
 */
export const getStopwatches = () =>
  fetchWithStore<TimeEntry[]>('get', 'stopwatches')

/**
 * Create a new stopwatch
 */
export const createStopwatch = (entry: TimeEntryFormData) =>
  fetchWithStore<TimeEntry>('post', 'stopwatches', entry)

/**
 * Toggle the state of a stopwatch
 */
export const toggleStopwatch = (entryId: number) =>
  fetchWithStore<TimeEntry>('put', `stopwatches/${entryId}`)

/**
 * Stops and removes stopwatch
 */
export const removeStopwatch = (entryId: number) =>
  fetchWithStore<boolean>('delete', `stopwatches/${entryId}`)

/**
 * Invites given user
 */
export const inviteUser = (form: UserInviteFormData) =>
  fetchWithStore<boolean>('post', 'admin/invite', form)

/**
 * Gets invited users list
 */
export const listInvitedUsers = () =>
  fetchWithStore<boolean>('get', 'admin/invites')

export const getTags = (query?: string) =>
  fetchWithStore<Tags[]>('post', 'tags/search', { query })
