














































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Datepicker from 'vuejs-datepicker'
import subWeeks from 'date-fns/subWeeks'
import addWeeks from 'date-fns/addWeeks'

@Component({
  components: {
    Datepicker
  }
})
export default class PeriodSelection extends Vue {
  @Prop({ type: Date, required: true })
  readonly period!: Date

  onCalendarClick (ev: Event) {
    const el = (this.$refs.calendar as Vue).$el
    const input = el.querySelector('input[type=text]') as HTMLInputElement

    if (input) {
      input.click()
    }
  }

  @Emit('select')
  onSelect (date: Date) {
    return date
  }

  @Emit('select')
  onStepToday () {
    return new Date()
  }

  @Emit('select')
  onStepLeft () {
    return subWeeks(this.period, 1)
  }

  @Emit('select')
  onStepRight () {
    return addWeeks(this.period, 1)
  }
}
