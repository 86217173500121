










































import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { User, UserInviteFormData, MessageboxMessage } from '@/types/era'
import Heading from '@/components/layout/Heading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    DefaultTemplate,
    Heading
  }
})
export default class AdminView extends Vue {
  inviteEmail: string = ''

  @State('invitedUsers', { namespace: 'admin' })
  invitedUsers!: User[]

  @Action('inviteUser', { namespace: 'admin' })
  inviteUser!: (form: UserInviteFormData) => Promise<boolean>

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  @Action('loadInvitedUsers', { namespace: 'admin' })
  loadInvitedUsers!: () => Promise<User[]>

  mounted () {
    this.loadInvitedUsers()
  }

  async onInviteSubmit () {
    if (this.inviteEmail) {
      const result = await this.inviteUser({
        email: this.inviteEmail
      })

      if (result) {
        this.addMessage({
          type: 'info',
          message: 'User invited' // FIXME: locale
        })
      } else {
        this.addMessage({
          type: 'warn',
          message: 'User was not invited' // FIXME: locale
        })
      }

      this.inviteEmail = ''

      this.loadInvitedUsers()
    }
  }
}
