



















































































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import eachDay from 'date-fns/eachDayOfInterval'
import isToday from 'date-fns/isToday'
import isSameDay from 'date-fns/isSameDay'
import isWeekend from 'date-fns/isWeekend'
import format from 'date-fns/format'
import CalendarCard from '@/components/layout/CalendarCard.vue'
import EntryCalendarCard from '@/components/layout/EntryCalendarCard.vue'
import { periodFromDate } from '@/utils'
import { TimeEntry, FirstDayOfWeek, CalendarCreateClick, CalendarCardEvent } from '@/types/era'

@Component({
  components: {
    CalendarCard,
    EntryCalendarCard
  }
})
export default class Calendar extends Vue {
  @Prop({ required: true })
  readonly entries!: TimeEntry[]

  @Prop({ required: true })
  readonly period!: Date

  @Prop({ required: true })
  readonly firstDayOfWeek!: FirstDayOfWeek

  format (date: Date, fmt: string): string {
    return format(date, fmt)
  }

  isToday (day: Date): boolean {
    return isToday(day)
  }

  get days (): Date[] {
    const { start, end } = periodFromDate(this.period, 'week', this.firstDayOfWeek)
    const days = eachDay({ start, end })
    return days
  }

  entriesByDay (day: Date): TimeEntry[] {
    return this.entries.filter((e: TimeEntry) => {
      const eday: Date = new Date(e.spentAt) // FIXME
      return isSameDay(day, eday)
    })
  }

  formatProjects (entry: TimeEntry) {
    return entry.projects
      .map(e => e.title)
      .join(' / ')
  }

  isWeekend (day: Date) {
    return isWeekend(day)
  }

  @Emit('create')
  onPlusClick (event: Event, date: Date): CalendarCreateClick {
    return { event, date }
  }

  @Emit('edit')
  onCardClick (click: CalendarCardEvent): CalendarCardEvent {
    return click
  }
}
