





















import { Component, Prop, Vue } from 'vue-property-decorator'

import { hoursAndMinutesFromTimePadded } from '@/utils'

@Component
export default class TotalTime extends Vue {
  @Prop()
  readonly time!: number

  @Prop({ default: false })
  readonly bold!: boolean

  get formatMinutes (): string {
    return hoursAndMinutesFromTimePadded(this.time).minutes
  }

  get formatHours (): string {
    return hoursAndMinutesFromTimePadded(this.time).hours
  }
}
