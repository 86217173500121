import { ProjectChoices } from '@/types/era'
import { parseInput } from '@/utils/parsers'
const regex = /(ditto|--)/

const getEntryTitle = (parseStr: string, index?: number, arr?: string[], isDitto:boolean = false):{entryTitle: string|undefined} => {
  const match = parseStr && parseStr.match(regex)
  if (match || isDitto) {
    if (index && arr) {
      const entryTxt = arr[index - 1].split(' - ')[1]
      let isDittoEntry = isDitto && entryTxt === undefined
      if (entryTxt) {
        isDittoEntry = new RegExp(regex).test(entryTxt)
      }
      const { entryTitle } = getEntryTitle(entryTxt, index - 1, arr, isDittoEntry)
      return { entryTitle }
    }
    return { entryTitle: undefined }
  } else {
    const entryTitle = parseStr && parseStr.trim()
    return { entryTitle }
  }
}

const getProjectName = (parseStr: string, dateFormat:string, index?: number, arr?: string[], isDitto:boolean = false):{projectName: string|undefined} => {
  const match = parseStr.match(regex)
  if (match || isDitto) {
    if (index && arr) {
      const { projectName } = parseInput(arr[index - 1], dateFormat, index - 1, arr)
      return { projectName }
    }
    return { projectName: undefined }
  } else {
    const projectName = parseStr && parseStr.trim()
    return { projectName }
  }
}

export const findInputDetails = (parseStr: string, dateFormat:string, index?: number, arr?: string[], isDittoProject:boolean = false, isDittoTitle:boolean = false) => {
  const res = parseStr.split(' - ')
  if (res) {
    const { entryTitle } = getEntryTitle(res[1], index, arr, isDittoTitle)
    const { projectName } = getProjectName(res[0], dateFormat, index, arr, isDittoProject)
    return {
      projectName,
      entryTitle
    }
  }

  return {
    projectName: undefined,
    entryTitle: undefined
  }
}

// 1.30 Era - test 123
// yd -- Portals - --
// yd --
// yd --
