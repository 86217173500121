/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export interface FetcherAction {
  url: URL
  request: () => Promise<Response>
  controller: AbortController
  signal: AbortSignal
}

export interface FetcherOptions {
  baseUri: string
  method: string
  path: string
  payload?: any
  headers: Headers
}

/**
 * Checks if input is native data
 */
export const isNativeData = (input: any) => [FormData, File, Blob, ArrayBuffer]
  .some(i => input instanceof i)

/**
 * Splits query parameters into an array of key-value tuples
 */
const splitQueryParams = (key: string, value: any) => value instanceof Array
  ? value.map(val => [`${key}[]`, val])
  : [[key, value]]

/**
 * Creates server compatible URL query parameters
 */
const createQueryParams = (payload: any) => Object
  .keys(payload)
  .flatMap(key => splitQueryParams(key, payload[key]))

/**
 * Creates the fetch body from payload
 */
const createBody = (payload: any) => {
  if (payload) {
    if (!isNativeData(payload)) {
      return JSON.stringify(payload)
    }
  }

  return payload
}

/**
 * Creates basic fetch arguments
 */
const createRequestArguments = (method: string, baseUri: string, path: string, payload?: any) => {
  const isGet = method.toUpperCase() === 'GET'
  const body = !isGet ? createBody(payload) : undefined
  const url = new URL(baseUri + path)

  if (isGet && payload) {
    const params = createQueryParams(payload)

    params.forEach(([key, value]) => url.searchParams.append(
      key,
      value
    ))
  }

  return { url, body }
}

/**
 * A wrapper around the browser default fetch() functionality
 * to make life a bit eaiser
 */
export default (fetchOptions: FetcherOptions): FetcherAction => {
  const controller = new AbortController()
  const { baseUri, method, path, payload, headers } = fetchOptions
  const { signal } = controller
  const { url, body } = createRequestArguments(method, baseUri, path, payload)

  const request = (options: Partial<RequestInit> = {}) => window.fetch(
    url.toString(),
    {
      signal,
      body,
      method,
      headers,
      ...options
    }
  )

  return { url, request, controller, signal }
}
