
























































































































































import { Icon } from '@iconify/vue2'
import OnClickOutside from '@/components/base/OnClickOutside.vue'
import {
  Component,
  Vue
} from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { AutosaveData } from '@/types/era'
import format from 'date-fns/format'
import bus from '@/services/bus'

@Component({
  components: {
    Icon,
    OnClickOutside
  }
})
export default class DraftFloatList extends Vue {
  isOpen: boolean = false
  status: string = 'draft'
  page: number = 1
  limit: number = 20

  @State('drafts', { namespace: 'interface' })
  drafts!: AutosaveData[]

  @State('dateFormat', { namespace: 'session' })
  dateFormat!: string

  @Action('loadDrafts', { namespace: 'interface' })
  loadDrafts!: (page: number, limit: number, status: string) => Promise<AutosaveData[]>

  @Action('updateAutosave', { namespace: 'interface' })
  updateAutosave!: (form: AutosaveData) => Promise<AutosaveData>

  @Action('setImporttext', { namespace: 'interface' })
  setImporttext!: (importtext: string) => Promise<string>

  @Action('setImporttextId', { namespace: 'interface' })
  setImporttextId!: (importtextid: number|null) => Promise<number|null>

  @Action('setDraftPage', { namespace: 'interface' })
  setDraftPage!: (draftPage: number) => Promise<number>

  openDraft ($e: Event, item: AutosaveData) {
    $e.stopPropagation()
    $e.preventDefault()
    this.setImporttextId(item.id!)
    this.setImporttext(item.entries!)
    this.toggle()
  }

  async discardClick ($e: Event, item: AutosaveData) {
    $e.stopPropagation()
    $e.preventDefault()

    try {
      await this.updateAutosave({ id: item.id, status: 'discard' })
      bus.$emit('draft-updated')
    } catch {}
  }

  async previouse ($e: Event) {
    $e.stopPropagation()
    $e.preventDefault()
    if (this.page - 1 > 0) {
      this.page--
      this.loadDrafts(this.page, this.limit, this.status)
    }
  }

  async next ($e: Event) {
    $e.stopPropagation()
    $e.preventDefault()
    if (this.drafts.length >= this.limit) {
      this.page++
      this.loadDrafts(this.page, this.limit, this.status)
    }
  }

  onClickOutside () {
    if (!this.isOpen) {
      return
    }

    this.isOpen = false
  }

  toggle () {
    this.isOpen = !this.isOpen
  }

  getDate (date: Date | undefined) {
    const newDate = new Date(date!) || new Date()
    return format(newDate, this.dateFormat)
  }
}
