
































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import { UserAuth, User, UserPasswordFormData, UserNotification, MessageboxMessage, SelectOption } from '@/types/era'
import configuration from '@/config'
import Heading from '@/components/layout/Heading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

let timeout: any

@Component({
  components: {
    DefaultTemplate,
    Heading
  }
})
export default class PreferencesView extends Vue {
  @State('profile', { namespace: 'auth' })
  authProfile!: UserAuth

  @State('profile', { namespace: 'preferences' })
  userProfile!: User

  @Action('bumpAvatarHash', { namespace: 'auth' })
  bumpAvatarHash!: () => void

  @Getter('avatarSource', { namespace: 'auth' })
  avatar!: string

  @Action('load', { namespace: 'preferences' })
  loadProfile!: (id: number) => Promise<User>

  @Action('updateProfile', { namespace: 'preferences' })
  updateProfile!: (data: any) => Promise<User>

  @Action('updatePassword', { namespace: 'preferences' })
  updatePassword!: (data: UserPasswordFormData) => Promise<void>

  @Action('updateAvatar', { namespace: 'preferences' })
  updateAvatar!: (file: File) => Promise<void>

  @Action('updateNotifications', { namespace: 'preferences' })
  updateNotifications!: (data: UserNotification[]) => Promise<void>

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  @Action('setAllLocales', { namespace: 'session' })
  setAllLocales!: (locales: any) => Promise<void>

  data: Partial<User> = {
    username: '',
    phoneNumber: '',
    fullName: '',
    language: 'en',
    dateFormat: configuration.dateFormat,
    timeFormat: configuration.timeFormat,
    firstDayOfWeek: configuration.firstDayOfWeek
  }

  password: UserPasswordFormData = {
    current: '',
    create: '',
    confirm: ''
  }

  notifications: UserNotification[] = []

  async created () {
    const profile = await this.loadProfile(this.authProfile.id)

    if (profile.notifications!.length > 0) {
      this.notifications = profile.notifications || []
    } else {
      this.notifications = this.allNotifications
        .map(([t]) => t as UserNotification)
    }

    this.data = {
      ...this.data,
      ...profile
    }
  }

  hasNotificationOption (str: UserNotification) {
    return this.notifications.indexOf(str) !== -1
  }

  submitProfile () {
    clearTimeout(timeout)
    timeout = setTimeout(async () => {
      const profile = await this.updateProfile(this.data)

      this.setAllLocales(profile)

      this.addMessage({
        type: 'info',
        message: 'Preferences updated' // FIXME: locale
      })
    }, 1000)
  }

  onFieldUpdate (name: string, value: string) {
    this.$set(this.data, name, value)
    this.submitProfile()
  }

  onPasswordSubmit () {
    if (this.password.create === this.password.confirm) {
      this.updatePassword(this.password)
    }
  }

  onProfileSubmit () {
    this.submitProfile()
  }

  onNotifyChange (str: UserNotification) {
    const index = this.notifications.indexOf(str)

    if (index === -1) {
      this.notifications.push(str)
    } else {
      this.notifications.splice(index, 1)
    }

    this.updateNotifications(this.notifications)
  }

  async onAvatarChange (file: File) {
    await this.updateAvatar(file)
    await this.bumpAvatarHash()
  }

  get model (): User {
    return {
      ...this.userProfile || {},
      ...this.data
    }
  }

  get languages (): SelectOption[] {
    return Object.keys(configuration.languages)
      .map(value => ({ value, label: configuration.languages[value] }))
  }

  get firstDaysOfWeeks (): object[] {
    return [{
      value: 0,
      label: 'Sunday'
    }, {
      value: 1,
      label: 'Monday'
    }]
  }

  get allNotifications (): [string, string][] {
    return [
      ['additions', 'notifyAdditions'],
      ['timeAdditions', 'notifyTimeAdditions'],
      ['noTime', 'notifyNoTime'],
      ['summary', 'notifySummary']
    ]
  }

  get allDateFormats (): object[] {
    return [{
      label: 'MM.DD.YY',
      value: 'LL.dd.yy'
    }, {
      label: 'DD.MM.YY',
      value: 'dd.LL.yy'
    }]
  }

  get allTimeFormats (): object[] {
    return [{
      value: 'hh:mma',
      label: '12 hour'
    }, {
      value: 'HH:mm',
      label: '24 hour'
    }]
  }
}
