















































































import Datepicker from 'vuejs-datepicker'
import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import subWeeks from 'date-fns/subWeeks'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import Dropdown from './Dropdown.vue'

@Component({
  components: {
    Datepicker,
    Dropdown
  }
})
export default class FormDatepickerRanged extends Vue {
  currentValue: Date[] = []
  currentRangeIndex: number = -1

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ default: () => ([]) })
  readonly value!: Date[]

  created () {
    this.currentValue = this.value
  }

  @Emit('select')
  onSelectFrom (date: Date): Date[] {
    this.currentValue[0] = date
    this.currentRangeIndex = -1

    return this.currentValue
  }

  @Emit('select')
  onSelectTo (date: Date): Date[] {
    this.currentValue[1] = date
    this.currentRangeIndex = -1

    return this.currentValue
  }

  @Emit('select')
  onSelectRange (index: number, start: Date, end: Date) {
    this.currentRangeIndex = index === 0 ? -1 : index
    this.currentValue = [start, end]

    return this.currentValue
  }

  get periods () {
    const now = new Date()
    const lastWeek = subWeeks(now, 1)
    const lastMonth = subMonths(now, 1)

    return [
      ['All time', undefined, undefined],
      ['This week', startOfWeek(now), endOfWeek(now)],
      ['Last week', startOfWeek(lastWeek), endOfWeek(lastWeek)],
      ['This month', startOfMonth(now), endOfMonth(now)],
      ['Last month', startOfMonth(lastMonth), endOfMonth(lastMonth)]
    ]
  }
}
