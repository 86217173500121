/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Clients',
  createButton: 'New Client',
  success: 'Client was successfuly updated'
}
