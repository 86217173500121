/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import Router from 'vue-router'
import Vue from '@/providers/vue'
import Hours from '@/components/views/Hours.vue'
import Projects from '@/components/views/Projects.vue'
import Project from '@/components/views/Project.vue'
import Teams from '@/components/views/Teams.vue'
import Reports from '@/components/views/Reports.vue'
import Home from '@/components/views/Home.vue'
import Logout from '@/components/views/Logout.vue'
import Clients from '@/components/views/Clients.vue'
import Client from '@/components/views/Client.vue'
import Admin from '@/components/views/Admin.vue'
import Preferences from '@/components/views/Preferences.vue'
import Profile from '@/components/views/Profile.vue'
import NotFound from '@/components/views/NotFound.vue'
import store from '@/providers/store'
import config from '@/config'

Vue.use(Router)

const beforeHomeEnter = (to: any, from: any, next: Function) => {
  const loggedIn: boolean = store.getters['auth/isLoggedIn']
  if (loggedIn) {
    next({ name: 'hours' })
  } else {
    next()
  }
}

const router = new Router({
  mode: 'history',
  base: config.baseUrl,
  routes: [{
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: beforeHomeEnter
  }, {
    path: '/hours',
    name: 'hours',
    component: Hours,
    meta: { requiresAuth: true }
  }, {
    path: '/profile/:id?',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  }, {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: { requiresAuth: true }
  }, {
    path: '/project/:id',
    name: 'project',
    component: Project,
    meta: { requiresAuth: true }
  }, {
    path: '/teams',
    name: 'teams',
    component: Teams,
    meta: { requiresAuth: true }
  }, {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: { requiresAuth: true }
  }, {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: { requiresAuth: true }
  }, {
    path: '/client/:id',
    name: 'client',
    component: Client,
    meta: { requiresAuth: true }
  }, {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: { requiresAuth: true }
  }, {
    path: '/preferences',
    name: 'preferences',
    component: Preferences,
    meta: { requiresAuth: true }
  }, {
    path: '/logout',
    name: 'logout',
    component: Logout
  }, {
    path: '*',
    component: NotFound,
    meta: { showNavigation: false }
  }]
})

// Add a guard that checks authentication
router.beforeEach((to, from, next) => {
  const guarded: boolean = to.matched.some(record => record.meta.requiresAuth)
  const loggedIn: boolean = store.getters['auth/isLoggedIn']

  if (guarded) {
    if (loggedIn) {
      next() // FIXME: Renew token
    } else {
      next({ name: 'home' })
    }
  } else {
    next()
  }
})

export default router
