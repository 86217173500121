/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  team: {
    title: 'Team title',
    submit: 'Save Team'
  },
  client: {
    title: 'Client title',
    description: 'Description',
    submit: 'Save Client',
    delete: 'Remove',
    deleteConfirm: 'Are you sure ?'
  },
  entry: {
    spentAt: 'Date',
    timeSpent: 'Time spent',
    project: 'Project',
    title: 'Title',
    delete: 'Delete',
    deleteConfirm: 'Are you sure ?',
    submit: 'Save'
  },
  login: {
    email: 'Email',
    password: 'Password',
    forgot: 'Forgot your password?',
    submit: 'Log in'
  },
  project: {
    title: 'Project title',
    client: 'Add client...',
    description: 'Description',
    submit: 'Save Project',
    delete: 'Remove',
    deleteConfirm: 'Are you sure ?'
  },
  register: {
    email: 'Email',
    password: 'Password',
    submit: 'Register'
  },
  reset: {
    email: 'Email',
    submit: 'Request password reset'
  }
}
