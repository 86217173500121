






































































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import bus from '@/services/bus'
import { checkCollision } from '@/utils'
import router from '@/providers/router'
import { SearchResult } from '@/types/era'

@Component
export default class NavigationSearch extends Vue {
  query: string = ''
  opened: boolean = false
  currentIndex: number = -1

  @State('searching', { namespace: 'search' })
  isSearching!: boolean

  @State('results', { namespace: 'search' })
  searchResults!: SearchResult[]

  @Action('searchAll', { namespace: 'search' })
  searchAll!: (query: string) => Promise<SearchResult[]>

  mounted () {
    bus.$on('document-click', this.onDocumentClick)
  }

  beforeDestroy () {
    bus.$off('document-click', this.onDocumentClick)
  }

  onDocumentClick (ev: Event) {
    const modal = this.$refs.popup as Vue
    if (!modal || !checkCollision(modal.$refs.modal as Element, ev)) {
      this.opened = false
    }
  }

  onResultClick () {
    this.currentIndex = -1
    this.opened = false
    this.query = ''
  }

  onEnter () {
    if (this.currentIndex !== -1) {
      const link = this.link(this.searchResults[this.currentIndex])
      this.opened = false
      this.currentIndex = -1
      this.query = ''

      router.push(link)
    } else {
      this.opened = true
      this.currentIndex = -1

      this.searchAll(this.query)
    }
  }

  onArrowDown () {
    if (this.searchResults.length > 0) {
      this.currentIndex = Math.min(this.searchResults.length - 1, this.currentIndex + 1)
    } else {
      this.currentIndex = -1
    }
  }

  onArrowUp () {
    if (this.searchResults.length > 0) {
      this.currentIndex = Math.max(-1, this.currentIndex - 1)
    } else {
      this.currentIndex = -1
    }
  }

  isSelected (index: number) {
    return this.currentIndex === index
  }

  link (item: SearchResult) {
    return `/${item.type}/${item.id}`
  }

  get hasSearchResults () {
    return this.searchResults.length > 0
  }
}
