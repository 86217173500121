





























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { createUserAvatarUrl } from '@/services/api'
import { Team, TeamFormData, User } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import TeamForm from '@/components/forms/TeamForm.vue'
import bus from '@/services/bus'

@Component({
  components: {
    TeamForm
  }
})
export default class TeamModal extends Mixins(BaseModalForm) {
  @Prop()
  readonly team!: Team

  @Prop()
  readonly users!: User[]

  @Action('create', { namespace: 'teams' })
  createTeam!: (data: TeamFormData) => Promise<Team>

  @Action('update', { namespace: 'teams' })
  updateTeam!: (data: TeamFormData) => Promise<void>

  onSubmit (data: TeamFormData) {
    return this.submitWithFn(async () => {
      await (data.id
        ? this.updateTeam(data)
        : this.createTeam(data))

      bus.$emit('team-updated', data)

      return data
    })
  }

  onUserTeamChange (ev: Event, user: User) {
    const value = (ev.target as HTMLInputElement).checked
    if (value) {
      this.$emit('userJoin', {
        userId: user.id,
        teamId: this.team.id
      })
    } else {
      this.$emit('userLeave', {
        userId: user.id,
        teamId: this.team.id
      })
    }
  }

  avatar (user: User) {
    return createUserAvatarUrl(user.id)
  }

  hasUser (user: User) {
    return user.teams!.findIndex((team: Team) => team.id === this.team.id) !== -1
  }
}
