/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import fetcher from '@/utils/fetch'
import config from '@/config'

export interface AjvValidationError {
  dataPath: string
  message: string
}

export interface ApiErrorMessage {
  name: string
  message: string
}

export interface ApiResponse {
  success: boolean
  result?: any
  error?: string
  errors?: AjvValidationError[]
  stack?: string[]
}

export interface ApiRequestOptions {
  json?: boolean
  timeout?: number
  cancelContext?: string
  // TODO: Add skipContext
  //       Instead of cancelling the promise entirely
  //       chain it to the next one that was performed
  //       Will require a new Promise() context addution
}

export interface ApiPayload {
  [Key: string]: any
}

export interface ApiRequestHeaders {
  [Key: string]: string
}

/**
 * Map of active requests that are cancellable
 */
const activeRequests: Map<string, AbortController> = new Map()

/**
 * Checks if given Error is of type abort
 */
const isAbortError = (e: Error) => e instanceof DOMException && e.name === 'AbortError'

/**
 * Aborts given request
 */
const abortRequest = (key: string) => {
  const ctrl = activeRequests.get(key)
  if (ctrl) {
    ctrl.abort()
  }
  activeRequests.delete(key)
}

/**
 * Sets a state for a fetch request or removes one if there's one already active
 */
export const abortActiveRequest = (key: string, controller: AbortController) => {
  if (activeRequests.has(key)) {
    abortRequest(key)
  } else {
    activeRequests.set(key, controller)
  }
}

/**
 * Creates API url
 */
export const createApiUrl = (path: string) =>
  `${config.backendUrl}/api/v1/${path}`

/**
 * Creates a set of headers for a fetch request
 */
export const createHeaders = (append: ApiRequestHeaders, options: ApiRequestOptions) => {
  const headers = new Headers()

  if (typeof options.json === 'undefined' || options.json === true) {
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
  }

  Object
    .keys(append)
    .forEach(key => headers.set(key, append[key]))

  return headers
}

/**
 * Maps server validation errors
 */
export const mapValidationErrors = (errors: AjvValidationError[]) => errors
  .map(item => ({
    name: item.dataPath.replace(/^\./, ''),
    message: item.message
  })) as ApiErrorMessage[]

/**
 * API Abort Error (cancellation)
 */
export class ApiAbortError extends Error {
  timeout: boolean = false

  constructor (timeout: boolean) {
    super(timeout ? 'Request timed out' : 'User aborted request')

    this.timeout = timeout
  }
}

/**
 * API Error with validation results if given
 */
export class ApiError extends Error {
  errors: ApiErrorMessage[] = []
  errorNames: string[] = []
  statusCode: number = 0

  constructor (message: string, errors: AjvValidationError[] = [], response: Response) {
    super(message)

    this.errors = mapValidationErrors(errors)
    this.errorNames = this.errors.map(error => error.name)
    this.statusCode = response.status
  }
}

/**
 * Fetch wrapper for API requests
 */
export const fetch = async (
  method: string,
  path: string,
  payload?: ApiPayload,
  headers: ApiRequestHeaders = {},
  options: ApiRequestOptions = {},
  type: string = 'json'
) => {
  let timedOut = false
  const timeout = options.timeout || 0
  const cancelContext = options.cancelContext as string
  const createRequest = () => fetcher({
    baseUri: createApiUrl(''),
    headers: createHeaders(headers, options),
    method,
    path,
    payload
  })

  try {
    const { controller, request } = createRequest()

    if (cancelContext) {
      abortActiveRequest(cancelContext, controller)
    }

    if (timeout > 0) {
      setTimeout(() => {
        timedOut = true
        controller.abort()
      }, timeout)
    }

    const response = await request()

    if (type === 'blob') {
      return response.blob()
    }

    const { error, errors, result } = (await response.json()) as ApiResponse

    if (error) {
      throw new ApiError(error, errors, response)
    }

    return result
  } catch (e) {
    if (e instanceof Error) {
      throw isAbortError(e) ? new ApiAbortError(timedOut) : e
    }
  } finally {
    if (cancelContext) {
      activeRequests.delete(cancelContext)
    }
  }
}
