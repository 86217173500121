/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getUserProfile, updateUserProfile, updateUserPassword, updateUserAvatar, updateUserNotifications } from '@/services/api'
import { User, UserPasswordFormData, UserNotification } from '@/types/era'

@Module({ namespaced: true })
export default class PreferencesModule extends VuexModule {
  profile: User | null = null

  @Mutation
  SET_PROFILE (profile: User) {
    this.profile = profile
  }

  @Action({ commit: 'SET_PROFILE' })
  load (id: number) {
    return getUserProfile(id)
  }

  @Action({ commit: 'SET_PROFILE' })
  updateProfile (data: Partial<User>) {
    return updateUserProfile(this.profile!.id, data)
  }

  @Action
  updatePassword (data: UserPasswordFormData) {
    return updateUserPassword(this.profile!.id, data)
  }

  @Action
  updateAvatar (file: File) {
    return updateUserAvatar(this.profile!.id, file)
  }

  @Action
  updateNotifications (data: UserNotification[]) {
    return updateUserNotifications(this.profile!.id, data)
  }
}
