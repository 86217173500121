




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { createUserAvatarUrl } from '@/services/api'
import { User } from '@/types/era'

@Component
export default class UserLink extends Vue {
  @Prop({ required: false, default: false })
  readonly avatar!: boolean

  @Prop({ required: true })
  readonly user!: User

  get to () {
    return {
      name: 'profile',
      params: {
        id: this.user.id
      }
    }
  }

  get avatarSrc () {
    return createUserAvatarUrl(this.user.id)
  }

  get username () {
    return this.user.fullName ||
      this.user.username ||
      this.user.email
  }
}
