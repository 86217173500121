




























import { Component, Prop, Vue } from 'vue-property-decorator'
import defaultImage from '@/assets/images/avatar.png'

@Component
export default class Avatar extends Vue {
  @Prop({ type: String, default: defaultImage })
  readonly src!: string

  @Prop({ type: Boolean, default: true })
  readonly rounded!: boolean
}
