

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormConfirmButton extends Vue {
  active: boolean = false

  @Prop({ required: true, type: String })
  readonly inactiveText!: string

  @Prop({ required: true, type: String })
  readonly activeText!: string

  onClick ($event: Event) {
    if (this.active) {
      this.$emit('click', $event)
    } else {
      $event.stopPropagation()
      $event.preventDefault()

      this.active = true
    }
  }

  get text () {
    return this.active ? this.activeText : this.inactiveText
  }
}
