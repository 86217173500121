/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

// TODO: Add <T>
export const filterList = (list: any[], key: string, str: string): any[] => {
  const filter = (iter: any) => String(iter[key])
    .toLowerCase()
    .indexOf(str.toLowerCase()) !== -1

  return list.filter(filter)
}
