/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import VueLazyload from 'vue-lazyload'
import Vue from '@/providers/vue'

Vue.use(VueLazyload)
