/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @author Morten Mehus <morten.mehus@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { ApiError } from '@/services/api/fetch'
import { createPopper } from '@popperjs/core'

@Component
export default class BaseModalForm extends Vue {
  errors: string[] = []

  popper: any = null

  @Prop({ type: Boolean, default: true })
  readonly blackout!: boolean

  @Prop({ default: undefined })
  readonly target!: HTMLElement

  @Action('toggleBlackout', { namespace: 'interface' })
  toggleBlackout!: (toggle: boolean) => void

  @Emit('open')
  onOpen () {
    this.clearErrors()
  }

  @Emit('close')
  onClose () {
  }

  @Watch('target', { immediate: true })
  onTargetChanged (newTarget: HTMLElement) {
    this.popper?.destroy()

    if (newTarget) {
      this.$nextTick(() => {
        this.popper = createPopper(newTarget, this.$el as HTMLElement, {
          placement: 'right-start'
        })
      })
    }
  }

  mounted () {
    if (this.blackout) {
      this.toggleBlackout(true)
    }
  }

  destroyed () {
    if (this.blackout) {
      this.toggleBlackout(false)
    }

    this.popper?.destroy()
  }

  async submitWithFn (fn: Function) {
    this.errors = []

    try {
      const data = await fn()
      this.$emit('save', data)
      this.$emit('done')

      return data
    } catch (e) {
      if (e instanceof ApiError) {
        this.errors = e.errorNames
      } else {
        throw e
      }
    }

    return null
  }

  clearErrors () {
    this.errors = []
  }
}
