

















































import { Component, Vue } from 'vue-property-decorator'
import { Getter, State, Action } from 'vuex-class'
import { Client, MessageboxMessage } from '@/types/era'
import ClientModal from '@/components/modals/ClientModal.vue'
import ListingTable from '@/components/layout/ListingTable.vue'
import ListingTableHeading from '@/components/layout/ListingTableHeading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    DefaultTemplate,
    ClientModal,
    ListingTable,
    ListingTableHeading
  }
})
export default class ClientsView extends Vue {
  @State('client', { namespace: 'clients' })
  client!: Client

  @Getter('filtered', { namespace: 'clients' })
  clients!: Client[]

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  @Action('loadAll', { namespace: 'clients' })
  loadClients!: () => Promise<Client[]>

  @Action('load', { namespace: 'clients' })
  loadClient!: (id: number) => Promise<Client>

  @Action('clearCurrent', { namespace: 'clients' })
  clearClient!: () => void

  @Action('createCurrent', { namespace: 'clients' })
  newClient!: () => void

  @Action('setFilter', { namespace: 'clients' })
  setFilter!: (filter: string) => void

  created () {
    this.setFilter('')
    this.loadClients()
  }

  onClientCreate () {
    this.newClient()
  }

  onClientEdit (client: Client) {
    this.loadClient(client.id as number)
  }

  onModalSave (client: Client) {
    this.addMessage({
      message: this.$t('clients.success') as string
    })

    this.clearClient()
  }

  onModalClose () {
    this.clearClient()
  }

  onFilterInput (str: string) {
    this.setFilter(str)
  }
}
