

































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class FormTextarea extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: String, default: '' })
  readonly value!: ''

  @Prop()
  readonly maxlength!: number

  @Emit('input')
  onInput ($event: KeyboardEvent): string {
    return ($event.target as HTMLInputElement).value
  }

  get currentLength () {
    return String(this.value).length
  }
}
