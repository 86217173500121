

































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import Dropdown from './Dropdown.vue'
import RadioDropdownDrawer from './RadioDropdownDrawer.vue'
import { MultiselectDropdownOption } from '@/types/era'

@Component({
  components: {
    Dropdown,
    RadioDropdownDrawer
  }
})
export default class GroupDropdown extends Vue {
    @Prop({ type: String, default: '' })
    readonly placeholder!: string

    @Prop({ type: Boolean, default: false })
    readonly required!: boolean

    @Prop({ type: Boolean, default: false })
    readonly error!: boolean

    @Prop({ required: true, default: () => ([]) })
    readonly group!: MultiselectDropdownOption[]

    @Prop({ required: false, default: () => ([]) })
    readonly selectedGroup!: string

    @Emit('selectGroup')
    onSelectGroup (item: MultiselectDropdownOption) {
      return item
    }

    @Emit('selectSubgroup')
    onSelectSubgroup (item: MultiselectDropdownOption) {
      return item
    }

    get getSubGroup (): any {
      return this.group.find(item => item.value === this.selectedGroup)!.items
    }
}

