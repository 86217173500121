




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { createUserAvatarUrl } from '@/services/api'

@Component
export default class UserGroupLink extends Vue {
  @Prop({ required: false, default: false })
  readonly avatar!: boolean

  @Prop({ required: true })
  readonly username!: string

  @Prop({ required: true })
  readonly userId!: number

  get to () {
    return {
      name: 'profile',
      params: {
        id: this.userId
      }
    }
  }

  get avatarSrc () {
    return createUserAvatarUrl(this.userId)
  }
}
