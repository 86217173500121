/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { searchAll } from '@/services/api'
import { SearchResult } from '@/types/era'

@Module({ namespaced: true })
export default class SearchModule extends VuexModule {
  results: SearchResult[] = []
  searching: boolean = false

  @Mutation
  SET_SEARCH_RESULTS (results: SearchResult[]) {
    this.results = results
  }

  @Mutation
  SET_SEACHING (toggle: boolean) {
    this.searching = toggle
  }

  @Action
  async searchAll (query: string) {
    this.context.commit('SET_SEACHING', true)

    try {
      if (query.length > 0) {
        const results = await searchAll(query)
        this.context.commit('SET_SEARCH_RESULTS', results)
      } else {
        this.context.commit('SET_SEARCH_RESULTS', [])
      }
    } finally {
      this.context.commit('SET_SEACHING', false)
    }
  }
}
