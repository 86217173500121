










































import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Getter, Action } from 'vuex-class'
import { TimeEntry, MessageboxMessage } from '@/types/era'
import Message from '@/components/layout/Message.vue'
import StopwatchMessage from '@/components/layout/StopwatchMessage.vue'

let popMessageInterval: any

@Component({
  components: {
    Message,
    StopwatchMessage
  }
})
export default class MessageBox extends Vue {
  @State('stopwatches', { namespace: 'stopwatches' })
  stopwatches!: { [key: number]: TimeEntry }

  @State('messages', { namespace: 'session' })
  messages!: MessageboxMessage[]

  @Action('removeMessage', { namespace: 'session' })
  removeMessage!: (index: number) => void

  @Action('popMessage', { namespace: 'session' })
  popMessage!: () => void

  @Action('removeStopwatch', { namespace: 'stopwatches' })
  removeStopwatch!: (entryId: number) => void

  @Action('toggleStopwatch', { namespace: 'stopwatches' })
  toggleStopwatch!: (entryId: number) => void

  @Action('loadAll', { namespace: 'stopwatches' })
  loadStopwatches!: () => void

  @Getter('isLoggedIn', { namespace: 'auth' })
  loggedIn!: boolean

  @Watch('loggedIn')
  onLoggedInChanged (value: boolean, oldValue: boolean) {
    if (!oldValue && value) {
      this.loadStopwatches()
    }
  }

  mounted () {
    if (this.loggedIn) {
      this.loadStopwatches()
    }

    popMessageInterval = setInterval(() => this.onPopMessage(), 1000)
  }

  destroyed () {
    clearInterval(popMessageInterval)
  }

  onMessageClick (ev: Event, index: number) {
    this.removeMessage(index)
  }

  onStopwatchClick (ev: Event, entryId: number) {
    this.toggleStopwatch(entryId)
  }

  onStopwatchRemove (ev: Event, entryId: number) {
    this.removeStopwatch(entryId)
  }

  onPopMessage () {
    this.popMessage()
  }
}
