var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultTemplate',{attrs:{"title":_vm.$t('reports.heading'),"padded":true}},[_c('Filters',{class:_vm.$style.filters,attrs:{"filters":_vm.filters},on:{"remove":_vm.onRemoveItem}},[_c('FormDatepickerRanged',{attrs:{"placeholder":_vm.datePlaceholder},on:{"select":_vm.onDateSelect}}),_c('FormMultiselectDropdown',{attrs:{"placeholder":_vm.$t('reports.filters.clients'),"options":_vm.clientOptions},on:{"select":_vm.onMultiselectEntry,"selectAll":function($event){return _vm.onMultiselectAll('clients')},"selectNone":function($event){return _vm.onMultiselectNone('clients')}}}),_c('FormMultiselectDropdown',{attrs:{"placeholder":_vm.$t('reports.filters.teams'),"options":_vm.teamOptions},on:{"select":_vm.onMultiselectEntry,"selectAll":function($event){return _vm.onMultiselectAll('teams')},"selectNone":function($event){return _vm.onMultiselectNone('teams')}}}),_c('FormAutocomplete',{attrs:{"placeholder":_vm.$t('reports.filters.tags')},on:{"select":function (item) { return _vm.onAddItem('tags', item); }}}),_c('GroupDropdown',{attrs:{"placeholder":_vm.$t('reports.filters.group'),"group":_vm.groupOptions,"selectedGroup":_vm.groupFilter},on:{"selectGroup":_vm.onSelectGroup,"selectSubgroup":_vm.onSelectSubgroup}}),_c('FormButton',{style:({flex: '0 0'}),attrs:{"icon":"Plus"},on:{"click":_vm.onExportClick}},[_c('span',[_vm._v(_vm._s(_vm.$t('reports.filters.export')))])])],1),_c('Table',{class:_vm.$style.table,scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"100%"}},[_vm._v(_vm._s(_vm.$t('reports.table.users')))]),_c('th',{class:_vm.$style.right},[_vm._v(_vm._s(_vm.$t('reports.table.logged')))])])]},proxy:true},{key:"body",fn:function(){return [_vm._l((_vm.projectTree),function(ref){
var group = ref.group;
var subgroups = ref.subgroups;
var _expanded = ref._expanded;
return [_c('tr',{key:((group.id) + "-top"),class:_vm.$style.row},[_c('td',[_c('div',{class:_vm.$style.groupContainer},[_c('Icon',{class:_vm.$style.chevron,attrs:{"icon":_expanded ? 'ChevronDown' : 'ChevronUp'},on:{"click":function($event){$event.preventDefault();return _vm.onUserExpandToggle(group.id)}}}),(group.type == 'user')?_c('div',{class:_vm.$style.user},[_c('UserGroupLink',{attrs:{"avatar":true,"userId":group.id,"username":group.title}})],1):_c('div',{class:_vm.$style.groupTitle},[_c('div',{class:_vm.$style.circle,style:({backgroundColor: group.color})}),_c('span',[_vm._v(_vm._s(group.title)+" "+_vm._s(group.type))])])],1)]),_c('td',{class:_vm.$style.right},[_vm._v(_vm._s(_vm.totalHoursLogged2(group.totalTime)))])]),(_expanded)?_c('tr',{key:((group.id) + "-bottom")},[_c('td',{attrs:{"colspan":"2"}},_vm._l((subgroups),function(ref){
var id = ref.id;
var title = ref.title;
var totalTime = ref.totalTime;
var entries = ref.entries;
var color = ref.color;
var type = ref.type;
var _expanded = ref._expanded;
return _c('div',{key:id,class:_vm.$style.project},[_c('header',[_c('div',{class:_vm.$style.projectTitle},[_c('Icon',{class:_vm.$style.chevron,attrs:{"icon":_expanded ? 'ChevronDown' : 'ChevronUp'},on:{"click":function($event){$event.preventDefault();return _vm.onProjectExpandToggle(group.id, id)}}}),(type == 'user')?_c('div',{class:_vm.$style.user},[_c('UserGroupLink',{attrs:{"avatar":true,"userId":id,"username":title}})],1):_c('div',{class:_vm.$style.groupTitle},[_c('div',{class:_vm.$style.circle,style:({backgroundColor: color})}),_c('span',[_vm._v(_vm._s(title)+" "+_vm._s(type))])])],1),_c('div',{class:_vm.$style.right},[_vm._v(" "+_vm._s(_vm.totalHoursLogged2(totalTime))+" ")])]),(_expanded)?_c('div',{class:_vm.$style.entries},_vm._l((entries),function(entry){return _c('div',{key:entry.id,class:_vm.$style.entry},[_c('div',{class:_vm.$style.entryTitle,domProps:{"innerHTML":_vm._s(_vm.parseTitle(entry.title))},on:{"click":function($event){$event.stopPropagation();}}}),_c('div',{class:_vm.$style.right},[_vm._v(" "+_vm._s(_vm.formattedTime(entry.timeSpent))+" ")])])}),0):_vm._e()])}),0)]):_vm._e()]})]},proxy:true},{key:"foot",fn:function(){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('reports.table.sum'))+" ")]),_c('td',[_c('div',{class:_vm.$style.right},[_vm._v(" "+_vm._s(_vm.formattedTime(_vm.totalTimeSpent))+" ")])])])]},proxy:true}])}),(_vm.startExport)?_c('ReportExportModal',{on:{"close":_vm.onModalClose,"submit":_vm.onModalSubmit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }