

























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ default: '#000000' })
  readonly color!: string

  @Prop({ default: 20 })
  readonly size!: number

  @Prop({ default: 5 })
  readonly strokeWidth!: number

  @Prop({ default: false })
  readonly loading!: boolean

  get outerSize () {
    return (this.size * 2) + (this.strokeWidth * 2)
  }

  get outerStyle () {
    return {
      width: this.outerSize,
      height: this.outerSize
    }
  }

  get innerStyle () {
    return {
      stroke: this.color
    }
  }

  get center () {
    return this.size + this.strokeWidth
  }

  get viewBox () {
    return `0 0 ${this.outerSize} ${this.outerSize}`
  }
}
