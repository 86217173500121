/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Reports',
  filters: {
    time: 'All time',
    clients: 'Clients/projects...',
    teams: 'Teams/users...',
    tags: 'Tags...',
    group: 'Grouped by...',
    export: 'Export'
  },
  table: {
    users: 'Users',
    logged: 'Logged hours',
    sum: 'Sum'
  },
  labels: {
    clients: 'Clients and projects',
    teams: 'Users and teams',
    tags: 'Tags'
  },
  exportTypes: {
    header: 'Chose report format:'
  }
}
