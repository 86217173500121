/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { inviteUser, listInvitedUsers } from '@/services/api'
import { User, UserInviteFormData } from '@/types/era'

@Module({ namespaced: true })
export default class AdminModule extends VuexModule {
  invitedUsers: User[] = []

  @Mutation
  SET_INVITED_USERS (list: User[]) {
    this.invitedUsers = list
  }

  @Action({})
  async inviteUser (form: UserInviteFormData) {
    return inviteUser(form)
  }

  @Action({ commit: 'SET_INVITED_USERS' })
  async loadInvitedUsers () {
    return listInvitedUsers()
  }
}
