



























import { Component, Prop, Vue } from 'vue-property-decorator'
import Card from '@/components/layout/Card.vue'

@Component({
  components: {
    Card
  }
})
export default class CalendarCard extends Vue {
  @Prop({ default: false })
  readonly stripe!: boolean

  @Prop({ default: '#2B3AA4' })
  readonly stripeColor!: string

  @Prop({ type: String })
  readonly classOverride!: string
}
