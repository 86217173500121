












































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class FormTextbox extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: String, default: '' })
  readonly icon!: string

  @Prop({ type: [String, Number], default: '' })
  readonly value!: string | number

  @Prop({ type: String })
  readonly cursor!: string

  @Prop()
  readonly maxlength!: number

  get inputStyle () {
    return {
      cursor: this.cursor
    }
  }

  // FIXME: I really wanna v-on="$listeners" on this, but doesn't work with overrides ?!

  @Emit('click')
  onClick () {
  }

  @Emit('focus')
  onFocus ($event: Event) {
    return $event
  }

  @Emit('blur')
  onBlur ($event: Event) {
    return $event
  }

  @Emit('keypress')
  onKeyPress ($event: KeyboardEvent) {
    return $event
  }

  @Emit('keyup')
  onKeyUp ($event: KeyboardEvent) {
    return $event
  }

  @Emit('keydown')
  onKeyDown ($event: KeyboardEvent) {
    return $event
  }

  @Emit('input')
  onInput ($event: KeyboardEvent): string | number {
    const str: string = ($event.target as HTMLInputElement).value

    if (this.type === 'number') {
      return parseInt(str, 10)
    }

    return str
  }
}
