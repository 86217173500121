


































import { Component, Vue } from 'vue-property-decorator'
import { Getter, State, Action } from 'vuex-class'
import { Client, Project } from '@/types/era'
import Heading from '@/components/layout/Heading.vue'
import ListingTable from '@/components/layout/ListingTable.vue'
import ListingTableHeading from '@/components/layout/ListingTableHeading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    Heading,
    DefaultTemplate,
    ListingTable,
    ListingTableHeading
  }
})
export default class ClientView extends Vue {
  @State('client', { namespace: 'client' })
  client!: Client

  @Getter('filteredProjects', { namespace: 'client' })
  projects!: Project[]

  @Action('load', { namespace: 'client' })
  loadClient!: (id: number) => Promise<Client>

  @Action('setFilter', { namespace: 'client' })
  setFilter!: (filter: string) => void

  onFilterInput (str: string) {
    this.setFilter(str)
  }

  onProjectEdit (project: Project) {
    // TODO
    this.$router.push({ name: 'projects' })
  }

  created () {
    const id = parseInt(this.$route.params.id, 10)

    this.loadClient(id)
  }

  get documentTitle () {
    const prefix = this.$t('client.heading') as string
    return this.client
      ? `${prefix} - ${this.client.title}`
      : prefix
  }
}
