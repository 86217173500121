



























































































































































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { CalendarHeatmap } from 'vue-calendar-heatmap'
import format from 'date-fns/format'
import { createUserAvatarUrl } from '@/services/api'
import { TimeEntry, UserProfile, UserAuth, CalendarCardEvent, User } from '@/types/era'
import ProfileTimeCard from '@/components/layout/ProfileTimeCard.vue'
import ProfileEntryModal from '@/components/modals/ProfileEntryModal.vue'
import Heading from '@/components/layout/Heading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'
import Card from '@/components/layout/Card.vue'
import Icon from '@/components/base/Icon.vue'
import bus from '@/services/bus'

@Component({
  components: {
    Heading,
    DefaultTemplate,
    CalendarHeatmap,
    ProfileTimeCard,
    ProfileEntryModal,
    Card,
    Icon
  }
})
export default class ProfileView extends Vue {
  modalTarget: HTMLElement | null = null

  @State('entry', { namespace: 'interface' })
  entry!: TimeEntry

  @State('profile', { namespace: 'profile' })
  userProfile!: UserProfile

  @State('profile', { namespace: 'auth' })
  authProfile!: UserAuth

  @State('currentDate', { namespace: 'profile' })
  currentDate!: Date

  @State('entries', { namespace: 'profile' })
  entries!: Date

  @Action('clear', { namespace: 'profile' })
  clearProfile!: () => Promise<undefined>

  @Action('load', { namespace: 'profile' })
  loadProfile!: (id: number) => Promise<User>

  @Action('loadEntries', { namespace: 'profile' })
  loadEntries!: () => Promise<TimeEntry[]>

  @Action('loadEntry', { namespace: 'interface' })
  loadEntry!: (id: number) => Promise<TimeEntry>

  @Action('clearCurrent', { namespace: 'interface' })
  clearCurrent!: () => void

  @Action('setFilterPeriod', { namespace: 'profile' })
  setFilterPeriod!: (date: Date) => void

  @Action('createCurrent', { namespace: 'interface' })
  createCurrent!: (data: any) => void

  async created () {
    const id = parseInt(this.$route.params.id, 10)
    const loadId = isNaN(id) ? this.authProfile.id : id

    bus.$on('stopwatch-stopped', this.refreshEntries)

    await this.loadProfile(loadId)

    if (this.userProfile) {
      await this.loadEntries()
    }
  }

  beforeDestroy () {
    bus.$off('stopwatch-stopped', this.refreshEntries)
    this.clearProfile()
  }

  onCardClick (click: CalendarCardEvent) {
    const target: HTMLElement = click.event.target as HTMLElement

    const entryId = click.entry.id!
    this.loadEntry(entryId)
    this.modalTarget = target
  }

  async onNewEntry (event: MouseEvent) {
    this.modalTarget = event.target as HTMLElement
    await this.createCurrent({
      spentAt: this.currentDate || new Date()
    })
  }

  async refreshEntries () {
    await this.loadEntries()
  }

  onModalClose () {
    this.modalTarget = null
    this.clearCurrent()
  }

  onModalSave () {
    this.onModalClose()
  }

  onModalDone () {
    this.loadEntries()
  }

  async onDayClick (click: any) {
    await this.setFilterPeriod(click.date)
    await this.loadEntries()
  }

  get heatmapEndDate () {
    return format(new Date(), 'yyyy-LL-dd')
  }

  get heatmapColors () {
    return [
      '#deebf5',
      '#abb5cf',
      '#757a9a',
      '#515272',
      '#36334a'
    ]
  }

  get formattedCurrentDate () {
    return format(this.currentDate, 'yyyy-LL-dd')
  }

  get username () {
    return this.userProfile.fullName || this.userProfile.username
  }

  get avatarImage () {
    return createUserAvatarUrl(this.userProfile!.id)
  }

  get isMyself () {
    return this.authProfile!.id === this.userProfile!.id
  }
}
