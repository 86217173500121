





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Photoshop } from 'vue-color'
import Dropdown from './Dropdown.vue'
import invert from 'invert-color'

@Component({
  components: {
    Dropdown,
    Photoshop
  }
})
export default class FormColorDropdown extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly error!: boolean

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean

  @Prop({ default: '' })
  readonly value!: string

  open: boolean = false
  tempColor: string = this.value

  onUpdateColor (color: { hex: 'string' }) {
    this.tempColor = color.hex
  }

  onDropClose () {
    this.open = false
  }

  onDropOpen () {
    this.open = true
  }

  onClose (ok: boolean) {
    setTimeout(() => {
      this.open = false
    })

    if (ok) {
      this.$emit('input', this.tempColor)
    }
  }

  get inputLabel () {
    return this.value
  }

  get inputValue () {
    return this.value || ''
  }

  get inputStyle () {
    return {
      backgroundColor: this.inputValue ? this.inputValue : undefined,
      color: this.inputValue ? invert(this.inputValue) : undefined
    }
  }
}
