/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

// NOTE: Do **NOT** set process.env.NODE_ENV shortcuts here
// as this will not tree shake in production!

import { FirstDayOfWeek } from '@/types/era'

export interface Languages {
  [Key: string]: string
}

export interface Branding {
  title: string
}

export interface Configuration {
  baseUrl: string
  backendUrl: string
  branding: Branding
  languages: Languages
  language: string
  firstDayOfWeek: FirstDayOfWeek
  timeFormat: string
  dateFormat: string
}

const configuration: Configuration = {
  baseUrl: process.env.BASE_URL || '',
  backendUrl: process.env.VUE_APP_BACKEND_URL || window.location.origin,
  branding: {
    title: 'Era'
  },
  languages: {
    en: 'English',
    no: 'Norwegian'
  },
  language: 'en',
  firstDayOfWeek: 0,
  timeFormat: 'hh:mma',
  dateFormat: 'LL.dd.yy'
}

export default configuration
