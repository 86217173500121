




















import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import bus from '@/services/bus'
import { checkCollision } from '@/utils'

@Component
export default class TeamDayReportModal extends Mixins(BaseModalForm) {
  @Prop({ default: undefined })
  declare readonly target: HTMLElement

  @Emit('toggle')
  onDocumentClick (ev: MouseEvent) {
    const modal = this.$refs.popup as any // FIXME: Vue type

    return modal && checkCollision(modal.$refs.modal as Element, ev)
  }

  mounted () {
    bus.$on('document-click', this.onDocumentClick)
  }

  beforeDestroy () {
    bus.$off('document-click', this.onDocumentClick)
  }
}
