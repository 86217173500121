















































































































import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import format from 'date-fns/format'
import { ProjectListItem, TimeEntryFormData, MessageboxMessage, TimeEntry, AutosaveData } from '@/types/era'
import StopwatchButton from '@/components/layout/StopwatchButton.vue'
import { parseEntryInput } from '@/utils'
import { debounce } from 'throttle-debounce'
import bus from '@/services/bus'

@Component({
  components: {
    StopwatchButton
  }
})
export default class TimeEntryBar extends Vue {
  time: number = 0
  project: number | null = null
  title: string = ''
  date: string = format(new Date(), 'LL-dd-yyyy')
  text: string = ''

  @Prop({ required: true })
  readonly textbox!: boolean

  @State('projects', { namespace: 'interface' })
  projects!: ProjectListItem[]

  @State('importtext', { namespace: 'interface' })
  importtext!: string

  @State('importtextid', { namespace: 'interface' })
  importtextid!: number | null

  @Action('createEntry', { namespace: 'interface' })
  createEntry!: (form: TimeEntryFormData) => Promise<TimeEntry>

  @Action('createAutosave', { namespace: 'interface' })
  createAutosave!: (form: AutosaveData) => Promise<[AutosaveData, boolean]>

  @Action('updateAutosave', { namespace: 'interface' })
  updateAutosave!: (form: AutosaveData) => Promise<AutosaveData>

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  @Action('createStopwatch', { namespace: 'stopwatches' })
  createStopwatch!: (entry: TimeEntryFormData) => void

  @Action('setImporttext', { namespace: 'interface' })
  setImporttext!: (importtext: string) => Promise<string>

  @Action('setImporttextId', { namespace: 'interface' })
  setImporttextId!: (importtextid: number|null) => Promise<number|null>

  @State('dateFormat', { namespace: 'session' })
  dateFormat!: string

  @Watch('importtext')
  watchRoute ($text: string) {
    this.text = $text
  }

  handleChange = debounce(2500, () => this.onChange())

  async onChange () {
    if (this.textbox && this.text !== '') {
      if (this.importtextid) {
        try {
          await this.updateAutosave({
            id: this.importtextid,
            entries: this.text
          })
        } catch {}
        bus.$emit('draft-updated')
      } else {
        try {
          const [result] = await this.createAutosave({
            entries: this.text
          })
          this.setImporttextId(result.id!)
        } catch {}
        bus.$emit('draft-updated')
      }
    }
  }

  async onSubmit () {
    if (this.textbox) {
      const list = this.text.split('\n')
      const errorLines = []

      for (let i = 0; i < list.length; i++) {
        const parseStr = list[i]
        const { fullDate, project, entryTitle, hours: h, minutes: m } = parseEntryInput(parseStr, this.dateFormat, this.projectChoices, i, list)

        if (fullDate && project) {
          await this.createEntry({
            projects: [project.value],
            spentAt: fullDate,
            timeSpent: (Number(h) * 60) + Number(m),
            title: entryTitle || ''
          })
        } else {
          errorLines.push(parseStr)
        }
      }
      if (list.length > 0) {
        this.addMessage({
          message: this.$t('common.interface.timeLogged') as string
        })
        this.onClear()
        this.$emit('timeLogged')
      }

      if (errorLines.length > 0) {
        this.setImporttext(errorLines.join('\n'))
        try {
          if (this.importtextid) {
            await this.updateAutosave({
              id: this.importtextid,
              entries: errorLines.join('\n')
            })
            bus.$emit('draft-updated')
          }
        } catch {}
      } else {
        try {
          if (this.importtextid) {
            await this.updateAutosave({
              id: this.importtextid,
              entries: this.text,
              status: 'completed'
            })
            bus.$emit('draft-updated')
          }
        } catch {}
      }
    } else {
      try {
        await this.createEntry({
          projects: this.project ? [this.project] : [],
          timeSpent: this.time,
          spentAt: this.date,
          title: this.title
        })

        this.addMessage({
          message: this.$t('common.interface.timeLogged') as string
        })

        this.onClear()

        this.$emit('timeLogged')
      } catch {
      }
    }
  }

  onClear () {
    this.clear()
  }

  clear () {
    this.time = 0
    this.project = null
    this.title = ''
    this.setImporttext('')
    this.setImporttextId(null)
  }

  onStopwatchToggle () {
    try {
      this.createStopwatch({
        projects: this.project ? [this.project] : [],
        timeSpent: this.time,
        spentAt: this.date,
        title: this.title
      })

      this.onClear()
    } catch {
    }
  }

  updateTitle ($title: string) {
    this.title = $title
  }

  updateImporttext ($importtext: string) {
    this.setImporttext($importtext)
  }

  get projectChoices () {
    return this.projects.map((p: ProjectListItem) => ({
      key: `${p.id}-${p.updatedAt}`,
      label: p.clients && p.clients.length > 0 ? `${p.clients[0].title} / ${p.title}` : `${p.title}`,
      title: p.title,
      color: p.color,
      value: p.id
    }))
  }
}
