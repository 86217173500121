/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { config } from 'vuex-module-decorators'
import { Component } from 'vue-property-decorator'

// FIXME: Do we want this in all environments?
if (process.env.NODE_ENV === 'development') {
  config.rawError = true
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
