/**
 * @project Era
 * @author Marko Muric <marko.muric@copyleft.rs>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { parse } from 'date-fns'

export const getMonthIndex = (month:string):number => {
  return new Date(Date.parse(`${month} 1, 2012`)).getMonth()
}

export const formatTime = (hours:number|undefined, minutes:number|undefined): string => {
  const h1 = hours !== undefined ? hours : 0
  const m1 = minutes !== undefined ? minutes : 0
  return `${h1.toString().padStart(2, '0')}:${m1.toString().padStart(2, '0')}`
}

export const convertDecimalToMinutes = (timeString:string):number => {
  return Math.round(parseFloat(timeString) * 60)
}

export const convertMinutesToHours = (minutes:string) => {
  const tmp = parseInt(minutes, 10) / 60
  const newHours = Math.trunc(tmp)
  const newMinutes = convertDecimalToMinutes(`${tmp % 1}`)
  return { newHours, newMinutes }
}

export const getSubtractConfig = (obj:RegExpMatchArray):Duration => {
  const subtract:Duration = {}
  const chunkSize = 3
  const tail = obj.slice(1)
  const dateValues = []
  for (let i = 0; i < tail.length; i += chunkSize) {
    const chunk = tail.slice(i, i + chunkSize)
    // don't use attribute if they are undefined
    const tmp = [...new Set(chunk)]
    if (tmp[0]) {
      dateValues.push(chunk)
    }
  }

  for (let i = 0; i < dateValues.length; i++) {
    const data = dateValues[i]
    if (data[2] === 'd' && !subtract.days) {
      subtract.days = parseInt(data[1])
    }
    if (data[2] === 'w' && !subtract.weeks) {
      subtract.weeks = parseInt(data[1])
    }
    if (data[2] === 'm' && !subtract.months) {
      subtract.months = parseInt(data[1])
    }
    if (data[2] === 'y' && !subtract.years) {
      subtract.years = parseInt(data[1])
    }
  }

  return subtract
}

export const getDotTime = (hrs?:string, mns?:string):{ hours:number, minutes:number } => {
  const { newHours, newMinutes } = mns && mns !== '' ? convertMinutesToHours(`${convertDecimalToMinutes(`0.${mns}`)}`) : { newHours: 0, newMinutes: 0 }
  const hours = hrs && hrs !== '' ? parseInt(hrs, 10) + newHours : newHours
  return { hours, minutes: newMinutes }
}

export const getNormalTimeFormat = (hrs:string, mns:string):{ hours:number, minutes:number } => {
  const { newHours, newMinutes } = mns && mns !== '' ? convertMinutesToHours(mns) : { newHours: 0, newMinutes: 0 }
  const hours = hrs && hrs !== '' ? parseInt(hrs, 10) + newHours : newHours
  return { hours, minutes: newMinutes }
}

export const getOrderDate = (date:Date, day:string, month?: string) => {
  if (day) {
    date.setDate(parseInt(day))
  }
  if (month) {
    date.setMonth(getMonthIndex(month))
  }

  return { date }
}

export const getYesterday = (category:string, date:Date) => {
  if (['yesterday', 'yd', /(y+)d/].includes(category)) {
    date.setDate(date.getDate() - 1)
  } else {
    const match = category.match(/(y+)d/)
    if (match) {
      const count = match[1].length
      date.setDate(date.getDate() - count)
    }
  }
}

export const parseDate = (date: Date, dateString: string) => {
  const timestamp = Date.parse(dateString)
  if (isNaN(timestamp)) {
    /**
     * So far I found that this is the only one format of date that js cannot parse.
     * In the we may need more formats here, or to find better solution for different format of dates parser.
    */
    const ds = dateString.replace(/[\s./-]/g, '.')
    date = parse(ds, 'dd.LL.yyyy', new Date())
  } else {
    date = new Date(timestamp)
  }

  return date
}

export const getToday = (date:Date) => {}
