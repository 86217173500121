









































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { orderBy } from 'lodash-es'
import ListingTableChevron from './ListingTableChevron.vue'
import DateTime from './DateTime.vue'
import TotalTime from './TotalTime.vue'

const sortColumns: string[] = [
  'title',
  'description',
  'updatedAt'
]

@Component({
  components: {
    ListingTableChevron,
    DateTime,
    TotalTime
  }
})
export default class ListingTable extends Vue {
  sortByIndex: number = 0
  sortByDirection: number = 0

  @Prop({ required: true })
  readonly list!: any

  @Prop({ required: true })
  readonly route!: string

  @Prop({ default: false })
  readonly fixed!: boolean

  @Emit('edit')
  onEdit (ev: Event, item: any, index: number) {
    return item
  }

  onColumnSortClick (column: number) {
    if (column !== this.sortByIndex) {
      this.sortByDirection = 0
    } else {
      this.sortByDirection = (this.sortByDirection + 1) % 2
    }

    this.sortByIndex = column
  }

  routerLink (item: any) {
    return {
      name: this.route,
      params: {
        id: item.id
      }
    }
  }

  showTotalTime (item: any) {
    return this.route === 'project' && 'totalTimeSpent' in item
  }

  get sortedList () {
    const col = sortColumns[this.sortByIndex]
    const dir = this.sortByDirection ? 'desc' : 'asc'
    return orderBy(this.list, col, dir)
  }

  get showTotalTimeHeader () {
    return this.route === 'project' && this.list.map((x:any) => 'totalTimeSpent' in x).every((x:any) => x === true)
  }
}
