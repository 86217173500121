/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import '@fontsource/inter'
import 'vue-calendar-heatmap/dist/vue-calendar-heatmap.css'

import '@/services/worker'
import '@/services/sentry'
import '@/providers/hooks'
import '@/providers/lazyload'
import '@/providers/globals'
import '@/styles/schemes/light.scss' // TODO: Load dynamically
import '@/styles/main.scss'

import store from '@/providers/store'
import i18n from '@/providers/i18n'
import router from '@/providers/router'
import Vue from '@/providers/vue'
import App from '@/components/App.vue'
import config from '@/config'

const lang = store.getters['session/currentLocale']
i18n.locale = lang || config.language

export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
