






















import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { Client, ClientFormData } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import ClientForm from '@/components/forms/ClientForm.vue'
import bus from '@/services/bus'

@Component({
  components: {
    ClientForm
  }
})
export default class ClientModal extends Mixins(BaseModalForm) {
  @Prop()
  readonly client!: Client

  @Action('create', { namespace: 'clients' })
  createClient!: (data: ClientFormData) => Promise<Client>

  @Action('update', { namespace: 'clients' })
  updateClient!: (data: ClientFormData) => Promise<Client>

  @Action('remove', { namespace: 'clients' })
  removeClient!: (data: ClientFormData) => Promise<void>

  @Emit('close')
  async onRemove (data: ClientFormData) {
    await this.removeClient(data)
  }

  onSubmit (data: ClientFormData) {
    return this.submitWithFn(async () => {
      await (data.id
        ? this.updateClient(data)
        : this.createClient(data))

      bus.$emit('client-updated', data)

      return data
    })
  }
}
