




































import { Component, Vue } from 'vue-property-decorator'
import WelcomeTemplate from '@/components/templates/WelcomeTemplate.vue'

@Component({
  components: {
    WelcomeTemplate
  }
})
export default class NotFoundView extends Vue {
}
