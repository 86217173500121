








































import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { ApiError } from '@/services/api/fetch'
import { Project, ClientListItem, ProjectFormData, Client, ClientFormData } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import ProjectForm from '@/components/forms/ProjectForm.vue'
import ClientForm from '@/components/forms/ClientForm.vue'
import bus from '@/services/bus'

@Component({
  components: {
    ProjectForm,
    ClientForm
  }
})
export default class ProjectModal extends Mixins(BaseModalForm) {
  showClient: boolean = false
  clientErrors: string[] = []

  @State('clients', { namespace: 'interface' })
  clients!: ClientListItem[]

  @Prop()
  readonly project!: Project

  @Action('create', { namespace: 'projects' })
  createProject!: (data: ProjectFormData) => Promise<Project>

  @Action('update', { namespace: 'projects' })
  updateProject!: (data: ProjectFormData) => Promise<void>

  @Action('remove', { namespace: 'projects' })
  removeProject!: (data: ProjectFormData) => Promise<void>

  @Action('createAndReturn', { namespace: 'clients' })
  createClient!: (data: ClientFormData) => Promise<Client>

  @Action('loadClients', { namespace: 'interface' })
  loadClients!: () => Promise<Client[]>

  onNewClient () {
    this.showClient = !this.showClient
  }

  @Emit('close')
  async onRemove (data: ProjectFormData) {
    await this.removeProject(data)
  }

  async onClientSubmit (data: ClientFormData) {
    try {
      const result = await this.createClient(data)
      await this.loadClients()
      this.showClient = false

      this.$emit('newClient', result)
    } catch (e) {
      if (e instanceof ApiError) {
        this.clientErrors = e.errorNames
      } else {
        throw e
      }
    }
  }

  onSubmit (data: ProjectFormData) {
    return this.submitWithFn(async () => {
      await (data.id
        ? this.updateProject(data)
        : this.createProject(data))

      bus.$emit('project-updated', data)

      return data
    })
  }
}
