


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FilterState } from '@/types/era'

@Component
export default class Filters extends Vue {
  @Prop({ required: true })
  readonly filters!: FilterState

  onRemoveClick (event: Event, name: string, index: number) {
    this.$emit('remove', event, name, index)
  }
}
