

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import bus from '@/services/bus'
import { checkCollision } from '@/utils'

@Component({})
export default class NavigationDropdown extends Vue {
  visible = false

  @Prop({ type: String })
  readonly avatar!: string

  mounted () {
    bus.$on('document-click', (ev: Event) => {
      if (!checkCollision(this.$refs.root as Element, ev)) {
        this.visible = false
      }
    })
  }

  onClick () {
    this.visible = !this.visible
  }
}
