/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import subYears from 'date-fns/subYears'
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import { getUserProfile, updateEntry, deleteEntry, createEntry, getUserEntries } from '@/services/api'
import { UserProfile, TimeEntry, TimeEntryFormData } from '@/types/era'

@Module({ namespaced: true })
export default class ProfileModule extends VuexModule {
  profile: UserProfile | null = null
  entries: TimeEntry[] = []
  currentDate: Date = new Date()

  startDate: Date = subYears(new Date(), 1)
  endDate: Date = new Date()
  filterStartDate: Date | null = null
  filterEndDate: Date | null = null

  @Mutation
  SET_PROFILE (profile: UserProfile | null) {
    this.profile = profile
  }

  @Mutation
  SET_CURRENT_DATE (date: Date) {
    this.currentDate = date
  }

  @Mutation
  SET_ENTRIES (entries: TimeEntry[]) {
    this.entries = entries
  }

  @Action({ commit: 'SET_PROFILE' })
  load (id: number) {
    return getUserProfile(id, this.startDate, this.endDate)
  }

  @Action({ commit: 'SET_PROFILE' })
  clear () {
    return null
  }

  @Action({})
  async create (entry: TimeEntryFormData) {
    await createEntry(entry)

    return this.context.dispatch('load', this.profile!.id)
  }

  @Action({ commit: 'SET_PROFILE' })
  async update (entry: TimeEntryFormData) {
    await updateEntry(entry)

    return this.context.dispatch('load', this.profile!.id)
  }

  @Action({ commit: 'SET_PROFILE' })
  async remove (entry: TimeEntryFormData) {
    await deleteEntry(entry)

    return this.context.dispatch('load', this.profile!.id)
  }

  @Action({ commit: 'SET_ENTRIES' })
  async loadEntries () {
    return getUserEntries(this.profile!.id, this.currentDate, 'day')
  }

  @Action({ commit: 'SET_CURRENT_DATE' })
  async resetFilterPeriod () {
    return new Date()
  }

  @Action({ commit: 'SET_CURRENT_DATE' })
  async setFilterPeriod (date: Date) {
    return date
  }
}
