

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormLabel extends Vue {
  @Prop({ type: String, required: true })
  readonly label!: String
}
