







import { Component, Vue, Prop } from 'vue-property-decorator'
import bus from '@/services/bus'
import { checkCollision } from '@/utils'

@Component
export default class OnClickOutside extends Vue {
  @Prop({ required: true })
  readonly do!: Function

  @Prop({ default: true })
  readonly active!: boolean

  listener (event: MouseEvent) {
    if (this.active && !checkCollision(this.$el, event)) {
      this.do()
    }
  }

  mounted () {
    bus.$on('document-click', this.listener)
  }

  beforeUnmount () {
    bus.$off('document-click', this.listener)
  }

  render () {
    return this.$slots.default![0]
  }
}
