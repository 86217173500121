




































import { Component, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { UserRegisterFormData, Auth } from '@/types/era'
import BaseModalForm from '@/components/mixins/BaseModalForm'
import RegisterForm from '@/components/forms/RegisterForm.vue'

@Component({
  components: {
    RegisterForm
  }
})
export default class LoginModal extends Mixins(BaseModalForm) {
  @Action('register', { namespace: 'auth' })
  register!: (data: UserRegisterFormData) => Promise<Auth>

  @Action('setAllLocales', { namespace: 'session' })
  setAllLocales!: (locales: any) => Promise<void>

  async onSubmit (data: UserRegisterFormData) {
    const result = await this.submitWithFn(() => this.register(data))
    if (result) {
      await this.setAllLocales(result.profile)
    }

    this.onClose()
    this.$router.push({ name: 'hours' })
  }
}
