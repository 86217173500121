/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import VueI18n from 'vue-i18n'
import Vue from '@/providers/vue'
import * as messages from '@/translations'
import config from '@/config'

Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: 'en',
  locale: config.language,
  messages
})
