







import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import config from '@/config'

@Component
export default class DocumentTitle extends Vue {
  @Prop({ required: false })
  readonly title!: string

  mounted () {
    this.updateTitle()
  }

  updated () {
    this.updateTitle()
  }

  @Watch('title')
  onTitleChange () {
    this.updateTitle()
  }

  updateTitle () {
    const prefix = this.title
    const suffix = config.branding.title
    const title = prefix ? `${prefix} - ${suffix}` : suffix
    document.title = title
  }

  render () {
  }
}
