










































import { Component, Emit, Mixins } from 'vue-property-decorator'
import BaseModalForm from '@/components/mixins/BaseModalForm'

@Component({})
export default class ReportExportModal extends Mixins(BaseModalForm) {
  @Emit('submit')
  onSubmit (value: string) {
    return value
  }
}
