



































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class CollapseContainer extends Vue {
  @Prop({ required: true })
  readonly title!: string

  collapsed: boolean = false

  get icon () {
    return this.collapsed
      ? 'ChevronDown'
      : 'ChevronUp'
  }

  @Emit('toggle')
  onClick (event: Event) {
    this.collapsed = !this.collapsed

    return this.collapsed
  }
}
