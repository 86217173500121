








































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ColorStripe extends Vue {
  @Prop({ default: '#2B3AA4' })
  readonly stripeColor!: string

  @Prop({ type: String })
  readonly classOverride!: string

  get stripeStyle () {
    return {
      backgroundColor: this.stripeColor
    }
  }

  get className () {
    const clsName = { [this.$style.stripe]: true }
    if (this.classOverride && this.classOverride === 'calendar') {
      clsName[this.$style.colorOverrideTop] = true
    }
    return clsName
  }
}
