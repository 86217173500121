/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Vue from '@/providers/vue'
import AuthModule from '@/stores/auth'
import ProjectsModule from '@/stores/projects'
import ProjectModule from '@/stores/project'
import InterfaceModule from '@/stores/interface'
import HoursModule from '@/stores/hours'
import ProfileModule from '@/stores/profile'
import TeamsModule from '@/stores/teams'
import ClientsModule from '@/stores/clients'
import ClientModule from '@/stores/client'
import SearchModule from '@/stores/search'
import ReportsModule from '@/stores/reports'
import SessionModule from '@/stores/session'
import PreferencesModule from '@/stores/preferences'
import StopwatchesModule from '@/stores/stopwatches'
import AdminModule from '@/stores/admin'

Vue.use(Vuex)

const { plugin: persist } = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    'auth',
    'session'
  ]
})

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    projects: ProjectsModule,
    project: ProjectModule,
    interface: InterfaceModule,
    hours: HoursModule,
    profile: ProfileModule,
    teams: TeamsModule,
    clients: ClientsModule,
    client: ClientModule,
    search: SearchModule,
    reports: ReportsModule,
    session: SessionModule,
    preferences: PreferencesModule,
    stopwatches: StopwatchesModule,
    admin: AdminModule
  },
  plugins: [
    persist
  ]
})
