






















import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { Project, ProjectSummary } from '@/types/era'
import ProjectLog from '@/components/layout/ProjectLog.vue'
import Heading from '@/components/layout/Heading.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

@Component({
  components: {
    Heading,
    DefaultTemplate,
    ProjectLog
  }
})
export default class ProjectView extends Vue {
  currentPage: number = 1

  @State('project', { namespace: 'project' })
  project!: Project

  @Action('load', { namespace: 'project' })
  loadProject!: (id: number) => Promise<ProjectSummary>

  @Action('loadMoreEntries', { namespace: 'project' })
  loadMoreEntries!: (id: number) => Promise<ProjectSummary>

  created () {
    const id = parseInt(this.$route.params.id, 10)
    return this.loadProject(id)
  }

  onLoadMore () {
    this.currentPage = this.currentPage + 1

    this.loadMoreEntries(this.currentPage)
  }

  get documentTitle () {
    const prefix = this.$t('project.heading') as string
    return this.project
      ? `${prefix} - ${this.project.title}`
      : prefix
  }
}
