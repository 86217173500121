/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export default {
  heading: 'Admin',
  invite: {
    heading: 'Invite users',
    email: 'User email',
    submit: 'Invite'
  },
  pending: {
    heading: 'Pending invites'
  }
}
