/**
 * @project Era
 * @author Anders Evenrud <anders.evenrud@copyleft.no>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

export const checkCollision = (root: Element, ev: Event): boolean => {
  const target = ev.target as Element

  if (root && target) {
    return root.contains(target)
  }

  return false
}
