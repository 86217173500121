/**
 * @project Era
 * @author Marko Muric <marko.muric@copyleft.rs>
 * @copyright Copyleft Solutions AS
 * @license MIT
 */

import { CallbackResult } from './interfaces'
import { getSubtractConfig, getDotTime, getNormalTimeFormat, getOrderDate, getYesterday, parseDate } from './functions'
import sub from 'date-fns/sub'

// date regex
const dateRegex = /(\d{1,2}[\s./-]\d{1,2}[\s./-]\d{4}|\d{4}[\s./-]\d{1,2}[\s./-]\d{1,2}|\d{1,2}[\s./-]\s?[a-zA-Z]{3,}[\s./-]\d{4}|\d{1,2}[\s./-]\d{4}[\s./-]\s?[a-zA-Z]{3,}|[a-zA-Z]{3,}[\s./-]\d{1,2}[\s./-]\d{4}|[a-zA-Z]{3,}[\s./-]\d{4}[\s./-]\d{1,2}|\d{4}[\s./-]\d{1,2}[\s./-]\s?[a-zA-Z]{3,}|\d{4}[\s./-]\s?[a-zA-Z]{3,}[\s./-]\d{1,2})/
const dwmyRegex = /^-((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))?((\d+)(d|D|w|W|m|M|y|Y))/
const dayOrderMonthRegex = /(\d+)(st|nd|rd|th)[\s./-]([a-zA-Z]{3,})/
const monthDayOrderRegex = /([a-zA-Z]{3,})[\s./-](\d+)(st|nd|rd|th)/
const dayOrderRegex = /(\d+)(st|nd|rd|th)/
const yesterdayRegex = /(yesterday|yd|(y+)d|today|td)/
// time regex
const dotTimeRegex = /(?<![.\d])(\d*)[.,](\d*)(?![.\d])/
const normalTimeRegex = /(\d*):(\d*)/
const hmTimeRegex = /(\d+\s?[h|H]?\s?\d+\s?[m|M]|\d+\s?[h|H]|\d+\s?[m|M])/
// ditto regex
const dittoRegex = /(ditto|--)/

const getHMTime = (str:string) => {
  const reg = /((\d+)\s?[h|H])? ?((\d+)\s?[m|M])?/
  const res = str.match(reg)!
  return getNormalTimeFormat(res[2], res[4])
}

export const regList = [
  {
    reg: new RegExp(dateRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = parseDate(date, obj[1])
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: '-1d2w3m4y ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = sub(date, getSubtractConfig(obj))
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: '1st May ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1], obj[3])
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: 'May 1st ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[2], obj[1])
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: '1st ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1])
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: 'yd ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getYesterday(obj[1], date)
      return { result: obj[0], date, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: 'ditto H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const { hours, minutes } = getDotTime(obj[2], obj[3])
      return { result: obj[0], date: undefined, hours, minutes }
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: 'ditto H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      // date = parseDate(date, obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[2], obj[3])
      return { result: obj[0], date: undefined, hours, minutes }
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: 'ditto 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const { hours, minutes } = getHMTime(obj[2])
      return { result: obj[0], date: undefined, hours, minutes }
    }
  },
  {
    reg: new RegExp(dittoRegex.source + /\s/.source + dittoRegex.source),
    category: 'datetime',
    format: 'ditto ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      return { result: obj[0], date: undefined, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dittoRegex.source),
    category: 'datetime',
    format: 'ditto',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      return { result: obj[0], date: undefined, hours: undefined, minutes: undefined }
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = parseDate(date, obj[1])
      const { hours, minutes } = getDotTime(obj[2], obj[3])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: '-1d2w3m4y H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getDotTime(obj[13], obj[14])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: '1st May H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getDotTime(obj[4], obj[5])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: 'May 1st H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getDotTime(obj[4], obj[5])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: '1st H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1])
      const { hours, minutes } = getDotTime(obj[3], obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + dotTimeRegex.source),
    category: 'datetime',
    format: 'yd H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getYesterday(obj[1], date)
      const { hours, minutes } = getDotTime(obj[3], obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = parseDate(date, obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[2], obj[3])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: '-1d2w3m4y H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getNormalTimeFormat(obj[13], obj[14])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: '1st May H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getNormalTimeFormat(obj[4], obj[5])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: 'May 1st H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[4], obj[5])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: '1st H:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1])
      const { hours, minutes } = getNormalTimeFormat(obj[3], obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + normalTimeRegex.source),
    category: 'datetime',
    format: 'yd H.mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getYesterday(obj[1], date)
      const { hours, minutes } = getNormalTimeFormat(obj[3], obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dateRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: 'DD.MM.YY/YYYY 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = parseDate(date, obj[1])
      const { hours, minutes } = getHMTime(obj[2])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dwmyRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: '-1d2w3m4y 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = sub(date, getSubtractConfig(obj))
      const { hours, minutes } = getHMTime(obj[13])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderMonthRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: '1st May 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1], obj[3])
      const { hours, minutes } = getHMTime(obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(monthDayOrderRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: 'May 1st 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[2], obj[1])
      const { hours, minutes } = getHMTime(obj[4])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: '1st 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1])
      const { hours, minutes } = getHMTime(obj[3])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source + /\s/.source + hmTimeRegex.source),
    category: 'datetime',
    format: 'yd 1H1m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getYesterday(obj[1], date)
      const { hours, minutes } = getHMTime(obj[3])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(/\b/.source + '' + dateRegex.source + '' + /\b/.source),
    category: 'date',
    format: 'DD.MM.YY/YYYY',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      date = parseDate(date, obj[0])
      const hours = 0
      const minutes = 0
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dwmyRegex.source),
    category: 'date',
    format: '-1d2w3m4y ',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const hours = 0
      const minutes = 0
      date = sub(date, getSubtractConfig(obj))
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(/\b/.source + '' + dayOrderMonthRegex.source + '' + /\b/.source),
    category: 'date',
    format: '1st May',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1], obj[3])
      const hours = 0
      const minutes = 0
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(/\b/.source + '' + monthDayOrderRegex.source + '' + /\b/.source),
    category: 'date',
    format: 'May 1st',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[2], obj[1])
      const hours = 0
      const minutes = 0
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dayOrderRegex.source),
    category: 'date',
    format: '1st',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getOrderDate(date, obj[1])
      const hours = 0
      const minutes = 0
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(yesterdayRegex.source),
    category: 'date',
    format: 'yesterday/yd',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      getYesterday(obj[1], date)
      const hours = 0
      const minutes = 0
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(dotTimeRegex.source),
    category: 'time',
    format: '2.5 => 2:30',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const { hours, minutes } = getDotTime(obj[1], obj[2])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(normalTimeRegex.source),
    category: 'time',
    format: 'HH:mm',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const { hours, minutes } = getNormalTimeFormat(obj[1], obj[2])
      return { result: obj[0], date, hours, minutes }
    }
  },
  {
    reg: new RegExp(hmTimeRegex.source),
    category: 'time',
    format: '1h10m',
    fnc: (obj:RegExpMatchArray, date:Date):CallbackResult => {
      const { hours, minutes } = getHMTime(obj[0])
      return { result: obj[0], date, hours, minutes }
    }
  }
]
