






















































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ default: () => ({}) })
  readonly appendStyles!: object

  @Prop({ default: true })
  readonly closeable!: boolean

  @Prop({ default: false })
  readonly centered!: boolean

  mounted () {
    this.$emit('open')
  }

  @Emit('close')
  onClose () {
  }

  get styles () {
    return this.centered
      ? {}
      : { ...this.appendStyles }
  }
}
