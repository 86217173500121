


















































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { orderBy } from 'lodash-es'
import eachDay from 'date-fns/eachDayOfInterval'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import isToday from 'date-fns/isToday'
import getWeek from 'date-fns/getISOWeek'
import format from 'date-fns/format'
import PeriodSelection from '@/components/layout/PeriodSelection.vue'
import EntryCalendarCard from '@/components/layout/EntryCalendarCard.vue'
import TeamModal from '@/components/modals/TeamModal.vue'
import TeamDayReportModal from '@/components/modals/TeamDayReportModal.vue'
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'
import ListingTableHeading from '@/components/layout/ListingTableHeading.vue'
import UserLink from '@/components/layout/UserLink.vue'
import ListingTableChevron from '@/components/layout/ListingTableChevron.vue'
import { User, Project, Team, TimeEntry, UserAuth, MessageboxMessage } from '@/types/era'

@Component({
  components: {
    PeriodSelection,
    DefaultTemplate,
    EntryCalendarCard,
    TeamModal,
    TeamDayReportModal,
    ListingTableHeading,
    UserLink,
    ListingTableChevron
  }
})
export default class TeamsView extends Vue {
  modalTarget: HTMLElement | null = null
  userTimeDetails: number | null = null
  selectionEntries: TimeEntry[] = []
  sortByDirection: any[] = []

  @State('profile', { namespace: 'auth' })
  profile!: UserAuth

  @State('period', { namespace: 'teams' })
  period!: Date

  @State('team', { namespace: 'teams' })
  team!: Team

  @State('teams', { namespace: 'teams' })
  teams!: Team[]

  @State('overview', { namespace: 'teams' })
  overview!: Team[]

  @State('filter', { namespace: 'teams' })
  filter!: number[]

  @State('users', { namespace: 'teams' })
  users!: User[]

  @Action('createCurrent', { namespace: 'teams' })
  newTeam!: () => void

  @Action('clearCurrent', { namespace: 'teams' })
  clearTeam!: () => void

  @Action('load', { namespace: 'teams' })
  loadTeam!: (id: number) => Promise<Team>

  @Action('loadOverview', { namespace: 'teams' })
  loadOverview!: (options: any) => Promise<Team[]>

  @Action('loadAll', { namespace: 'teams' })
  loadTeams!: () => Promise<Team[]>

  @Action('remove', { namespace: 'teams' })
  deleteTeam!: (team: Team) => Promise<Team[]>

  @Action('setPeriod', { namespace: 'teams' })
  setPeriod!: (period: Date) => void

  @Action('join', { namespace: 'teams' })
  joinTeam!: (o: any) => void // FIXME: Type

  @Action('leave', { namespace: 'teams' })
  leaveTeam!: (o: any) => void // FIXME: Type

  @Action('loadUsers', { namespace: 'teams' })
  loadUsers!: () => Promise<void>

  @Action('setFilter', { namespace: 'teams' })
  setFilter!: (n: number[]) => void

  @Action('addMessage', { namespace: 'session' })
  addMessage!: (message: Partial<MessageboxMessage>) => void

  async created () {
    await this.loadTeams()
    await this.updatePeriod(this.period)
  }

  async updatePeriod (period: Date) {
    await this.setPeriod(period)
    await this.loadOverview({
      week: getWeek(period)
    })
  }

  isToday (date: Date) {
    return isToday(date)
  }

  formatDate (date: Date) {
    return format(date, 'd')
  }

  sum (entries: TimeEntry[]) {
    const times = entries.map(e => e.timeSpent)
    return times.reduce((acc, cur) => acc + cur, 0)
  }

  hasTimeSpent (entries: any, weekDay: Date) {
    return this.getGroupSum(entries, weekDay) > 0
  }

  getGroupSum (entries: any, weekDay: Date) {
    return this.sum(this.getGroup(entries, weekDay))
  }

  getGroup (entries: any, weekDay: Date) {
    const group = format(weekDay, 'yyyy-LL-dd')
    return entries[group] || []
  }

  belongsInTeam (team: Team) {
    const found = this.teams.find(t => t.id === team.id)
    return found
      ? found.users?.find(u => u.id === this.profile.id)
      : false
  }

  sortedUsers (index: number, list: User[]) {
    const dir = this.sortByDirection[index]
    if (dir === undefined) {
      return list
    }

    return orderBy(list, (item: User) => item.fullName || item.username || item.email, dir)
  }

  onTeamCreate () {
    this.newTeam()
  }

  onModalToggle (toggle: boolean) {
    if (!toggle) {
      this.modalTarget = null
      this.userTimeDetails = null
    }
  }

  onBoxClick (userId: number, entries: any, period: Date, ev: MouseEvent) {
    this.userTimeDetails = userId
    const selection = this.getGroup(entries, period)
    const target = ev.target as HTMLElement
    this.selectionEntries = selection
    this.modalTarget = selection.length > 0 ? target : null
  }

  onPeriodSelection (period: Date) {
    this.updatePeriod(period)
  }

  onColumnSortClick (index: number) {
    const current = this.sortByDirection[index]
    this.sortByDirection[index] = current === undefined
      ? 'desc'
      : (current === 'desc' ? 'asc' : undefined)

    // FIXME: This is stupid. Somehow we lose reactivity
    // with the sortedUsers method call even though we change
    // keys to include the sort direction ?!
    this.$forceUpdate()
  }

  async onModalSave (project: Project) {
    this.addMessage({
      message: this.$t('projects.success') as string
    })

    this.clearTeam()
    await this.loadTeams()
    await this.updatePeriod(this.period)
  }

  async onModalClose () {
    this.clearTeam()
    await this.loadTeams()
    await this.updatePeriod(this.period)
  }

  async onEdit (team: Team) {
    await this.loadUsers()
    await this.loadTeam(team.id as number)
  }

  onTeamFilterSelect (id: number | null) {
    const filter = id ? [id] : []
    this.setFilter(filter)
  }

  onUserJoin (o: any) { // FIXME: Type
    this.joinTeam(o)
  }

  onUserLeave (o: any) { // FIXME: Type
    this.leaveTeam(o)
  }

  showDetails (userId: number) {
    return this.userTimeDetails === userId
  }

  get filterValue () {
    return this.filter[0]
  }

  get filteredOverview () {
    if (this.filter.length > 0) {
      return this.overview.filter(team => {
        return this.filter.indexOf(team.id) !== -1
      })
    }

    return this.overview
  }

  get weekDays () {
    return eachDay({
      start: startOfWeek(this.period),
      end: endOfWeek(this.period)
    })
  }

  get periodTitle () {
    const week = format(this.period, 'I')
    const month = format(this.period, 'LLLL')
    const str = this.$t('hours.week') as string

    return `${month} - ${str} ${week}`
  }

  get teamOptions () {
    return this.teams.map(team => ({
      value: team.id,
      label: team.title
    }))
  }
}
